import { useState, useEffect, forwardRef, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ChromePicker } from 'react-color';
import { listSysFonts } from '../../../actions/actions';
import TemplateEditorUploadModal from './TemplateEditorUploadModal';
import ToolbarFontSelect from './ToolbarFontSelect';
import {
  TypeIcon,
  PlusIcon,
  ToolbarTrashIcon,
  LayerUpIcon,
  LayerDownIcon,
  BoldIcon,
  ItalicIcon,
  LeftAlignIcon,
  CenterAlignIcon,
  RightAlignIcon,
  UndoIcon,
  RedoIcon,
} from '../../Icons';

const ToolbarButton = ({ id, label, icon, type, name, onClick, checked, disabled, color, current, values }) => {
  const [open, setOpen] = useState(false);
  const dialog = useRef();
  const toggle = useRef();

  useEffect(() => {
    const handleClick = (e) => {
      if (!dialog.current) {
        return false;
      }
      if (
        e.target === dialog.current ||
        dialog.current.contains(e.target) ||
        e.target === toggle.current ||
        toggle.current.contains(e.target)
      ) {
        return false;
      }
      setOpen(false);
    };

    if (type === 'color') {
      window.addEventListener('click', handleClick, false);
    }

    return () => {
      window.removeEventListener('click', handleClick, false);
    };
  }, []);

  switch (type) {
    case 'checkbox':
    case 'radio':
      return (
        <>
          <input
            id={id}
            className="toolbar__input"
            type={type}
            name={name}
            disabled={disabled ? 'disabled' : null}
            onChange={(e) => onClick(e.target.checked)}
            checked={!!checked}
          />
          <label htmlFor={id} className="toolbar__button">
            {icon}
            <span className="sr-only">{label}</span>
          </label>
        </>
      );
    case 'color':
      return (
        <div style={{ position: 'relative' }}>
          <button
            ref={toggle}
            className="toolbar__button"
            onClick={() => setOpen(!open)}
            disabled={disabled ? 'disabled' : null}
            title="Change text color"
          >
            <div className="toolbar__color" style={{ background: `rgba(${color.join(',')})` }} />
          </button>
          {open && (
            <div ref={dialog} className="toolbar__dialog">
              <ChromePicker
                color={{ r: color[0], g: color[1], b: color[2], a: color[3] }}
                onChangeComplete={({ rgb: { r, g, b, a } }) => onClick([r, g, b, a])}
              />
            </div>
          )}
        </div>
      );
    case 'cycle': {
      const index = values.findIndex((v) => v[0] === current);
      if (index < 0) {
        return null;
      }
      const nextIndex = index + 1 >= values.length ? 0 : index + 1;
      return (
        <button
          className="toolbar__button"
          onClick={() => onClick(values[nextIndex][0])}
          disabled={disabled ? 'disabled' : null}
          title={label}
        >
          {values[index][1]}
          <span className="sr-only">{values[index][0]}</span>
        </button>
      );
    }
    default:
      return (
        <button className="toolbar__button" onClick={onClick} disabled={disabled ? 'disabled' : null} title={label}>
          {icon}
          <span className="sr-only">{label}</span>
        </button>
      );
  }
};

const TemplateEditorToolbar = forwardRef((props, toolbarRef) => {
  const { activeShape, setActiveShape, config, updateConfig, undo, canUndo, redo, canRedo, onLoadFonts } = props;

  const dispatch = useDispatch();
  const fonts = useSelector((store) => store.sysFontsList);
  const accessToken = useSelector((store) => store.operator.accessToken);

  const [settings, set] = useState({
    bold: false,
    italic: false,
    align: 'left',
    color: [0, 0, 0, 1],
    font: 'Frontage-Bold',
  });

  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    dispatch(listSysFonts(accessToken));
  }, []);

  useEffect(async () => {
    if (fonts.data.length && !fonts.loading && !fonts.error && config.texts.length) {
      if (!document.fonts) {
        return;
      }

      const style = document.createElement('style');
      document.head.appendChild(style);
      const { sheet } = style;

      // Load only fonts from this template initially
      const fontNames = [
        ...new Set(
          fonts.data.reduce((accumulator, template) => {
            accumulator.push(...config.texts.map((t) => t.font));
            return accumulator;
          }, [])
        ),
      ];
      const fontsToLoad = fontNames.map((name) => fonts.data.find((f) => f.name === name)).filter((f) => f);
      if (!fontsToLoad.length) {
        onLoadFonts();
        return;
      }

      Promise.all(
        fontsToLoad.map(async (font) => {
          return new Promise(async (resolve) => {
            try {
              const fontFace = new FontFace(font.name, `url(${font.url})`);
              const fontData = await fontFace.load();
              document.fonts.add(fontData);
              // console.log('loaded '+font.name)
              resolve(fontData);
            } catch (error) {
              resolve(false);
            }
          });
        })
      ).then((data) => {
        const fontsLoaded = data.filter((f) => f);

        document.fonts.ready.then(() => {
          if (onLoadFonts) {
            setTimeout(() => {
              onLoadFonts();
            }, 1000);
          }
        });
      });
    }

    if (!config.texts.length) {
      onLoadFonts();
    }

    // Load remaining fonts asynchronously
    fonts.data.forEach((font) => {
      const fontFace = new FontFace(font.name, `url(${font.url})`);
      fontFace.load().then((data) => {
        document.fonts.add(data);
      });
    });
  }, [fonts]);

  useEffect(() => {
    // Update settings to reflect selected text
    if (activeShape && activeShape.category === 'texts') {
      set({
        bold: activeShape.bold,
        italic: activeShape.italic,
        align: activeShape.align,
        color: activeShape.color,
        font: activeShape.font,
      });
    }
  }, [activeShape]);

  const handleDelete = () => {
    if (!activeShape) {
      return;
    }
    updateConfig({
      type: 'deleteShape',
      category: activeShape.category,
      id: activeShape.id,
    });
    setActiveShape(null);
  };

  const handleMoveLayer = (direction) => {
    if (!activeShape) {
      return;
    }
    const currentZIndex = activeShape.zIndex || 0;
    const imageCount = config.images ? config.images.length : 0;
    const textCount = config.texts ? config.texts.length : 0;
    let zIndex = currentZIndex;
    if (direction === 'up' && currentZIndex >= imageCount + textCount) {
      zIndex = imageCount + textCount;
    } else {
      zIndex = direction === 'up' ? currentZIndex + 1 : currentZIndex - 1;
    }
    updateConfig({
      type: 'updateShape',
      category: activeShape.category,
      id: activeShape.id,
      payload: { zIndex },
    });
    setActiveShape({ ...activeShape, zIndex });
  };

  const getNextZIndex = () => {
    const imageCount = config.images ? config.images.length : 0;
    const textCount = config.texts ? config.texts.length : 0;
    return imageCount + textCount + 1;
  };

  const handleNewImage = (asset, type) => {
    setModalOpen(false);
    const isBackgroundOrOverlay = ['printTemplateOverlay', 'printTemplateBackground'].includes(type);
    let actualWidth;
    let actualHeight;
    if (isBackgroundOrOverlay) {
      actualWidth = config.width;
      actualHeight = config.height;
    } else {
      const ratio = asset.width / asset.height;
      const templateRatio = config.width / config.height < 1 ? 'portrait' : 'landscape';
      if (templateRatio === 'landscape') {
        actualHeight = Math.min(asset.height, config.height);
        actualWidth = actualHeight * ratio;
      } else {
        actualWidth = Math.min(asset.width, config.width);
        actualHeight = actualWidth / ratio;
      }
    }
    updateConfig({
      type: 'addShape',
      category: 'images',
      payload: {
        src: asset.originalUrl,
        width: actualWidth,
        height: actualHeight,
        xOrigin: 0,
        yOrigin: 0,
        rotation: 0,
        zIndex: type === 'printTemplateBackground' ? -1 : getNextZIndex(),
        editable: !isBackgroundOrOverlay,
      },
    });
  };

  const handleNewText = () => {
    updateConfig({
      type: 'addShape',
      category: 'texts',
      payload: {
        text: 'Change Text',
        size: 60,
        xOrigin: 0,
        yOrigin: 0,
        rotation: 0,
        zIndex: getNextZIndex(),
        font: 'Frontage-Bold',
        bold: false,
        italic: false,
        align: 'left',
        color: [0, 0, 0, 1],
      },
    });
  };

  const handleSettingChange = (newSettings) => {
    set({
      ...settings,
      ...newSettings,
    });
    // Apply new settings to active shape if necessary
    if (activeShape) {
      updateConfig({
        type: 'updateShape',
        id: activeShape.id,
        category: activeShape.category,
        payload: newSettings,
      });
    }
  };

  return (
    <>
      <div className="toolbar" ref={toolbarRef}>
        {activeShape && (
          <div className="toolbar__section">
            <div className="toolbar__group">
              {activeShape.category === 'texts' && (
                <>
                  <ToolbarFontSelect
                    currentFont={settings.font}
                    onSelect={(font) => handleSettingChange({ font })}
                    disabled={!!activeShape && activeShape.category === 'images'}
                    onLoadFonts={onLoadFonts}
                  />
                  <ToolbarButton
                    id="color"
                    label="Text color"
                    type="color"
                    color={settings.color}
                    onClick={(color) => handleSettingChange({ color })}
                    disabled={!!activeShape && activeShape.category === 'images'}
                  />
                  <ToolbarButton
                    id="align"
                    label="Toggle text alignment"
                    type="cycle"
                    current={settings.align}
                    values={[
                      ['left', <LeftAlignIcon key="left" />],
                      ['center', <CenterAlignIcon key="center" />],
                      ['right', <RightAlignIcon key="right" />],
                    ]}
                    name="align"
                    onClick={(align) => handleSettingChange({ align })}
                    disabled={!!activeShape && activeShape.category === 'images'}
                  />
                </>
              )}
              <ToolbarButton
                label="Move selected item forward"
                icon={<LayerUpIcon />}
                disabled={!activeShape}
                onClick={() => handleMoveLayer('up')}
              />
              <ToolbarButton
                label="Move selected item back"
                icon={<LayerDownIcon />}
                disabled={!activeShape}
                onClick={() => handleMoveLayer('down')}
              />
              <ToolbarButton
                label="Delete selected item"
                icon={<ToolbarTrashIcon />}
                disabled={!activeShape}
                onClick={handleDelete}
              />
            </div>
          </div>
        )}

        <div className="toolbar__section">
          <div className="toolbar__group">
            <ToolbarButton label="Add Image" icon={<PlusIcon />} onClick={() => setModalOpen(true)} />
          </div>

          <div className="toolbar__group">
            <ToolbarButton label="Add Text" icon={<TypeIcon />} onClick={handleNewText} />
          </div>

          <div className="toolbar__group">
            <ToolbarButton label="Undo" icon={<UndoIcon />} onClick={() => undo()} disabled={!canUndo} />
          </div>

          <div className="toolbar__group">
            <ToolbarButton label="Redo" icon={<RedoIcon />} onClick={() => redo()} disabled={!canRedo} />
          </div>
        </div>
      </div>

      <TemplateEditorUploadModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        onSelectAsset={handleNewImage}
        currentTemplate={config}
      />
    </>
  );
});

export default TemplateEditorToolbar;
