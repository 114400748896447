import { useState, useReducer, useCallback, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { WAIT_FOR_ACTION, ERROR_ACTION } from 'redux-wait-for-action';
import useTimeout from 'use-timeout';
import { toast, ToastContainer } from 'react-toastify';
import uniqid from 'uniqid';
import _ from 'lodash';
import useUndoReducer from '../../../utils/useUndoReducer';
// import { createUndoRedo } from '../../../utils/react-undo-redo';
import TemplateEditorToolbar from './TemplateEditorToolbar';
import TemplateEditorCanvas from './TemplateEditorCanvas';
import IconButton from '../core/IconButton';
import Button from '../core/Button';
import Loading from '../Loading';
import Modal from '../core/Modal';
import { BackIcon } from '../../Icons';
import {
  getEvent,
  getPrintTemplate,
  createPrintTemplate,
  updatePrintTemplate,
  downloadSysTemplate,
  CREATE_PRINT_TEMPLATE_SUCCESS,
  CREATE_PRINT_TEMPLATE_FAILURE,
  UPDATE_PRINT_TEMPLATE_SUCCESS,
  UPDATE_PRINT_TEMPLATE_FAILURE,
  DOWNLOAD_SYS_TEMPLATE_SUCCESS,
  DOWNLOAD_SYS_TEMPLATE_FAILURE,
} from '../../../actions/actions';
import { transformTemplateConfig } from '../../../utils/photoTemplates';
import { TextSwitch, TextSwitchOption } from '../core/TextSwitch';

const configReducer = (state, action) => {
  switch (action.type) {
    case 'addShape':
      return {
        ...state,
        [action.category]: [
          ...(state[action.category] || []),
          {
            ...action.payload,
            id: uniqid(),
          },
        ],
      };
    case 'updateShape':
      return {
        ...state,
        [action.category]: state[action.category].map((shape) => {
          if (shape.id !== action.id) {
            return shape;
          }
          return { ...shape, ...action.payload };
        }),
      };
    case 'deleteShape':
      return {
        ...state,
        [action.category]: state[action.category].filter((shape) => {
          return shape.id !== action.id;
        }),
      };
    case 'setPrintSize':
      return {
        ...state,
        layout: {
          ...state.layout,
          printSize: action.size,
        },
      };
    case 'setData':
      return action.payload;
    default:
      return state;
  }
};

const TemplateEditor = ({ template, goBack, onSave }) => {
  const dispatch = useDispatch();
  const appEvent = useSelector((store) => store.appEvent.data);
  const accessToken = useSelector((store) => store.operator.accessToken);
  // const printTemplate = useSelector(store => store.printTemplates[templateId]);
  const loading = useSelector((store) => store.printTemplates.loading);
  const error = useSelector((store) => store.printTemplates.error);

  const [config, updateConfig] = useUndoReducer(configReducer, template);
  const [activeShape, setActiveShape] = useState(null);
  const [saveInterval, setSaveInterval] = useState(null);
  const [fontsLoaded, setFontsLoaded] = useState(false);
  const [saving, setSaving] = useState(false);
  const [savingPSD, setSavingPSD] = useState(false);

  const toolbarRef = useRef();

  const undo = useCallback(() => {
    setActiveShape(null);
    updateConfig({ type: useUndoReducer.types.undo });
  }, [updateConfig]);

  const redo = useCallback(() => {
    setActiveShape(null);
    updateConfig({ type: useUndoReducer.types.redo });
  }, [updateConfig]);

  const resetHistory = useCallback(() => {
    updateConfig({ type: useUndoReducer.types.reset });
  }, [updateConfig]);

  const saveConfig = () => {
    setSaving(true);
    const newConfig = transformTemplateConfig(config.present);

    // console.log('Config after transform:', newConfig)

    let action;
    if (template.id) {
      // console.log('Update existing template')
      action = updatePrintTemplate(template.id, newConfig, accessToken);
      action[WAIT_FOR_ACTION] = UPDATE_PRINT_TEMPLATE_SUCCESS;
      action[ERROR_ACTION] = UPDATE_PRINT_TEMPLATE_FAILURE;
    } else {
      // console.log('Create new template')
      delete newConfig.id;
      action = createPrintTemplate(appEvent.id, newConfig, accessToken);
      action[WAIT_FOR_ACTION] = CREATE_PRINT_TEMPLATE_SUCCESS;
      action[ERROR_ACTION] = CREATE_PRINT_TEMPLATE_FAILURE;
    }
    dispatch(action)
      .then((data) => {
        if (onSave) {
          onSave(data.id);
        } else {
          // goBack();
        }
      })
      .catch((error) => {
        alert(error);
      })
      .finally(() => {
        setSaving(false);
      });
  };

  useTimeout(
    () => {
      saveConfig();
    },
    saveInterval > 0 ? saveInterval : null
  );

  const downloadPSD = () => {
    // setSavingPSD(true);
    const action = downloadSysTemplate(config.present.psdLink, accessToken);
    action[WAIT_FOR_ACTION] = DOWNLOAD_SYS_TEMPLATE_SUCCESS;
    action[ERROR_ACTION] = DOWNLOAD_SYS_TEMPLATE_FAILURE;
    dispatch(action)
      .then((data) => {
        window.open(data.url);
      })
      .catch((error) => alert(error));
    // .finally(() => setSavingPSD(false));
  };

  return (
    <>
      <header className="multistep-header mb-xl" style={{ alignItems: 'center' }}>
        <IconButton alt="Go back" onClick={goBack}>
          <BackIcon color="#777777" />
        </IconButton>
        <h1 className="gamma">{template.type === 'overlay' ? 'Overlay Editor' : 'Template Editor'}</h1>
        <div className="button-group" style={{ marginLeft: 'auto' }}>
          {!!config.present.psdLink && (
            <Button variant="outline" loading={!!savingPSD} onClick={downloadPSD}>
              Download PSD
            </Button>
          )}
          <Button variant="primary" onClick={saveConfig} disabled={!!saving} loading={!!saving}>
            Save &amp; Exit
          </Button>
        </div>
      </header>
      <div className="template-editor">
        <TemplateEditorToolbar
          config={config.present}
          updateConfig={updateConfig}
          activeShape={activeShape}
          setActiveShape={setActiveShape}
          undo={() => undo()}
          canUndo={!!config.past.length}
          redo={() => redo()}
          canRedo={!!config.future.length}
          ref={toolbarRef}
          onLoadFonts={() => setFontsLoaded(true)}
        />
        {!fontsLoaded ? (
          <Loading />
        ) : (
          <TemplateEditorCanvas
            config={config.present}
            resetHistory={resetHistory}
            updateConfig={updateConfig}
            activeShape={activeShape}
            setActiveShape={setActiveShape}
            toolbarRef={toolbarRef}
          />
        )}
      </div>

      {!!template.layout && ['size2x6', 'sizeSingle2x6'].includes(template.layout.printSize) && (
        <TextSwitch
          label="Printing Options"
          helperText={
            <>
              Double 2x6 prints two copies of the 2x6 template onto a single 4x6 to be cut by your printer. If your
              printer supports single 2x6 prints, you can select that option.{' '}
              <a href="https://support.photoboothsupplyco.com/salsa/airprint" className="text-link">
                Learn more
              </a>
            </>
          }
          value={config.present.layout.printSize}
          onChange={(size) => updateConfig({ type: 'setPrintSize', size })}
        >
          <TextSwitchOption value="sizeSingle2x6">Single 2x6</TextSwitchOption>
          <TextSwitchOption value="size2x6">Double 2x6</TextSwitchOption>
        </TextSwitch>
      )}
    </>
  );
};

export default TemplateEditor;
