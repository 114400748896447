import { forwardRef } from 'react';
import { Box as ThemeUIBox } from 'theme-ui';

const Box = forwardRef((props, ref) => (
  <ThemeUIBox {...props} ref={ref}>
    {props.children}
  </ThemeUIBox>
));

export default Box;
