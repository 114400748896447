import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

export const useSteps = (totalSteps = 0, initialStep = 0) => {
  const [currentStep, setCurrentStep] = useState(initialStep);

  const canGoBack = currentStep - 1 >= 0;
  const canGoForward = currentStep + 1 <= totalSteps;

  const goBack = () => {
    if (!canGoBack) {
      return;
    }
    setCurrentStep(currentStep - 1);
  };

  const goForward = () => {
    if (!canGoForward) {
      return;
    }
    setCurrentStep(currentStep + 1);
  };

  return [currentStep, setCurrentStep, goBack, goForward, canGoBack, canGoForward];
};

export const Step = ({ index, children }) => (
  <AnimatePresence exitBeforeEnter>
    <motion.div
      key={index}
      initial={{ x: 12, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: -12, opacity: 0 }}
      transition={{ type: 'spring', bounce: 0.25 }}
    >
      <div>{children}</div>
    </motion.div>
  </AnimatePresence>
);
