import React from 'react';
import TextInput from '../core/TextInput';
import Button from '../core/Button';

class DeleteEventForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inputValue: '',
      disabled: true,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleChange(e) {
    let disabled = true;

    if (e.target.value === 'DELETE') {
      disabled = false;
    }

    this.setState({
      inputValue: e.target.value,
      disabled,
    });
  }

  handleDelete() {
    const { id, onClose, onDelete } = this.props;

    if (typeof onDelete === 'function') {
      onDelete(id);
    }
  }

  render() {
    const { onClose } = this.props;

    return (
      <div>
        <p>
          <strong>WARNING:</strong> Deleting an event is final and cannot be reversed. To confirm that you understand
          and are ready to continue, type &ldquo;DELETE&rdquo; (case-sensitive) into the field below.
        </p>
        <TextInput
          id="delete-event-confirm"
          label="Type confirmation here"
          value={this.state.inputValue}
          onChange={this.handleChange}
        />
        <div className="button-group button-group--spaced">
          <Button variant="primary" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="danger" disabled={this.state.disabled} onClick={this.handleDelete}>
            Delete
          </Button>
        </div>
      </div>
    );
  }
}

export default DeleteEventForm;
