import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { withRouter } from 'next/router';
import { Container, Row, Col } from 'reactstrap';
import Head from 'next/head';
import { scroller } from 'react-scroll';
import _ from 'lodash';
import { Router } from '../../routes';
import Logo from '../core/Logo';
import Background from '../Background';
import CtaButton from '../event/CtaButton';
import Button from '../core/Button';
import { SearchIcon, CloseIcon } from '../Icons';

class Header extends Component {
  constructor(props) {
    super(props);
    const {
      buttonText,
      pageType,
      subtitle,
      fontColor,
      buttonLink,
      buttonFlag,
      eventId,
      beta,
      contactEnabled,
      contactOwner,
      fullGalleryEnabled,
    } = props;
    this.state = {
      fullGalleryEnabled,
      pageType,
      fontColor,
      contactEnabled,
      contactOwner,
      buttonLink: buttonLink || `/${eventId}`,
      subtitle,
      buttonFlag,
      buttonText,
      beta,
      searchOpen: false,
    };
    this.timer = null;
  }

  componentDidMount() {
    this.getHeroPhotos();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.photos && !this.state.heroPhotos) {
      this.getHeroPhotos();
      const {
        buttonText,
        pageType,
        subtitle,
        buttonLink,
        buttonFlag,
        eventId,
        beta,
        contactEnabled,
        contactOwner,
        fullGalleryEnabled,
      } = this.props;
      this.setState({
        buttonText,
        pageType,
        subtitle,
        buttonLink,
        buttonFlag,
        eventId,
        beta,
        contactEnabled,
        contactOwner,
        fullGalleryEnabled,
      });
    }
  }

  getHeroPhotos = () => {
    if (this.props.photos) {
      const photos = Object.values(this.props.photos);
      const heroPhotos = [];

      // Only select static images
      for (let i = 0; i < photos.length; i += 1) {
        const photo = photos[i];
        // if (photo.type !== 'image') continue;

        heroPhotos.push(photo);
        if (heroPhotos.length >= 3) {
          break;
        }
      }

      this.setState({ heroPhotos });
    }
  };

  scrollToPhotos = () => {
    const { isPortfolio } = this.props;
    scroller.scrollTo('eventMedia', {
      duration: 1000,
      smooth: true,
      offset: isPortfolio ? -72 : 0,
    });
  };

  handleCallToAction(link) {
    window.location.assign(link);
  }

  render() {
    const {
      buttonLink,
      fontColor,
      buttonFlag,
      buttonText,
      subtitle,
      beta,
      fullGalleryEnabled,
      contactEnabled,
      contactOwner,
      pageType,
    } = this.state;
    const { environment, theme, isPortfolio } = this.props;

    const heroClasses = ['hero', 'hero--event'];
    if (theme) {
      heroClasses.push(`hero--${theme}`);
    }
    if (isPortfolio) {
      heroClasses.push('hero--portfolio');
    }

    let button;
    if (buttonFlag && buttonText && buttonLink) {
      if (!isPortfolio) {
        button = <CtaButton />;
      } else {
        button = (
          <Button
            link={true}
            href={buttonLink}
            className="btn"
            type="primary"
            target="_blank"
            style={{
              backgroundColor: this.props.accentColor,
              color: this.props.contrastColor,
            }}
          >
            {buttonText}
          </Button>
        );
      }
    }

    return (
      <div>
        <Head>
          <title>{this.props.title}</title>
          <meta name="description" content="Photobooth Live Gallery" />
        </Head>
        <header className={heroClasses.join(' ')}>
          <Container fluid={true}>
            {this.props.logo && (
              <div className="hero__logo">
                <Logo logoSrc={this.props.logo} link={this.props.logoLink} />
              </div>
            )}
            <h1 className="hero__title">{this.props.title}</h1>

            {<p className="hero__subtitle">{subtitle}</p>}

            {button}
          </Container>
          <button className="hero__scroll" onClick={this.scrollToPhotos}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 84 36">
              <path
                d="M42,36c-0.4,0-0.9-0.1-1.2-0.4l-40-32c-0.9-0.7-1-1.9-0.3-2.8c0.7-0.9,1.9-1,2.8-0.3l38.8,31l38.8-31
                            c0.9-0.7,2.1-0.5,2.8,0.3c0.7,0.9,0.6,2.1-0.3,2.8l-40,32C42.9,35.9,42.4,36,42,36z"
              />
            </svg>
            Scroll to captures
          </button>
          <Background
            background={this.props.background}
            backgroundColor={this.props.backgroundColor}
            heroPhotos={this.state.heroPhotos}
          />
          {this.props.children}
        </header>
      </div>
    );
  }
}

Header.propTypes = {
  date: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  logo: PropTypes.string,
  slim: PropTypes.bool,
  subtitle: PropTypes.string,
  buttonFlag: PropTypes.bool,
  buttonLink: PropTypes.string,
  buttonText: PropTypes.string,
  logoLink: PropTypes.string,
};

Header.defaultProps = {
  slim: false,
};

export default withRouter(Header);
