import _ from 'lodash';
import { useState } from 'react';
import useAppEvent from '../../../hooks/useAppEvent';
import useAppSettings from '../../../hooks/useAppSettings';
import { Button } from '../atoms';
import { SettingControl, DropboxFolderBrowser } from '.';

import FormSection from '../forms/FormSection'; // LEGACY
import Modal from '../core/Modal'; // LEGACY
import DeleteEventForm from '../events/DeleteEventForm'; // LEGACY

import {
  EVENT_NAME,
  EVENT_START_DATE,
  EVENT_END_DATE,
  EVENT_LOCATION,
  EVENT_PASSCODE_IS_ENABLED,
  EVENT_IS_INCLUDED_IN_PORTFOLIO,
  EVENT_DROPBOX_WATCH_FOLDER,
} from '~/src/scripts/constants/event/eventInfoSettings';

const EVENT_INFO_SETTINGS = [
  EVENT_NAME,
  EVENT_START_DATE,
  EVENT_END_DATE,
  EVENT_LOCATION,
  EVENT_PASSCODE_IS_ENABLED,
  EVENT_IS_INCLUDED_IN_PORTFOLIO,
  EVENT_DROPBOX_WATCH_FOLDER,
];

const EventInfoSettings = (props) => {
  // Use the active event
  const [appEvent, appEventLoading, appEventError, updateAppEvent, deleteAppEvent] = useAppEvent();

  // Use settings helper hook
  const [settingValues, updateSettingValues] = useAppSettings(EVENT_INFO_SETTINGS);

  // Sub-modal states
  const [isPortfolioModalOpen, setIsPortfolioModalOpen] = useState(false);
  const [isDeleteEventOpen, setIsDeleteEventOpen] = useState(false);
  const [isDropboxBrowserOpen, setIsDropboxBrowserOpen] = useState(false);

  // Handle Portfolio toggle
  const handleTogglePortfolio = (checked) => {
    if (checked && !_.get(appEvent, 'fullGalleryEnabled')) {
      // If Live Gallery is not enabled, Portfolio can't be either
      // Open modal to notify the user
      setIsPortfolioModalOpen(true);
      // Disable the Portfolio if it isn't already
      if (appEvent.portfolioEnabled) {
        updateSettingValues({ key: 'portfolioEnabled', value: false });
        updateAppEvent({ portfolioEnabled: false });
      }
    } else {
      updateSettingValues({ key: 'portfolioEnabled', value: checked });
      updateAppEvent({ portfolioEnabled: checked });
    }
  };

  // Enable the Live Gallery and Portfolio at the same time
  const enableGalleryAndPortfolio = () => {
    setIsPortfolioModalOpen(false);
    updateSettingValues({ key: 'portfolioEnabled', value: true });
    updateSettingValues({ key: 'fullGalleryEnabled', value: true });
    updateAppEvent({ fullGalleryEnabled: true, portfolioEnabled: true });
  };

  // Handle Dropbox watchPath selection
  const handleDropboxPathSelect = (path) => {
    setIsDropboxBrowserOpen(false);
    updateSettingValues({ key: 'dropbox.watchPath', value: path });
    updateAppEvent('dropbox.watchPath', path);
  };

  const handleSettingChange = (key, value) => {
    switch (key) {
      case 'portfolioEnabled':
        handleTogglePortfolio(value);
        break;
      case 'dropbox.watchPath':
        handleDropboxPathSelect(value);
        break;
      default:
        updateSettingValues({ key, value });
        updateAppEvent(key, value);
    }
  };

  return (
    <>
      {EVENT_INFO_SETTINGS.map((setting) => (
        <SettingControl
          key={setting.key}
          setting={setting}
          value={settingValues[setting.key]}
          onChange={(value) => handleSettingChange(setting.key, value)}
        />
      ))}

      <FormSection>
        <Button variant="error" onPress={() => setIsDeleteEventOpen(true)}>
          Delete Event
        </Button>
      </FormSection>

      <Modal size="small" isOpen={isDropboxBrowserOpen} onClose={() => setIsDropboxBrowserOpen(false)}>
        <DropboxFolderBrowser onSelect={handleDropboxPathSelect} />
      </Modal>

      <Modal
        title={`Delete \u201c${appEvent.title}\u201d`}
        size="small"
        isOpen={isDeleteEventOpen}
        onClose={() => setIsDeleteEventOpen(false)}
      >
        <DeleteEventForm id={appEvent.id} onClose={() => setIsDeleteEventOpen(false)} onDelete={deleteAppEvent} />
      </Modal>

      <Modal
        title="Include in Portfolio"
        size="small"
        isOpen={isPortfolioModalOpen}
        onClose={() => setIsPortfolioModalOpen(false)}
      >
        <p className="mb-lg">In order to show this event in your Portfolio, please enable the Live Gallery.</p>
        <div className="button-group button-group--spaced">
          <Button variant="secondary" onPress={() => setIsPortfolioModalOpen(false)}>
            Don&rsquo;t view in Portfolio
          </Button>
          <Button variant="primary" onPress={enableGalleryAndPortfolio}>
            Turn on Live Gallery
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default EventInfoSettings;
