import { Children, cloneElement } from 'react';
import { Flex, Box, Heading } from '../atoms';

const ListGroup = (props) => {
  const { title, titleLevel = 'h3', children } = props;
  return (
    <Flex
      mb={5}
      sx={{
        flexDirection: 'column',
        borderBottom: '1px solid',
        borderColor: 'grayLight',
      }}
    >
      {title && (
        <Heading as={titleLevel} mb={5} sx={{ fontSize: 3 }}>
          {title}
        </Heading>
      )}
      <Box as="ul" p={0} mb={3} sx={{ flexDirection: 'column', listStyle: 'none' }}>
        {Children.map(children, (child) => (
          <Box key={child.key} as="li" mb={4}>
            {cloneElement(child)}
          </Box>
        ))}
      </Box>
    </Flex>
  );
};

export default ListGroup;
