import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { FacebookIcon, TwitterIcon, InstagramIcon, CloseIcon } from '../Icons';

class CompanyInfo extends React.Component {
  constructor(props) {
    super(props);
    this.toggleTerms = this.toggleTerms.bind(this);
    this.toggleDisclaimer = this.toggleDisclaimer.bind(this);
    this.state = {
      termsOpen: false,
      disclaimerOpen: false,
    };
  }

  toggleTerms() {
    this.setState((prevState) => ({
      termsOpen: !prevState.termsOpen,
    }));
  }

  toggleDisclaimer() {
    this.setState((prevState) => ({
      disclaimerOpen: !prevState.disclaimerOpen,
    }));
  }

  render() {
    const { company, accentColor, contrastColor, termsLink, privacyLink, large } = this.props;

    const themeStyle = {
      fill: contrastColor,
      background: accentColor,
    };

    const className = ['company-info'];
    if (large) {
      className.push('company-info--large');
    }

    return (
      <div className={className.join(' ')}>
        {company.headline && company.headline.length && <h3 className="company-info__name">{company.headline}</h3>}

        <div className="company-info__links">
          {company.email && company.email.length && (
            <a className="company-info__link" href={`mailto:${company.email}`}>
              {company.email}
            </a>
          )}

          {company.website && company.website.length && (
            <a className="company-info__link" href={company.website}>
              {company.website}
            </a>
          )}

          {company.phone && company.phone.length && (
            <a className="company-info__link" href={`tel:${company.phone}`}>
              {company.phone}
            </a>
          )}
        </div>

        <ul className="social-links">
          {company.twitter && company.twitter.length && (
            <li className="social-links__link">
              <a href={company.twitter} target="_blank" style={themeStyle} rel="noreferrer">
                <TwitterIcon color={contrastColor} />
              </a>
            </li>
          )}

          {company.facebook && company.facebook.length && (
            <li className="social-links__link">
              <a href={company.facebook} target="_blank" style={themeStyle} rel="noreferrer">
                <FacebookIcon color={contrastColor} />
              </a>
            </li>
          )}

          {company.instagram && company.instagram.length && (
            <li className="social-links__link">
              <a href={company.instagram} target="_blank" style={themeStyle} rel="noreferrer">
                <InstagramIcon color={contrastColor} />
              </a>
            </li>
          )}
        </ul>

        {this.props.showDisclaimers && (termsLink || privacyLink) && (
          <div>
            <div className="company-info__disclaimers">
              {termsLink && <a href={termsLink}>Terms of Service</a>}
              {privacyLink && <a href={privacyLink}>Privacy Policy</a>}
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const event = state.activeEvent;

  return {
    company: ownProps.company ? ownProps.company : event.liveGallery ? event.liveGallery.company : {},
    accentColor: ownProps.accentColor || event.accentColor,
    contrastColor: ownProps.contrastColor || event.contrastColor,
    termsLink: event.liveGallery ? event.liveGallery.termsLink : '',
    privacyLink: event.liveGallery ? event.liveGallery.privacyLink : '',
  };
};

export default connect(mapStateToProps)(CompanyInfo);
