import { Box, Flex, Paragraph, Button } from '../atoms';
import { FolderRow, RadioGroup } from '../molecules';

const boxStyles = {
  borderTop: '1px solid',
  borderColor: 'grayLight',
  pl: 0,
  mb: 5,
  minHeight: '700px',
};

const FolderList = (props) => {
  const { folders, activePath, selectedPath, onFolderPress, onFolderSelect, isLoading } = props;

  // Go up one path level
  const goUpOneLevel = () => {
    const newPath = activePath.split('/').slice(0, -1).join('/');
    onFolderPress(newPath);
  };

  if (!!folders && !folders.length) {
    return (
      <Flex sx={{ ...boxStyles, justifyContent: 'center', alignItems: 'center' }}>
        <Box sx={{ textAlign: 'center' }}>
          <Paragraph variant="muted" sx={{ fontSize: 3 }}>
            There are no folders in this directory
          </Paragraph>
          <Button variant="textPrimary" onPress={goUpOneLevel}>
            Go back
          </Button>
        </Box>
      </Flex>
    );
  }

  return (
    <Box as="ul" sx={boxStyles}>
      {isLoading ? (
        [...Array(10).keys()].map((i) => <FolderRow key={i} isPlaceholder={true} />)
      ) : (
        <RadioGroup label="Select a folder" isLabelHidden={true} value={selectedPath} onChange={onFolderSelect}>
          {folders.map((folder) => (
            <FolderRow key={folder.id} folder={folder} onPress={onFolderPress} />
          ))}
        </RadioGroup>
      )}
    </Box>
  );
};

export default FolderList;
