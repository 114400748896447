import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { ERROR_ACTION, WAIT_FOR_ACTION } from 'redux-wait-for-action';
import {
  fetchPlans,
  fetchProfile,
  loadingEvent,
  subscribe,
  SUBSCRIBE_FAILURE,
  SUBSCRIBE_SUCCESS,
} from '../../../actions/actions';
import PaymentForm from './PaymentForm';
import { Heading, Box, Link } from '../atoms';

const proFeatures = [
  'One iPad License',
  'Unlimited Events and Captures',
  'Unlimited Text and Email Sharing',
  'Cloud Syncing',
  'Virtual Booth Events',
  'Photo',
  'GIF',
  'Video',
  'Boomerang',
  'Photo Templates and Overlays',
  'AirPrint',
  'Digital Masks and Glam AR Filters',
  'Brandable Screens',
  'Offline Queueing',
  'Surveys',
];

const SignupPaymentForm = (props) => {
  const dispatch = useDispatch();
  const organizationId = useSelector((store) => _.get(store, 'userData.user.organizationId'));
  const accessToken = useSelector((store) => _.get(store, 'userData.user.accessToken'));
  const stripeEnv = useSelector((store) => _.get(store, 'fetchProfile.profile.stripeEnv', 'prod'));
  // Find default plan for trial
  const planId = useSelector((store) => {
    const plans = _.get(store, 'fetchPlans.plans');
    if (!plans || !plans.length) {
      return null;
    }
    const defaultPlan = plans.find((plan) => plan.type === 'Monthly' && plan.title === 'Pro');
    if (!defaultPlan) {
      return null;
    }
    return defaultPlan.planId;
  });
  // Boolean to disable form if necessary values aren't available yet
  const isFormDisabled = !stripeEnv || !planId;

  // Submission loading state
  const [isLoading, setIsLoading] = useState(false);

  // Fetch plans for relevant stripeEnv
  useEffect(() => {
    if (stripeEnv) {
      dispatch(fetchPlans(stripeEnv));
    }
  }, [stripeEnv]);

  // Fetch organization data
  useEffect(() => {
    if (organizationId && accessToken) {
      dispatch(fetchProfile(organizationId, accessToken));
    }
  }, [accessToken]);

  // When the payment method has been added, subscribe to the trial
  const startTrialPeriod = ({ coupon }) => {
    setIsLoading(true);
    const planData = { planId, quantity: 1 };
    if (coupon) {
      planData.coupon = coupon;
    }
    const action = subscribe(planData, accessToken);
    action[WAIT_FOR_ACTION] = SUBSCRIBE_SUCCESS;
    action[ERROR_ACTION] = SUBSCRIBE_FAILURE;
    dispatch(action)
      .then((data) => {
        window.location.href = '/admin/events?trialStarted=true';
      })
      .catch((err) => {
        alert(err);
        setIsLoading(false);
      });
  };

  return (
    <PaymentForm
      isDisabled={isFormDisabled}
      isLoading={isLoading}
      onComplete={startTrialPeriod}
      isCouponFieldVisible={true}
      isAmountDueVisible={true}
      submitText="Start 30-day free trial"
    >
      <Heading as="h4" sx={{ fontSize: 2 }} mb={3}>
        Your Salsa Pro free trial includes these features:
      </Heading>
      <Box as="ul" mb={3} pl={4}>
        {proFeatures.map((feature) => (
          <Box as="li" key={feature}>
            {feature}
          </Box>
        ))}
      </Box>
      <Link target="_blank" variant="primary" href="https://photoboothsupplyco.com/pages/software-pricing">
        See all 30+ features
      </Link>
    </PaymentForm>
  );
};

export default SignupPaymentForm;
