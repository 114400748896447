import React from 'react';
import PropTypes from 'prop-types';

class Background extends React.Component {
  render() {
    if (this.props.background === 'none') {
      const images = [];
      if (this.props.heroPhotos) {
        this.props.heroPhotos.forEach((photo, index) => {
          images.push(
            <div className="collage__img" key={index}>
              <img src={photo.static} alt="" />
            </div>
          );
        });
      }
      return <div className="collage">{images}</div>;
    }
    return (
      <div className="hero__background">
        <img src={this.props.background} alt="" />
      </div>
    );
  }
}

Background.propTypes = {
  background: PropTypes.string.isRequired,
};

export default Background;
