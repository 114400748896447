/** @jsxImportSource theme-ui */
import dynamic from 'next/dynamic';
import { Box, Spinner } from '.';

const Icon = (props) => {
  const { variant, color, size = 24, isUnwrapped } = props;

  // Import SVG dynamically based on variant prop
  const SvgComponent = dynamic(
    () => import(`../icons/${variant}`)
    // { loading: () => <Spinner size={size} color={color} /> }
  );

  if (isUnwrapped) {
    return <SvgComponent aria-hidden={true} />;
  }

  return (
    <Box
      sx={{
        display: 'inline-flex',
        fontSize: size,
        fill: color,
        color,
      }}
      aria-hidden={true}
    >
      <SvgComponent />
    </Box>
  );
};

export default Icon;
