/** @jsxImportSource theme-ui */
import { useContext, useRef } from 'react';
import { useRadio } from 'react-aria';
import { VisuallyHidden } from '@react-aria/visually-hidden';
import { useFocusRing } from '@react-aria/focus';
import { Box } from '.';
import { RadioContext } from '../molecules/RadioGroup';

const strokeWidth = 2;

const Radio = (props) => {
  const { children } = props;
  const state = useContext(RadioContext);
  if (!state) {
    return null;
  }

  const ref = useRef(null);
  const { inputProps } = useRadio(props, state, ref);
  const { isFocusVisible, focusProps } = useFocusRing();

  const isSelected = inputProps.checked;

  return (
    <Box as="label" sx={{ display: 'inline-flex', alignItems: 'center', width: 24, height: 24 }}>
      <VisuallyHidden>
        <input {...inputProps} {...focusProps} ref={ref} />
      </VisuallyHidden>
      <svg width={24} height={24} aria-hidden="true">
        <circle
          cx={12}
          cy={12}
          r={12 - strokeWidth - 1}
          fill="none"
          stroke={isSelected || isFocusVisible ? '#9775f7' : '#e2e2e2'}
          strokeWidth={strokeWidth}
        />
        {isSelected && <circle cx={12} cy={12} r={5} fill="#9775f7" />}
      </svg>
      {children}
    </Box>
  );
};

export default Radio;
