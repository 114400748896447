import ReactDOM from 'react-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SelectMenu from '../core/SelectMenu';
import { listSysFonts } from '../../../actions/actions';

const parseFontData = (fonts) => {
  /* eslint-disable no-param-reassign */
  const styles = fonts.reduce((result, current) => {
    const { family, subfamily } = current;
    result[family] = result[family] || [];
    if (!result[family].find((f) => f.subfamily === subfamily)) {
      result[family].push({
        ...current,
        family,
        subfamily,
      });
    }
    return result;
  }, Object.create(null));
  /* eslint-enable no-param-reassign */
  const families = Object.keys(styles)
    .map((key) => {
      // If "Regular" variant exists, use that for the default style,
      // otherwise just use first available
      return styles[key].find((font) => font.subfamily === 'Regular') || styles[key][0];
    })
    .filter((f) => f);
  return [families, styles];
};

const ToolbarFontSelect = ({ currentFont, onSelect, onLoadFonts }) => {
  const dispatch = useDispatch();

  const fonts = useSelector((store) => store.sysFontsList);
  const accessToken = useSelector((store) => store.operator.accessToken);
  const [fontFamilies, setFontFamilies] = useState([]);
  const [fontStyles, setFontStyles] = useState({});

  useEffect(() => {
    if (fonts.data && !fonts.loading && !fonts.error) {
      const [families, styles] = parseFontData(fonts.data);
      setFontFamilies(families);
      setFontStyles(styles);
    }
  }, [fonts]);

  const handleSelect = (font) => {
    onSelect(font.name);
  };

  if (!fontFamilies.length || !currentFont) {
    return null;
  }
  const currentFontData = fonts.data.find((f) => f.name === currentFont);
  let currentFontFamily;
  if (currentFontData) {
    currentFontFamily = fontFamilies.find((f) => f.family === currentFontData.family);
  }

  return (
    <>
      <SelectMenu value={currentFontFamily ? currentFontFamily.name : null} id="font-family">
        {fontFamilies.map((font) => (
          <div
            key={font.url}
            value={font.name}
            label={font.family}
            onClick={() => handleSelect(font)}
            style={{ fontFamily: `"${font.name}"` }}
          />
        ))}
      </SelectMenu>
      {!!currentFontFamily && (
        <SelectMenu value={currentFont} id="font-style">
          {fontStyles[currentFontFamily.family].map((font) => (
            <div key={font.url} value={font.name} label={font.subfamily} onClick={() => handleSelect(font)} />
          ))}
        </SelectMenu>
      )}
    </>
  );
};

export default ToolbarFontSelect;
