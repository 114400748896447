import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const useRestrictSettingByPlan = (setting = {}) => {
  const { excludedPlans = [] } = setting;

  const currentPlan = useSelector((store) => store.fetchProfile?.profile?.plan);

  const isSettingAllowedByPlan = useMemo(() => {
    if (!currentPlan) {
      return false;
    }
    return !excludedPlans.includes(currentPlan.title.toLowerCase());
  }, [currentPlan]);

  return [isSettingAllowedByPlan];
};

export default useRestrictSettingByPlan;
