import { useState, useEffect, forwardRef, useRef } from 'react';
import AssetLibrary from '../../../containers/webapp/AssetLibrary';
import Tabs from '../core/Tabs';
import Button from '../core/Button';
import Modal from '../core/Modal';

const TAB_DATA = [
  {
    id: 'upload-background',
    title: 'Select Background',
    buttonText: 'Select Background',
    assetCategory: 'printTemplateBackground',
    image: '/static/templates/layers/background.jpg',
  },
  {
    id: 'upload-overlay',
    title: 'Select Overlay',
    buttonText: 'Select Overlay',
    assetCategory: 'printTemplateOverlay',
    image: '/static/templates/layers/overlay.jpg',
    overlayImage: '/static/templates/layers/overlay-overlay.jpg',
  },
  {
    id: 'upload-template-asset',
    title: 'Select Art Asset',
    description: 'Select a transparent .PNG that is less than 5MB',
    buttonText: 'Select Art Asset',
    assetCategory: 'printTemplateAsset',
    image: '/static/templates/layers/art-asset.jpg',
    overlayImage: '/static/templates/layers/overlay-art-asset.jpg',
  },
];

const TemplateEditorUploadModal = ({ isOpen, onClose, onSelectAsset, currentTemplate }) => {
  const [assetLibraryOpen, setAssetLibraryOpen] = useState(false);
  const [assetCategory, setAssetCategory] = useState(null);

  const handleSelectTab = (tab) => {
    setAssetCategory(tab.assetCategory);
    setAssetLibraryOpen(true);
  };

  const handleSelectAsset = (asset, type) => {
    setAssetLibraryOpen(false);
    setAssetCategory(null);
    onSelectAsset(asset, type);
  };

  const getDescriptionText = (tab) => {
    return `Select a ${currentTemplate.width}px wide by ${currentTemplate.height}px tall
    ${currentTemplate.type !== 'overlay' && tab !== 'upload-overlay' ? '.JPG or' : 'transparent'} .PNG`;
  };

  const getTabData = () => {
    if (currentTemplate && currentTemplate.type === 'overlay') {
      return TAB_DATA.slice(1);
    }
    return TAB_DATA;
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <h1 className="text-center">Select File</h1>

        <Tabs variant="spaced" modal={true}>
          {getTabData().map((tab) => (
            <div key={tab.id} id={tab.id} label={tab.title}>
              <img
                src={currentTemplate.type === 'overlay' ? tab.overlayImage || tab.image : tab.image}
                alt=""
                style={{
                  display: 'block',
                  margin: '0 auto 48px',
                  width: '100%',
                  maxWidth: '400px',
                }}
              />
              <p className="text-center text-muted delta">{tab.description || getDescriptionText(tab.id)}</p>
              <div className="text-center">
                <Button variant="primary" onClick={() => handleSelectTab(tab)}>
                  {tab.buttonText}
                </Button>
              </div>
            </div>
          ))}
        </Tabs>
      </Modal>
      <AssetLibrary
        key={assetCategory}
        isOpen={assetLibraryOpen}
        onClose={() => setAssetLibraryOpen(false)}
        onSelectAsset={(asset) => handleSelectAsset(asset, assetCategory)}
        category={assetCategory}
        accept=".png,.jpg,.jpeg"
      />
    </>
  );
};

export default TemplateEditorUploadModal;
