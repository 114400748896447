import { useRef } from 'react';
import { useCheckbox, useFocusRing, VisuallyHidden } from 'react-aria';
import { useToggleState } from 'react-stately';
import { Box, Icon } from '.';

const Checkbox = (props) => {
  const { children } = props;

  const state = useToggleState(props);
  const ref = useRef();
  const { inputProps } = useCheckbox(props, state, ref);
  const { isFocusVisible, focusProps } = useFocusRing();

  return (
    <>
      <VisuallyHidden>
        <input {...inputProps} {...focusProps} ref={ref} />
      </VisuallyHidden>
      <Box
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '18px',
          height: '18px',
          bg: state.isSelected ? 'primary' : 'transparent',
          color: 'white',
          borderWidth: 2,
          borderStyle: 'solid',
          borderColor: state.isSelected ? 'transparent' : 'grayLight',
          borderRadius: '2px',
          fontSize: 2,
        }}
      >
        {state.isSelected && <Icon variant="Common02" />}
      </Box>
    </>
  );
};

export default Checkbox;
