import { useEffect, useState } from 'react';
import { InfiniteScroll } from '.';

const InfiniteScrollWithStaticData = (props) => {
  const { data, scrollableTarget } = props;

  const [currentItems, setCurrentItems] = useState([]);

  const hasMore = currentItems.length !== data.length;

  const loadMore = () => {
    if (!hasMore) {
      return false;
    }
    setCurrentItems([...currentItems, ...data.slice(currentItems.length, currentItems.length + 8)]);
  };

  // When data prop changes, reset loaded items array
  useEffect(() => {
    setCurrentItems([]);
  }, [data]);

  // When loaded items array resets, load the first page of the new results
  useEffect(() => {
    if (!currentItems.length) {
      loadMore();
    }
  }, [currentItems]);

  return (
    <InfiniteScroll
      loadMore={loadMore}
      hasMore={hasMore}
      dataLength={currentItems.length}
      scrollableTarget={scrollableTarget}
    >
      {currentItems}
    </InfiniteScroll>
  );
};

export default InfiniteScrollWithStaticData;
