import React, { useState } from 'react';
import dynamic from 'next/dynamic';
import dot from 'dot-object';
import { useDispatch } from 'react-redux';
import useAppEvent from '../../../hooks/useAppEvent';
import useAppSettings from '../../../hooks/useAppSettings';
import { Spinner, Button, Paragraph } from '../atoms';
import { ListGroup } from '../molecules';
import { SettingCheckboxWithModal } from '.';
import Modal from '../core/Modal'; // LEGACY

import {
  PHOTO_CAPTURE_MODE_IS_ENABLED,
  GIF_CAPTURE_MODE_IS_ENABLED,
  GIF_CAPTURE_MODE_FRAME_INTERVAL,
  GIF_CAPTURE_MODE_FRAME_COUNT,
  GIF_CAPTURE_MODE_CAPTURE_INTERVAL,
  BOOMERANG_CAPTURE_MODE_IS_ENABLED,
  BOOMERANG_CAPTURE_MODE_UNLOOPED_DURATION,
  VIDEO_CAPTURE_MODE_IS_ENABLED,
  VIDEO_CAPTURE_MODE_DURATION,
  VIDEO_CAPTURE_MODE_IS_AUDIO_ENABLED,
  GALLERY_SHARING_MODE_IS_ENABLED,
  SHARING_STATION_IS_ENABLED,
} from '~/src/scripts/constants/event/eventModeSettings';

const TemplateModal = dynamic(() => import('../template-editor/TemplateModal'));

// isEnabled settings for all modes
const MODE_SETTINGS = [
  PHOTO_CAPTURE_MODE_IS_ENABLED,
  GIF_CAPTURE_MODE_IS_ENABLED,
  BOOMERANG_CAPTURE_MODE_IS_ENABLED,
  VIDEO_CAPTURE_MODE_IS_ENABLED,
  GALLERY_SHARING_MODE_IS_ENABLED,
  SHARING_STATION_IS_ENABLED,
];

// Capture Modes setting group
const CAPTURE_MODE_SETTINGS = [
  {
    key: 'photoSettings',
    toggleSetting: PHOTO_CAPTURE_MODE_IS_ENABLED,
    modalOverride: TemplateModal,
  },
  {
    key: 'gifSettings',
    toggleSetting: GIF_CAPTURE_MODE_IS_ENABLED,
    modalSettings: [GIF_CAPTURE_MODE_FRAME_INTERVAL, GIF_CAPTURE_MODE_FRAME_COUNT, GIF_CAPTURE_MODE_CAPTURE_INTERVAL],
  },
  {
    key: 'boomerangSettings',
    toggleSetting: BOOMERANG_CAPTURE_MODE_IS_ENABLED,
    modalSettings: [BOOMERANG_CAPTURE_MODE_UNLOOPED_DURATION],
  },
  {
    key: 'videoSettings',
    toggleSetting: VIDEO_CAPTURE_MODE_IS_ENABLED,
    modalSettings: [VIDEO_CAPTURE_MODE_DURATION, VIDEO_CAPTURE_MODE_IS_AUDIO_ENABLED],
  },
];

// Sharing Modes setting group
const SHARING_MODE_SETTINGS = [
  {
    key: 'gallerySettings',
    toggleSetting: GALLERY_SHARING_MODE_IS_ENABLED,
  },
  {
    key: 'sharingStationSettings',
    toggleSetting: SHARING_STATION_IS_ENABLED,
  },
];

const EventModeSettings = (props) => {
  const dispatch = useDispatch();

  // Use the active event
  const [appEvent, appEventLoading, appEventError, updateAppEvent] = useAppEvent();

  // Use settings helper hook
  const [settingValues, updateSettingValues] = useAppSettings(MODE_SETTINGS);

  // Brightness warning modal state
  const [brightnessWarning, setBrightnessWarning] = useState({ isOpen: false, isDismissed: false });

  // Handle updates to root settings
  const handleUpdate = (key, value) => {
    if (!key || value === undefined) {
      return;
    }
    // Update local state
    updateSettingValues({ key, value });
    // Save new setting data
    updateAppEvent(key, value);
  };

  // Handle updates to modal settings (multiple at once)
  const handleModalSettingsUpdate = (values) => {
    const updateObject = dot.object(values);

    // Handle brightness warning modal
    const videoDuration = dot.pick(VIDEO_CAPTURE_MODE_DURATION.key, updateObject);
    const ledBrightness = appEvent.ledRGBBrightness;
    if (videoDuration) {
      if (videoDuration > 30 && ledBrightness >= 50 && !brightnessWarning.isDismissed) {
        updateObject.ledRGBBrightness = 50;
        setBrightnessWarning({ isOpen: true, isDismissed: true });
      } else if (videoDuration <= 30 && ledBrightness < 50 && brightnessWarning.isDismissed) {
        setBrightnessWarning({ isOpen: false, isDismissed: false });
      }
    }

    // Save new setting data
    updateAppEvent(updateObject);
  };

  // Dismiss brightness warning modal so it won't show again
  const dismissBrightnessWarning = () => {
    setBrightnessWarning({ isOpen: false, isDismissed: true });
  };

  // Return nothing if appEventError exists or there's no appEvent at all
  if (appEventError || !appEvent) {
    return null;
  }

  // Return a loader if appEvent is still loading
  if (appEventLoading) {
    return <Spinner />;
  }

  return (
    <>
      {[
        ['Capture Modes', CAPTURE_MODE_SETTINGS],
        ['Sharing Modes', SHARING_MODE_SETTINGS],
      ].map(([title, modeSettings]) => (
        <ListGroup key={title} title={title} titleLevel="h2">
          {modeSettings.map(({ key, toggleSetting, modalSettings, modalOverride }) => (
            <SettingCheckboxWithModal
              key={key}
              setting={toggleSetting}
              value={settingValues[toggleSetting.key]}
              onChange={(value) => handleUpdate(toggleSetting.key, value)}
              modalSettings={modalSettings}
              modalOverride={modalOverride}
              onSaveModalSettings={handleModalSettingsUpdate}
            />
          ))}
        </ListGroup>
      ))}
      <Modal isOpen={brightnessWarning.isOpen} onClose={dismissBrightnessWarning} size="small">
        <Paragraph sx={{ textAlign: 'center', mb: 5 }}>
          To prevent overheating during extended video capture, the Flash Brightness will be set to 50% brightness.
        </Paragraph>
        <Paragraph sx={{ textAlign: 'center' }}>
          <Button variant="primary" onClick={dismissBrightnessWarning}>
            Dismiss
          </Button>
        </Paragraph>
      </Modal>
    </>
  );
};

export default EventModeSettings;
