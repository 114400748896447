import React from 'react';
import Ink from 'react-ink';
import Loading from '../Loading';

class FileButton extends React.Component {
  render() {
    const {
      id,
      children,
      href,
      onChange,
      variant,
      size,
      className,
      circle,
      disabled,
      type,
      loading,
      target,
      icon,
      accept,
      multiple,
    } = this.props;

    const buttonClasses = ['button'];
    if (variant) {
      buttonClasses.push(`button--${variant}`);
    }
    if (size) {
      buttonClasses.push(`button--${size}`);
    }
    if (circle) {
      buttonClasses.push('button--circle');
    }
    if (className) {
      buttonClasses.push(className);
    }

    let renderChildren = children;
    if (loading) {
      buttonClasses.push('button--loading');
      renderChildren = (
        <div className="media media--sm">
          <div className="media__img">
            <Loading small={true} />
          </div>
          <div className="media__body">{children}</div>
        </div>
      );
    } else if (icon) {
      renderChildren = (
        <div className="media media--sm">
          <div className="media__img">{icon}</div>
          <div className="media__body">{children}</div>
        </div>
      );
    }

    return (
      <div className="file-button">
        <label className={buttonClasses.join(' ')}>
          {renderChildren}
          <Ink />
          <input
            id={id}
            accept={accept}
            multiple={multiple}
            type="file"
            className="file-button__input"
            disabled={disabled}
            onChange={onChange}
          />
        </label>
      </div>
    );
  }
}

export default FileButton;
