/** @jsxImportSource theme-ui */
import { useEffect, useState } from 'react';
import uniqid from 'uniqid';
import { Box, Button, Heading, Paragraph, Grid, Alert, Image, Flex, Link } from '../atoms';
import { RadioGroup, RadioCard } from '../molecules';
import { useSteps, Step } from '../../../hooks/useSteps';
import AssetLibrary from '../../../containers/webapp/AssetLibrary'; // LEGACY

const RATIOS = {
  portrait: {
    id: 'portrait',
    name: 'Portrait',
    dimensions: [2048, 2732],
    previewDimensions: [255, 343],
    printSize: 'size4x6',
  },
  landscape: {
    id: 'landscape',
    name: 'Landscape',
    dimensions: [2732, 2048],
    previewDimensions: [343, 255],
    printSize: 'size4x6',
  },
  square: {
    id: 'square',
    name: 'Square',
    dimensions: [2048, 2048],
    previewDimensions: [343, 343],
    printSize: 'size6x6',
  },
};

const RATIO_KEYS = ['portrait', 'landscape', 'square'];

const OverlayCreateFromImage = (props) => {
  const { onCreateOverlay } = props;

  // Selected aspect ratio
  const [ratio, setRatio] = useState(null);

  // Selected asset
  const [selectedAsset, setSelectedAsset] = useState(null);

  // Asset library open state
  const [isAssetLibraryOpen, setIsAssetLibraryOpen] = useState(false);

  // Overlay error
  const [warningMessage, setWarningMessage] = useState(null);

  // Handle two steps:
  //
  // 0. Select aspect ratio and file
  // 1. Warnings, if applicable
  const [currentStep, goToStep] = useSteps(2);

  // When returning to the first step, clear the old asset
  useEffect(() => {
    if (currentStep === 0) {
      setSelectedAsset(null);
      setWarningMessage(null);
    }
  }, [currentStep]);

  // Close the modal on any step change
  useEffect(() => {
    setIsAssetLibraryOpen(false);
  }, [currentStep]);

  // Create overlay using the selected image
  const createOverlay = () => {
    if (!selectedAsset) {
      return;
    }
    const { originalUrl } = selectedAsset;
    const ratioData = RATIOS[ratio];
    const { dimensions, printSize, id } = ratioData;
    const [width, height] = dimensions;
    setIsAssetLibraryOpen(false);
    onCreateOverlay({
      width,
      height,
      type: 'overlay',
      layout: {
        aspectRatio: ratio,
        printSize,
      },
      texts: [],
      placeholders: [],
      images: [
        {
          width,
          height,
          xOrigin: 0,
          yOrigin: 0,
          rotationAngle: 0,
          src: originalUrl,
          zIndex: 1,
          id: uniqid(),
        },
      ],
    });
  };

  // Handle file selection
  const handleAsset = (asset) => {
    setWarningMessage(null);
    const ratioData = RATIOS[ratio];
    if (!ratioData) {
      return;
    }
    setSelectedAsset(asset);

    // Validate size
    const { dimensions } = ratioData;
    const [idealWidth, idealHeight] = dimensions;
    const { width, height, originalUrl } = asset;
    let message;
    if (width < idealWidth || height < idealHeight) {
      message =
        'It looks like the uploaded overlay is too small, which can affect its quality. Confirm if you want to proceed with the uploaded overlay, or upload a new one.';
    } else if (Math.round((width / height) * 100) !== Math.round((idealWidth / idealHeight) * 100)) {
      message =
        'It looks like the uploaded overlay has the wrong dimensions. Confirm if you want to proceed with the uploaded overlay or upload a new one.';
    }

    // If errors exist, go to next step to confirm,
    // otherwise create the overlay right away
    if (message) {
      setWarningMessage(message);
      goToStep(1);
    } else {
      createOverlay();
    }
  };

  // Render the current step
  const renderCurrentStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <>
            <Box mb={5} sx={{ textAlign: 'center' }}>
              <Heading as="h1" mb={1} sx={{ fontSize: 4 }}>
                Upload New Overlay
              </Heading>
              <Paragraph variant="muted">To upload an overlay, select the file orientation first</Paragraph>
            </Box>

            <RadioGroup label="Select file orientation" isLabelHidden={true} onChange={setRatio} value={ratio}>
              <Grid gap={[4, 6]} columns={[1, 1, 3]} mb={3}>
                {RATIO_KEYS.map((key) => (
                  <Box key={key}>
                    <RadioCard
                      label={RATIOS[key].name}
                      value={key}
                      description={`${RATIOS[key].dimensions[0]}px x ${RATIOS[key].dimensions[1]}px transparent .PNG`}
                    >
                      <Image mb={2} src={`/static/templates/overlay/${key}.svg`} alt="" />
                    </RadioCard>
                  </Box>
                ))}
              </Grid>
            </RadioGroup>

            <Box sx={{ textAlign: 'center' }}>
              <Paragraph variant="muted" sx={{ fontSize: 0 }} mb={2}>
                Maximum upload size: 5 MB
              </Paragraph>

              <Button variant="primary" isDisabled={!ratio} onPress={() => setIsAssetLibraryOpen(true)} mb={3}>
                Upload Overlay
              </Button>

              <Paragraph sx={{ fontSize: 1 }}>
                <Link target="_blank" href="https://support.photoboothsupplyco.com/salsa/file-requirements#overlays">
                  See all file requirements
                </Link>
              </Paragraph>
            </Box>

            <AssetLibrary
              category="overlay"
              isOpen={isAssetLibraryOpen}
              onClose={() => setIsAssetLibraryOpen(false)}
              onSelectAsset={handleAsset}
            />
          </>
        );
      case 1:
        return (
          <>
            <Box mb={5} sx={{ textAlign: 'center' }}>
              <Heading as="h1" mb={5} sx={{ fontSize: 4 }}>
                Upload
              </Heading>
              <Alert variant="warning">{warningMessage}</Alert>
              <Flex mb={5} sx={{ justifyContent: 'center' }}>
                <Box
                  sx={{
                    width: RATIOS[ratio].previewDimensions[0],
                    height: RATIOS[ratio].previewDimensions[1],
                    backgroundImage: `url('${selectedAsset.originalUrl}')`,
                    backgroundSize: '100% 100%',
                    border: '2px solid',
                    borderColor: 'grayLight',
                  }}
                />
              </Flex>
              <Flex sx={{ justifyContent: 'center', flexDirection: 'column', maxWidth: 400, margin: '0 auto' }}>
                <Button variant="primary" onPress={() => goToStep(0)} mb={4}>
                  Upload New Overlay
                </Button>
                <Button variant="secondary" onPress={createOverlay}>
                  Proceed with Uploaded Overlay
                </Button>
              </Flex>
            </Box>
          </>
        );
      default:
        return null;
    }
  };

  return <Step index={currentStep}>{renderCurrentStep()}</Step>;
};

export default OverlayCreateFromImage;
