import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _, { template } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  listPrintTemplates as listTemplates,
  listPrintTemplatesReset as listTemplatesReset,
  deletePrintTemplates as deleteTemplates,
} from '../../../actions/actions';
import SelectMenu from '../core/SelectMenu';
import SelectMenuItem from '../core/SelectMenuItem';
import IconButton from '../core/IconButton';
import TemplatePreview from './TemplatePreview';
import { BackIcon, TrashIcon } from '../../Icons';
import Loading from '../Loading';
import Checkbox from '../core/Checkbox';
import Button from '../core/Button';
import ConfirmDialog from '../core/ConfirmDialog';
import TextLink from '../core/TextLink';

const TemplateUploads = ({ type = 'print', onSelect, goBack, goToEditor, modalRef = {} }) => {
  const dispatch = useDispatch();

  const appEvent = useSelector((store) => store.appEvent.data);
  const accessToken = useSelector((store) => store.operator.accessToken);
  const templates = useSelector((store) => store.printTemplateList);

  const [loading, setLoading] = useState(true);
  const [templateList, setTemplateList] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const loadMore = (firstLoad) => {
    if (templates.loading) {
      return false;
    }
    const pagination = {
      count: 8,
    };
    if (templates.data.length /*  && !firstLoad */) {
      pagination.before = templates.data.slice(-1)[0].dateCreated;
    }
    dispatch(
      listTemplates(accessToken, {
        pagination,
        template: type === 'overlay' ? 'overlay' : 'print',
        order: 'desc',
      })
    );
  };

  const checkScrollLoad = () => {
    if (!modalRef.current || !templates.hasMore || templates.loading) {
      return;
    }
    if (modalRef.current.scrollHeight <= modalRef.current.clientHeight) {
      loadMore();
    }
  };

  const handleSelect = (props) => {
    onSelect(props);
    dispatch(listTemplatesReset());
  };

  const deleteSelected = () => {
    dispatch(deleteTemplates(selectedItems, accessToken));
    setSelectedItems([]);
  };

  // On mount, reset template list so it starts with the first page
  useEffect(() => {
    dispatch(listTemplatesReset());
  }, []);

  useEffect(() => {
    if (loading && !templates.loading) {
      setLoading(false);
    }
    checkScrollLoad();
  }, [templates.loading]);

  useEffect(() => {
    setTemplateList(templates.data);
    // Load initial page on first load, or when data is reset
    // (infinite scroller doesn't do it automatically)
    if (!templates.data.length && templates.hasMore) {
      loadMore();
    }
  }, [templates.data]);

  const deleteBtn = (
    <ConfirmDialog
      title={`Delete ${selectedItems.length} asset${selectedItems.length > 1 ? 's' : ''}`}
      description={`Are you sure you want to delete ${selectedItems.length > 1 ? 'these templates' : 'this template'}?`}
      buttonVariant="danger"
      confirmText="Delete"
      centered={true}
    >
      {(confirm) => (
        <Button variant="link-danger" size="compact" onClick={confirm(deleteSelected)}>
          <div className="media media--sm">
            <div className="media__img">
              <TrashIcon size="small" />
            </div>
            <div className="media__body">Delete</div>
          </div>
        </Button>
      )}
    </ConfirmDialog>
  );

  const selectAllBtn = (
    <div className="select-all">
      <div>
        {selectedItems.length} of {templateList.length} selected.
      </div>

      <Button variant="link" size="compact" onClick={() => setSelectedItems(templateList.map((t) => t.id))}>
        Select all
      </Button>
    </div>
  );

  const selectNoneBtn = (
    <Button variant="link" size="compact" onClick={() => setSelectedItems([])}>
      Cancel
    </Button>
  );

  return (
    <>
      <header className="multistep-header multistep-header--center mb-xl">
        <IconButton alt="Go back" onClick={goBack}>
          <BackIcon color="#777777" />
        </IconButton>
        <h1 className="gamma">Your {type === 'overlay' ? 'Overlay' : 'Template'} Designs</h1>
      </header>
      <div className={['loading-wrapper', loading ? 'loading-wrapper--active' : null].join(' ')}>
        <Loading loading={loading} />

        <div className="toolbar" style={{ height: '36px' }}>
          <div className="toolbar__section">
            {!!selectedItems.length && selectAllBtn}
            {!!selectedItems.length && selectNoneBtn}
          </div>
          <div className="toolbar__section toolbar__section--btns">{!!selectedItems.length && deleteBtn}</div>
        </div>

        <InfiniteScroll
          next={loadMore}
          hasMore={templates.hasMore}
          dataLength={templateList.length}
          loader={<Loading key="grid-loader" centered={true} loading={true} />}
          element="ul"
          className="list-grid list-grid--templates"
          scrollableTarget={modalRef.current}
        >
          {templateList.map((template) => (
            <li
              key={template.id}
              className={['list-grid__item', selectedItems.length ? 'list-grid__item--selected' : null].join(' ')}
            >
              <button onClick={() => handleSelect({ ...template, id: null })} style={{ background: 'transparent' }}>
                <TemplatePreview stageWidth={200} stageHeight={200} config={template} />
              </button>
              <Checkbox
                checked={selectedItems.includes(template.id)}
                onCheck={(checked) =>
                  setSelectedItems((items) => {
                    if (!checked) {
                      return items.filter((i) => i !== template.id);
                    }
                    return [...items, template.id];
                  })
                }
              />
            </li>
          ))}
        </InfiniteScroll>

        {!templates.loading && !templateList.length && type === 'overlay' && (
          <div className="text-center wrap-text mb-xl">
            <h3 className="beta">No Previous Designs Found</h3>
            <p>You can access your existing uploads or upload new overlays in the Overlay Editor.</p>
            <button className="text-link" onClick={goToEditor}>
              Click Here for the Overlay Editor
            </button>
          </div>
        )}

        {!templates.hasMore && <div className="no-more-dot"></div>}
      </div>
    </>
  );
};

export default TemplateUploads;
