import React from 'react';
import Datetime from 'react-datetime';
import Ink from 'react-ink';
import Button from '../core/Button';
import Modal from '../core/Modal';
import Checkbox from '../core/Checkbox';
import DateInput from '../core/DateInput';
import { FilterIcon } from '../../Icons';
import { isMobile } from '../../../utils/utils';

class Filters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dateFrom: this.props.filters.dateFrom || null,
      dateTo: this.props.filters.dateTo || null,
      order: this.props.filters.order || 'desc',
      captureMode: this.props.filters.captureMode || null,
      filterByTime: !!(this.props.filters.dateFrom || this.props.filters.dateTo),
      filterByCaptureMode: !!this.props.filters.captureMode,
      isOpen: false,
    };

    this.onFilterClick = this.onFilterClick.bind(this);
    this.onOrderChange = this.onOrderChange.bind(this);
    this.onCaptureModeChange = this.onCaptureModeChange.bind(this);
    this.onFilterByCaptureMode = this.onFilterByCaptureMode.bind(this);
    this.onFilterByTime = this.onFilterByTime.bind(this);
    this.isValidFromDate = this.isValidFromDate.bind(this);
    this.isValidToDate = this.isValidToDate.bind(this);
  }

  componentDidMount() {
    this.setState({ isMobile: isMobile() });
  }

  onFilterClick() {
    this.props.onFilter(this.state);
    this.toggleModal();
  }

  onOrderChange(e) {
    this.setState({
      order: e.currentTarget.value,
    });
  }

  onCaptureModeChange(e) {
    this.setState({
      filterByCaptureMode: true,
      captureMode: e.currentTarget.value,
    });
  }

  onFilterByCaptureMode(checked) {
    if (checked) {
      this.setState({
        filterByCaptureMode: true,
        captureMode: 'Photo',
      });
    } else {
      this.setState({
        filterByCaptureMode: false,
        captureMode: null,
      });
    }
  }

  onFilterByTime(checked) {
    if (checked) {
      this.setState({
        filterByTime: true,
      });
    } else {
      this.setState({
        filterByTime: false,
        dateFrom: null,
        dateTo: null,
      });
    }
  }

  isValidFromDate(current) {
    const today = Datetime.moment();
    const dateTo = this.state.dateTo;
    if (dateTo) {
      return current.isSameOrBefore(dateTo);
    }
    return true;
  }

  isValidToDate(current) {
    const today = Datetime.moment();
    const dateFrom = this.state.dateFrom;
    if (dateFrom) {
      return current.isSameOrAfter(dateFrom) && current.isSameOrBefore(today);
    }
    return current.isSameOrBefore(today);
  }

  toggleModal = () => {
    this.setState((prevState) => ({
      modalOpen: !prevState.modalOpen,
    }));
  };

  render() {
    const { modalOpen, order, dateFrom, dateTo, captureMode } = this.state;
    const propFilters = this.props.filters;

    let disabled = '';
    if (this.props.loading) {
      disabled = 'disabled';
    }

    let filtersActive = false;
    if (propFilters.dateFrom || propFilters.dateTo || propFilters.captureMode) {
      filtersActive = true;
    }

    return (
      <React.Fragment>
        <button className={`filter-toggle ${filtersActive ? 'filter-toggle--active' : ''}`} onClick={this.toggleModal}>
          {propFilters.order === 'desc' ? 'Newest → Oldest' : 'Oldest → Newest'}

          <FilterIcon />

          <Ink />
        </button>

        <Modal isOpen={modalOpen} onClose={this.toggleModal} size="small">
          <div className="filters">
            <section className="filters__section">
              <h2 className="filters__heading">Sort</h2>

              <div className="filters__radios">
                <input
                  type="radio"
                  name="order"
                  value="desc"
                  checked={this.state.order === 'desc'}
                  onChange={this.onOrderChange}
                  id="descInput"
                />
                <label htmlFor="descInput">Newest &rarr; Oldest</label>

                <input
                  type="radio"
                  name="order"
                  value="asc"
                  checked={this.state.order === 'asc'}
                  onChange={this.onOrderChange}
                  id="ascInput"
                />
                <label htmlFor="ascInput">Oldest &rarr; Newest</label>
              </div>
            </section>

            <section className="filters__section">
              <h2 className="filters__heading">Filters</h2>

              <div className="filters__checkbox">
                <Checkbox
                  id="styleInput"
                  type="checkbox"
                  label="Filter by style"
                  checked={this.state.filterByCaptureMode}
                  onCheck={this.onFilterByCaptureMode}
                />
              </div>

              <div
                className="filters__radios filters__radios--small"
                style={{ opacity: this.state.filterByCaptureMode ? '1' : '0.5' }}
              >
                <input
                  type="radio"
                  name="captureMode"
                  value="Photo"
                  checked={this.state.captureMode === 'Photo'}
                  onChange={this.onCaptureModeChange}
                  id="photoInput"
                />
                <label htmlFor="photoInput">Photo</label>

                <input
                  type="radio"
                  name="captureMode"
                  value="Video"
                  checked={this.state.captureMode === 'Video'}
                  onChange={this.onCaptureModeChange}
                  id="videoInput"
                />
                <label htmlFor="videoInput">Video</label>

                <input
                  type="radio"
                  name="captureMode"
                  value="Boomerang"
                  checked={this.state.captureMode === 'Boomerang'}
                  onChange={this.onCaptureModeChange}
                  id="boomerangInput"
                />
                <label htmlFor="boomerangInput">Boomerang</label>

                <input
                  type="radio"
                  name="captureMode"
                  value="GIF"
                  checked={this.state.captureMode === 'GIF'}
                  onChange={this.onCaptureModeChange}
                  id="gifInput"
                />
                <label htmlFor="gifInput">GIF</label>
              </div>

              <hr />

              <div className="filters__checkbox">
                <Checkbox
                  id="timeInput"
                  type="checkbox"
                  label="Filter by time"
                  checked={this.state.filterByTime}
                  onCheck={this.onFilterByTime}
                />
              </div>

              <div style={{ opacity: this.state.filterByTime ? '1' : '0.5' }}>
                <DateInput
                  id="dateFromInput"
                  label="Start date"
                  date={true}
                  isValidDate={this.isValidFromDate}
                  value={this.state.dateFrom}
                  onFocus={() => this.setState({ filterByTime: true })}
                  onChange={(current) => {
                    this.setState({
                      dateFrom: current,
                      filterByTime: true,
                    });
                  }}
                />

                <DateInput
                  id="dateToInput"
                  label="End date"
                  date={true}
                  isValidDate={this.isValidFromDate}
                  value={this.state.dateTo}
                  onFocus={() => this.setState({ filterByTime: true })}
                  onChange={(current) => {
                    this.setState({
                      dateTo: current,
                      filterByTime: true,
                    });
                  }}
                />
              </div>
            </section>

            <div className="filters__apply">
              <Button variant="primary" onClick={this.onFilterClick} disabled={disabled}>
                Apply
              </Button>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default Filters;
