import { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ReactDOM from 'react-dom';
import { Stage, Layer, Image, Rect, Circle, Path, Group, Transformer } from 'react-konva';
import Shape from './TemplateEditorCanvasShape';
import { templateIsDoubled } from '../../../utils/photoTemplates';

const TemplateEditorCanvas = (props) => {
  const {
    config = {
      images: [],
      texts: [],
      placeholders: [],
    },
    updateConfig,
    activeShape,
    setActiveShape,
    undo,
    redo,
    toolbarRef,
    resetHistory,
  } = props;

  // const [shapes, setShapes] = useState([]);
  // const [selectedId, selectShape] = useState(null);
  const [stageSize, setStageSize] = useState({ width: 0, height: 0 });
  const [editingText, setEditingText] = useState(false);

  // Konva stage
  const stageRef = useRef();
  const containerRef = useRef();
  const textEditRef = useRef();

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (containerRef.current.contains(e.target)) {
        return;
      }
      if (toolbarRef.current && toolbarRef.current.contains(e.target)) {
        return;
      }
      setActiveShape(null);
    };
    const handleResize = (e) => {
      if (containerRef.current) {
        setStageSize({
          width: containerRef.current.offsetWidth,
          height: containerRef.current.offsetHeight,
        });
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('resize', handleResize);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      setStageSize({
        width: containerRef.current.offsetWidth,
        height: containerRef.current.offsetHeight,
      });
    }
  }, [containerRef.current]);

  // Deselect transformer when clicked outside
  const checkDeselect = (e) => {
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      setActiveShape(null);
    }
  };

  useEffect(() => {
    if (!activeShape || activeShape.category !== 'texts') {
      setEditingText(null);
    }
  }, [activeShape]);

  if (!config || !config.placeholders) {
    return null;
  }

  const templateScale = {
    x: Math.min((stageSize.width / config.width) * 0.7, (stageSize.height / config.height) * 0.7),
    y: Math.min((stageSize.width / config.width) * 0.7, (stageSize.height / config.height) * 0.7),
  };

  const shapes = Object.entries(config)
    .reduce((accumulator, [key, value]) => {
      if (!['placeholders', 'images', 'texts'].includes(key)) {
        return accumulator;
      }
      accumulator.push(
        ...value.map((shape) => {
          return { ...shape, category: key };
        })
      );
      return accumulator;
    }, [])
    .sort((a, b) => (a.zIndex > b.zIndex ? 1 : -1));

  const renderTemplate = (isDouble = false) => (
    <>
      <Rect
        fill="#ffffff"
        x={0}
        y={0}
        width={config.width}
        height={config.height}
        strokeScaleEnabled={false}
        listening={false}
      />
      {shapes.map((shape, i) => (
        <Shape
          key={shape.id}
          category={shape.category}
          index={i}
          shapeProps={shape}
          stageRef={stageRef}
          stageSize={stageSize}
          templateSize={{ width: config.width, height: config.height }}
          templateScale={templateScale}
          isSelected={!isDouble && activeShape && shape.id === activeShape.id}
          onSelect={() => setActiveShape(shape)}
          listening={!isDouble}
          onChange={(payload, logHistory = true) => {
            updateConfig({
              type: 'updateShape',
              category: shape.category,
              id: shape.id,
              payload,
            });
            if (!logHistory) {
              resetHistory();
            }
          }}
          onDelete={() => {
            updateConfig({
              type: 'deleteShape',
              category: shape.category,
              id: shape.id,
            });
            setActiveShape(null);
          }}
        />
      ))}
    </>
  );

  const getTemplateX = (isDouble = false) => {
    const centerPos = stageSize.width / templateScale.x / 2 - config.width / 2;
    if (config.width > config.height) {
      // Landscape
      return centerPos;
    }
    if (isDouble) {
      return stageSize.width / templateScale.x / 2;
    }
    if (templateIsDoubled(config)) {
      return stageSize.width / templateScale.x / 2 - config.width;
    }
    return centerPos;
  };

  const getTemplateY = (isDouble = false) => {
    const centerPos = stageSize.height / templateScale.y / 2 - config.height / 2;
    if (config.width <= config.height) {
      // Square or Portrait
      return centerPos;
    }
    if (isDouble) {
      return stageSize.height / templateScale.y / 2;
    }
    if (templateIsDoubled(config)) {
      return stageSize.height / templateScale.y / 2 - config.height;
    }
    return centerPos;
  };

  const getFullHeight = () => {
    if (!templateIsDoubled(config)) {
      return config.height;
    }
    if (config.width > config.height) {
      // Landscape
      return config.height * 2;
    }
    return config.height;
  };

  const getFullWidth = () => {
    if (!templateIsDoubled(config)) {
      return config.width;
    }
    if (config.width <= config.height) {
      // Square or Portrait
      return config.width * 2;
    }
    return config.width;
  };

  return (
    <div
      className="template-editor__stage"
      ref={containerRef}
      style={{
        height: '72vh',
      }}
    >
      <Stage
        ref={stageRef}
        width={stageSize.width}
        height={stageSize.height}
        onMouseDown={checkDeselect}
        onTouchStart={checkDeselect}
      >
        <Layer>
          <Rect
            fill="#e2e2e2"
            x={0}
            y={0}
            width={stageSize.width}
            height={stageSize.height}
            strokeScaleEnabled={false}
            listening={false}
          />
        </Layer>
        <Layer scale={templateScale}>
          {templateIsDoubled(config) && (
            <Group x={getTemplateX(true)} y={getTemplateY(true)} opacity={0.15}>
              {renderTemplate(true)}
            </Group>
          )}
          <Group x={getTemplateX()} y={getTemplateY()}>
            {renderTemplate()}
          </Group>
          <Rect
            listening={false}
            shadowEnabled={true}
            shadowColor="#000"
            shadowBlur={50}
            shadowOffset={{ x: 0, y: 25 }}
            shadowOpacity={0.5}
            stroke="rgba(0,0,0,0.15)"
            strokeWidth={1}
            strokeScaleEnabled={false}
            x={getTemplateX()}
            y={getTemplateY()}
            width={getFullWidth()}
            height={getFullHeight()}
          />
        </Layer>
      </Stage>
    </div>
  );
};

export default TemplateEditorCanvas;
