import React from 'react';
import Ink from 'react-ink';
import Loading from '../Loading';
import { getContrastColor } from '../../../utils/utils';

function Button(props) {
  const {
    children,
    href,
    onClick,
    variant,
    size,
    className,
    circle,
    disabled,
    type,
    loading,
    target,
    icon,
    color,
    isEmbedMode,
  } = props;

  const buttonClasses = ['button'];
  if (variant) {
    buttonClasses.push(`button--${variant}`);
  }
  if (size) {
    buttonClasses.push(`button--${size}`);
  }
  if (circle) {
    buttonClasses.push('button--circle');
  }
  if (className) {
    buttonClasses.push(className);
  }

  let renderChildren = children;
  if (loading) {
    buttonClasses.push('button--loading');
    renderChildren = (
      <div className="media media--sm">
        <div className="media__img">
          <Loading small={true} />
        </div>
        <div className="media__body">{children}</div>
      </div>
    );
  } else if (icon) {
    renderChildren = (
      <div className="media media--sm">
        <div className="media__img">{icon}</div>
        <div className="media__body">{children}</div>
      </div>
    );
  }

  let buttonStyle = {};
  if (color) {
    buttonStyle = {
      backgroundColor: color,
      color: getContrastColor(color),
    };
  }
  if (isEmbedMode) {
    buttonStyle.pointer = 'default';
  }

  if (href) {
    return (
      <a href={href} className={buttonClasses.join(' ')} disabled={false} target={target} style={buttonStyle}>
        {renderChildren}
        {!isEmbedMode && <Ink />}
      </a>
    );
  }
  return (
    <button
      type={type || 'button'}
      className={buttonClasses.join(' ')}
      onClick={onClick}
      disabled={disabled}
      style={buttonStyle}
    >
      {renderChildren}
      {!isEmbedMode && <Ink />}
    </button>
  );
}

export default Button;
