import { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DropboxAuth } from 'dropbox';
import { Box, Paragraph, Heading, Icon } from '../atoms';
import { ButtonCard } from '../molecules';
import { updateOperator } from '../../../actions/actions';

const { dropboxClientId, webAppURL } = require('../../../constants').get();

const REDIRECT_URI = `${webAppURL}/admin/account`;

const AccountDropboxAuth = (props) => {
  const { authCode } = props;
  const dispatch = useDispatch();
  const operator = useSelector((store) => store.fetchProfile?.profile);
  const accessToken = useSelector((store) => store.operator?.accessToken);
  const [dropboxError, setDropboxError] = useState(null);

  const isDropboxEnabled = useMemo(() => {
    const { dropbox } = operator;
    if (!dropbox) {
      return false;
    }
    const { id, token } = dropbox;
    return !!id && !!token;
  }, [operator.dropbox]);

  // Dropbox auth helper
  const dbxAuth = useMemo(() => {
    return new DropboxAuth({ clientId: dropboxClientId });
  }, [dropboxClientId]);

  useEffect(() => {
    const hasRedirectedFromAuth = !!authCode;
    if (hasRedirectedFromAuth) {
      dbxAuth.setCodeVerifier(window.sessionStorage.getItem('codeVerifier'));
      dbxAuth
        .getAccessTokenFromCode(REDIRECT_URI, authCode)
        .then((response) => {
          const {
            result: { refresh_token: refreshToken, uid },
          } = response;
          dispatch(
            updateOperator(
              {
                dropbox: {
                  token: refreshToken,
                  id: uid,
                },
              },
              accessToken
            )
          );
        })
        .catch((error) => setDropboxError(error));
    }
  }, []);

  const doAuth = () => {
    window.sessionStorage.removeItem('codeVerifier');
    dbxAuth
      .getAuthenticationUrl(
        REDIRECT_URI, // redirectUri
        undefined, // state
        'code', // authType
        'offline', // tokenAccessType
        undefined, // scope
        undefined, // includeGrantedScopes
        true // usePKCE
      )
      .then((authUrl) => {
        window.sessionStorage.setItem('codeVerifier', dbxAuth.codeVerifier);
        window.location.href = authUrl;
      })
      .catch((error) => setDropboxError(error));
  };

  // Handle the button press
  const handlePress = () => {
    if (isDropboxEnabled) {
      // Remove the existing Dropbox ID and token from the operator
      dispatch(
        updateOperator(
          {
            dropbox: {
              token: null,
              id: null,
            },
          },
          accessToken
        )
      );
    } else {
      // Begin authentication process with Dropbox
      doAuth();
    }
  };

  return (
    <Box sx={{ maxWidth: 180 }}>
      <ButtonCard onPress={handlePress}>
        <Icon variant="Dropbox" sx={{ fontSize: 8 }} />
        <Heading as="h3" sx={{ fontSize: 4, mb: 3, mt: 1 }}>
          Dropbox
        </Heading>
        <Paragraph sx={{ fontWeight: 'bold', color: 'primary' }}>
          {isDropboxEnabled ? 'Disconnect' : 'Connect'}
        </Paragraph>
      </ButtonCard>
      <Paragraph sx={{ fontSize: 1 }}>Sync captures from all your events to your Dropbox account</Paragraph>
    </Box>
  );
};

export default AccountDropboxAuth;
