import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { listPrintTemplateLayouts } from '../../../actions/actions';
import SelectMenu from '../core/SelectMenu';
import SelectMenuItem from '../core/SelectMenuItem';
import IconButton from '../core/IconButton';
import TemplatePreview from '../template-editor/TemplatePreview';
import { BackIcon } from '../../Icons';
import Loading from '../Loading';

const ratios = [
  {
    id: 'portrait',
    name: 'Portrait',
    width: 2048,
    height: 2732,
    layout: {
      printSize: 'size4x6',
      aspectRatio: 'portrait',
    },
  },
  {
    id: 'landscape',
    name: 'Landscape',
    width: 2732,
    height: 2048,
    layout: {
      printSize: 'size4x6',
      aspectRatio: 'landscape',
    },
  },
  {
    id: 'square',
    name: 'Square',
    width: 2048,
    height: 2048,
    layout: {
      printSize: 'size6x6',
      aspectRatio: 'square',
    },
  },
].map((r) => ({
  ...r,
  type: 'overlay',
  images: [],
  texts: [],
  placeholders: [],
}));

const OverlayLayouts = ({ onSelect, onSelectRatio, goBack: goBackProp, modalRef = {} }) => {
  const dispatch = useDispatch();

  const [activeLayout, setActiveLayout] = useState(null);
  const wrapper = useRef();

  useEffect(() => {
    if (!!activeLayout && !!onSelectRatio) {
      onSelectRatio({ ...activeLayout, id: null });
    }
  }, [activeLayout]);

  const goBack = () => {
    if (activeLayout) {
      setActiveLayout(null);
    } else {
      goBackProp();
    }
  };

  return (
    <>
      <div className="flex flex--spaced flex--center flex--tablet-and-up">
        <header className="multistep-header multistep-header--center mb-xl">
          <IconButton alt="Go back" onClick={goBack}>
            <BackIcon color="#777777" />
          </IconButton>
          <h1 className="gamma">Choose Overlay Layout</h1>
        </header>
      </div>

      <ul className="grid-list grid-list--large">
        {ratios.map((ratio) => (
          <li key={ratio.id} className="grid-list__item">
            <button onClick={() => setActiveLayout(ratio)} className="grid-list__button">
              <img src={`/static/templates/overlay/${ratio.id}.svg`} alt="" />
              <p>{ratio.name}</p>
            </button>
          </li>
        ))}
      </ul>
    </>
  );
};

export default OverlayLayouts;
