import React from 'react';
import _ from 'lodash';
import Modal from './Modal';
import Button from './Button';

class ConfirmDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      callback: null,
    };
  }

  componentDidMount() {
    const { callback } = this.props;
    const isFunc = _.isFunction(callback);
    this.setState({
      open: isFunc,
      callback: isFunc ? () => callback() : null,
    });
  }

  componentDidUpdate(prevProps) {
    const { callback } = this.props;
    const isFunc = _.isFunction(callback);
    if (!_.isEqual(prevProps.callback, callback)) {
      this.setState({
        open: isFunc,
        callback: isFunc ? () => callback() : null,
      });
    }
  }

  show = (callback) => (event) => {
    event.preventDefault();

    const payload = {
      ...event,
      target: {
        ...event.target,
        value: event.target.value,
      },
    };

    this.setState({
      open: true,
      callback: () => callback(payload),
    });
  };

  hide = () => {
    this.setState({
      open: false,
      callback: null,
    });
  };

  confirm = () => {
    this.state.callback();
    this.hide();
  };

  render() {
    const { children, title, description, centered } = this.props;

    let { confirmText, cancelText, buttonVariant } = this.props;
    if (!confirmText) {
      confirmText = 'Confirm';
    }
    if (!cancelText) {
      cancelText = 'Cancel';
    }
    if (!buttonVariant) {
      buttonVariant = 'primary';
    }

    return (
      <div>
        {children && children(this.show)}

        <Modal isOpen={this.state.open} onClose={this.hide} title={title} size="small">
          {description && <p className={centered ? 'text-center' : ''}>{description}</p>}

          <div className="button-group button-group--spaced">
            <Button variant="secondary" onClick={this.hide}>
              {cancelText}
            </Button>

            <Button variant={buttonVariant} onClick={this.confirm}>
              {confirmText}
            </Button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default ConfirmDialog;
