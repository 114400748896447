import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '../atoms';
import { TextField, CheckboxRow } from '../molecules';
// LEGACY
import NumberInput from '../core/NumberInput';
import { CheckIcon } from '../../Icons';

const checkIsSettingValid = (setting = {}) => {
  const { key, label, control } = setting;
  if (!key || !label || !control || typeof control !== 'object') {
    return false;
  }
  return true;
};

const SettingControl = (props) => {
  const { setting, value, onChange, onPress, onDelete } = props;
  if (!checkIsSettingValid(setting)) {
    return null;
  }
  const { planOverrides } = setting;

  // Current subscription plan title
  const planTitle = useSelector((store) => (store.fetchProfile?.profile?.plan?.title || '').toLowerCase());

  // If setting has any different allowed values according to the owner's plan, apply them
  const settingProps = useMemo(() => {
    if (!planOverrides || !planOverrides[planTitle]) {
      return setting;
    }
    return { ...setting, control: Object.assign(setting.control, planOverrides[planTitle]) };
  }, [planOverrides]);

  // Destructure setting controls
  const { key, label, control, description, supportLink, supportText } = settingProps;
  const { type, options, min, max, unit, step } = control;

  switch (type) {
    case 'checkbox':
      return (
        <CheckboxRow
          label={label}
          description={description}
          onChange={onChange}
          isSelected={!!value}
          supportLink={supportLink}
          supportText={supportText}
        />
      );
    case 'text':
      return <TextField type="text" label={label} description={description} onChange={onChange} value={value} />;
    case 'number':
      return <NumberInput min={min} max={max} step={step} unit={unit} onChange={onChange} value={value} />;
    case 'inline-select':
      // LEGACY
      // Should be replaced with an equivalent molecule by Q4 refresh
      return (
        <ul className="radio-list">
          {options.map((option) => (
            <li className="radio-list__option radio" key={option.key}>
              <input
                type="radio"
                id={option.key}
                name={key}
                className="radio__input"
                checked={option.value === value}
                onChange={() => onChange(option.value)}
              />
              <label htmlFor={option.key} className="radio__label">
                <CheckIcon />
                {option.label}
              </label>
            </li>
          ))}
        </ul>
      );
    case 'button': {
      const { emptyButtonText, removeButtonText } = control;
      return (
        <Button variant="textPrimary" onPress={value ? onDelete : onPress}>
          {value ? removeButtonText : emptyButtonText}
        </Button>
      );
    }
    default:
      return null;
  }
};

export default SettingControl;
