import React, { Component } from 'react';
import Ink from 'react-ink';

class Tab extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { id, onClick } = this.props;
    onClick(id);
  }

  render() {
    const {
      handleClick,
      props: { activeTab, id, label, accentColor },
    } = this;

    let className = 'tab-list__item';

    if (activeTab === id) {
      className += ' tab-list__item--active';
    }

    return (
      <button
        className={className}
        onClick={handleClick}
        style={{
          boxShadow: activeTab === id ? `inset 0 -4px 0 0 ${accentColor}` : `inset 0 0 0 0 ${accentColor}`,
        }}
      >
        {label}
        <Ink />
      </button>
    );
  }
}

export default Tab;
