/** @jsxImportSource theme-ui */
import { createContext, useEffect } from 'react';
import { useRadioGroup } from 'react-aria';
import { VisuallyHidden } from '@react-aria/visually-hidden';
import { useRadioGroupState } from '@react-stately/radio';
import { Box, Text } from '../atoms';

export const RadioContext = createContext(null);

const RadioGroup = (props) => {
  const { children, label, isLabelHidden } = props;
  const state = useRadioGroupState(props);
  const { radioGroupProps, labelProps } = useRadioGroup(props, state);

  const LabelComponent = isLabelHidden ? VisuallyHidden : Text;

  return (
    <Box {...radioGroupProps}>
      <LabelComponent {...labelProps}>{label}</LabelComponent>
      <RadioContext.Provider value={state}>{children}</RadioContext.Provider>
    </Box>
  );
};

export default RadioGroup;
