import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { listSysTemplates } from '../../../actions/actions';
import TemplatePreview from './TemplatePreview';
import { BackIcon } from '../../Icons';
import Loading from '../Loading';
import { InfiniteScrollWithStaticData } from '../organisms';
import TemplateFilters from './TemplateFilters';
import { colorKeys, rgbValues, checkColor } from '../../../utils/color';

const TemplateLibrary = ({ type = 'print', onSelect, activeLayout, modalRef = {} }) => {
  const dispatch = useDispatch();

  const appEvent = useSelector((store) => store.appEvent.data);
  const accessToken = useSelector((store) => store.operator.accessToken);
  const sysTemplates = useSelector((store) => store.sysTemplatesList.data);
  const currentTemplates = useSelector((store) =>
    store.sysTemplatesList.data.filter((template) => {
      if (type === 'print') {
        return (
          template.template.layout.aspectRatio === activeLayout.layout.aspectRatio &&
          template.template.layout.printSize === activeLayout.layout.printSize &&
          template.template.placeholders.length === activeLayout.placeholders.length &&
          template.type === type
        );
      }
      return template.template.layout.aspectRatio === activeLayout.layout.aspectRatio && template.type === type;
    })
  );
  const isLoading = useSelector((store) => store.sysTemplatesList.loading);

  const [validTemplates, setValidTemplates] = useState([]);

  // Load templates on mount
  useEffect(() => {
    dispatch(listSysTemplates(type, accessToken));
  }, []);

  const updateValidTemplates = ({ colors, themes }) => {
    const checkedColors = Object.entries(colors)
      .filter((c) => c[1].checked)
      .map((c) => c[0]);
    const checkedThemes = Object.entries(themes)
      .filter((t) => t[1].checked)
      .map((t) => t[0]);
    setValidTemplates(
      currentTemplates.filter((t) => {
        return (
          (checkedColors.length ? checkedColors.includes(checkColor(t.color)) : true) &&
          (checkedThemes.length ? checkedThemes.includes(t.theme) : true)
        );
      })
    );
  };

  const handleFilter = ({ colors, themes }) => {
    updateValidTemplates({ colors, themes });
  };

  const buildTemplateList = () => [
    <button
      key="blankTemplate"
      onClick={() =>
        onSelect({
          ...activeLayout,
          images: [],
          texts: [],
          id: null,
        })
      }
      style={{ background: 'transparent' }}
    >
      <TemplatePreview stageWidth={200} stageHeight={200} config={activeLayout} />
    </button>,
    ...validTemplates.map((template) => (
      <button
        key={template.key}
        onClick={() => onSelect({ ...template.template, id: null, psdLink: template.key })}
        style={{ background: 'transparent' }}
      >
        <img
          src={template.thumbnailUrl}
          alt={template.template.id}
          style={{
            height: 200,
            width: 200,
            objectFit: 'contain',
            objectPosition: 'center',
            transform: 'scale(0.9)',
            filter: 'drop-shadow(0 3px 4px rgba(0,0,0,0.2))',
          }}
        />
      </button>
    )),
  ];

  return (
    <div className={['loading-wrapper', isLoading ? 'loading-wrapper--active' : null].join(' ')}>
      <Loading loading={true} />
      {!!currentTemplates.length && (
        <TemplateFilters allTemplates={sysTemplates} currentTemplates={currentTemplates} onFilter={handleFilter} />
      )}
      <InfiniteScrollWithStaticData scrollableTarget={modalRef.current} data={buildTemplateList()} />
    </div>
  );
};

export default TemplateLibrary;
