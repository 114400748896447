import { useMemo } from 'react';
import { Box, Button } from '../atoms';

const FolderCrumbs = (props) => {
  const { activePath, onPathChange } = props;

  // Keep active path components as an array to render them separately
  const pathParts = useMemo(() => {
    if (!activePath) {
      return [''];
    }
    return activePath.split('/');
  }, [activePath]);

  // Handle a breadcrumb press
  const handlePress = (index) => {
    // Based on the pressed breadcrumb's index, construct a new path to navigate to
    onPathChange(pathParts.slice(0, index + 1).join('/'));
  };

  return (
    <Box as="ul" sx={{ display: 'flex', flexWrap: 'wrap', pl: 0, mb: 4, listStyle: 'none' }}>
      {pathParts.map((path, index) => (
        <Box as="li" key={path}>
          <Button
            onPress={() => handlePress(index)}
            variant={index === pathParts.length - 1 ? 'breadcrumbCurrent' : 'breadcrumb'}
            sx={{ whiteSpace: 'nowrap' }}
          >
            {path.length ? path : 'Home'}
          </Button>
        </Box>
      ))}
    </Box>
  );
};

export default FolderCrumbs;
