import { useState, useEffect, useRef } from 'react';
import TextLink from '../core/TextLink';
import { colorKeys, rgbValues, checkColor } from '../../../utils/color';

const TemplateFilters = ({ allTemplates, currentTemplates, loading, onFilter, onClear, onClose }) => {
  const [visible, setVisible] = useState(false);

  const [colors, setColors] = useState(
    /* eslint-disable no-param-reassign */
    colorKeys.reduce((obj, color) => {
      obj[color] = {
        checked: false,
        possible: !!currentTemplates.find((t) => checkColor(t.color) === color),
      };
      return obj;
    }, Object.create(null))
    /* eslint-enable no-param-reassign */
  );

  const [themeKeys, setThemeKeys] = useState([...new Set(allTemplates.map((t) => t.theme))]);
  useEffect(() => {
    setThemeKeys([...new Set(allTemplates.map((t) => t.theme))]);
  }, [allTemplates]);

  const [themes, setThemes] = useState(
    /* eslint-disable no-param-reassign */
    themeKeys.reduce((obj, theme) => {
      obj[theme] = {
        checked: false,
        possible: !!currentTemplates.find((t) => t.theme === theme),
      };
      return obj;
    }, Object.create(null))
    /* eslint-enable no-param-reassign */
  );

  const handleColorChange = (e) => {
    const color = e.target.id.split('-')[1];
    const { checked } = e.target;

    const newColors = { ...colors, [color]: { ...colors[color], checked } };

    // Set theme checked state
    setColors(newColors);

    // Find invalid color combinations
    setThemes((currentThemes) => {
      const enabledColors = colorKeys.filter((color) => newColors[color].checked);
      /* eslint-disable no-param-reassign */
      return themeKeys.reduce((newThemes, theme) => {
        newThemes[theme] = {
          ...currentThemes[theme],
          possible: enabledColors.length
            ? !!currentTemplates.find((template) => {
                return enabledColors.includes(checkColor(template.color)) && template.theme === theme;
              })
            : !!currentTemplates.find((t) => t.theme === theme),
        };
        return newThemes;
      }, Object.create(null));
      /* eslint-enable no-param-reassign */
    });
  };

  const handleThemeChange = (e) => {
    const theme = e.target.id.split('-')[1];
    const { checked } = e.target;

    const newThemes = { ...themes, [theme]: { ...themes[theme], checked } };

    // Set theme checked state
    setThemes(newThemes);

    // Find invalid combinations
    setColors((currentColors) => {
      const enabledThemes = themeKeys.filter((theme) => newThemes[theme].checked);
      /* eslint-disable no-param-reassign */
      return colorKeys.reduce((newColors, color) => {
        newColors[color] = {
          ...currentColors[color],
          possible: enabledThemes.length
            ? !!currentTemplates.find((template) => {
                return enabledThemes.includes(template.theme) && checkColor(template.color) === color;
              })
            : !!currentTemplates.find((t) => checkColor(t.color) === color),
        };
        return newColors;
      }, Object.create(null));
      /* eslint-enable no-param-reassign */
    });
  };

  useEffect(() => {
    if (onFilter) {
      onFilter({ colors, themes });
    }
  }, [colors, themes]);

  const handleClose = () => {
    setVisible(false);
    if (onClose) {
      onClose();
    }
  };

  const handleClear = () => {
    setColors(
      /* eslint-disable no-param-reassign */
      colorKeys.reduce((obj, color) => {
        obj[color] = {
          checked: false,
          possible: !!currentTemplates.find((t) => checkColor(t.color) === color),
        };
        return obj;
      }, Object.create(null))
      /* eslint-enable no-param-reassign */
    );
    setThemes(
      /* eslint-disable no-param-reassign */
      themeKeys.reduce((obj, theme) => {
        obj[theme] = {
          checked: false,
          possible: !!currentTemplates.find((t) => t.theme === theme),
        };
        return obj;
      }, Object.create(null))
      /* eslint-enable no-param-reassign */
    );
  };

  return (
    <div className="flex">
      <div className="text-link-menu" style={{ marginLeft: 'auto' }}>
        <button className="text-link text-link-menu__toggle" onClick={() => setVisible(true)}>
          FILTERS
        </button>

        {visible && (
          <>
            <div className="text-link-menu__dropdown">
              <div className="template-filters">
                <div className="template-filters__row template-filters__head">
                  <button className="text-link text-link--muted" onClick={handleClear}>
                    Clear filters
                  </button>
                  <button className="text-link" onClick={handleClose}>
                    Done
                  </button>
                </div>
                <div className="template-filters__row">
                  <h3>Colors</h3>
                  <ul className="color-filters">
                    {colorKeys.map((color) => (
                      <li key={color} className="color-filters__item">
                        <input
                          type="checkbox"
                          name="colors"
                          id={`color-${color}`}
                          onChange={handleColorChange}
                          checked={colors[color].checked}
                          disabled={!colors[color].possible || loading}
                        />
                        <label
                          htmlFor={`color-${color}`}
                          style={{ backgroundColor: `rgb(${rgbValues[color].join(',')})` }}
                        >
                          {color}
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="template-filters__row">
                  <h3>Categories</h3>
                  <ul className="column-list">
                    {themeKeys
                      .filter((k) => themes[k])
                      .map((theme) => (
                        <li key={theme} className="column-list__item">
                          <input
                            type="checkbox"
                            name="themes"
                            id={`theme-${theme}`}
                            onChange={handleThemeChange}
                            checked={themes[theme].checked}
                            disabled={!themes[theme].possible || loading}
                          />
                          <label htmlFor={`theme-${theme}`}>{theme}</label>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="text-link-menu-backdrop" onClick={() => setVisible(false)} />
          </>
        )}
      </div>
    </div>
  );
};

export default TemplateFilters;
