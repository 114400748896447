import { useSelector } from 'react-redux';
import useRestrictSettingByPlan from '../../../hooks/useRestrictSettingByPlan';
import { SettingControl } from '.';
import { Box, Flex, Link, Paragraph } from '../atoms';

const SettingRowWithControl = (props) => {
  const { setting, value, onChange, onPress, onDelete, children } = props;
  const { label, description, supportLink, supportText = 'Learn more', excludedPlans } = setting;

  const [isSettingAllowedByPlan] = useRestrictSettingByPlan(setting);
  if (!isSettingAllowedByPlan) {
    return null;
  }

  return (
    <Box as="label" sx={{ display: 'block', mb: 4 }}>
      <Flex sx={{ justifyContent: 'space-between', maxWidth: '640px' }}>
        <Box sx={{ pr: 4, mb: 3 }}>
          <Paragraph sx={{ fontSize: 2 }}>{label}</Paragraph>
          {description && <Paragraph sx={{ fontSize: 1 }}>{description}</Paragraph>}
          {supportLink && (
            <Paragraph sx={{ fontSize: 1 }}>
              <Link target="_blank" href={supportLink}>
                {supportText}
              </Link>
            </Paragraph>
          )}
        </Box>
        <Box sx={{ flexShrink: 0 }}>
          <SettingControl setting={setting} value={value} onChange={onChange} onPress={onPress} onDelete={onDelete} />
        </Box>
      </Flex>
      {children}
    </Box>
  );
};

export default SettingRowWithControl;
