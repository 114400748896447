import React, { Component } from 'react';
import _ from 'lodash';
import SubTab from './SubTab';
import PriorityNav from './PriorityNav';

class SubTabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeSubTab:
        this.props.activeSubTab && this.props.children.find((c) => c.props.id === this.props.activeSubTab)
          ? this.props.activeSubTab
          : this.props.children[0].props.id,
      isSwitching: false,
    };

    this.handleClick = this.handleClick.bind(this);
    this.timeout = null;
    this.switchTime = 250;
  }

  componentDidMount() {
    if (_.isFunction(this.props.onTabChange)) {
      this.props.onTabChange(this.state.activeSubTab);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  componentDidUpdate(prevProps, prevState) {
    if (_.isFunction(this.props.onTabChange) && !_.isEqual(prevState.activeSubTab, this.state.activeSubTab)) {
      this.props.onTabChange(this.state.activeSubTab);
    }

    if (prevProps.activeSubTab !== this.props.activeSubTab) {
      this.handleClick(this.props.activeSubTab);
    }
  }

  handleClick(tab) {
    if (tab === this.state.activeSubTab) {
      return;
    }
    if (!this.props.children.find((c) => c.props.id === tab)) {
      return;
    }
    this.setState({ isSwitching: true });
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState({
        activeSubTab: tab,
        isSwitching: false,
      });
    }, this.switchTime);
  }

  render() {
    const {
      handleClick,
      props: { children },
      state: { activeSubTab, isSwitching },
    } = this;

    const mobileNavItems = children.map((child) => ({
      id: child.props.id,
      label: child.props.label,
      onClick: () => handleClick(child.props.id),
      disabled: child.props.disabled,
    }));

    const contentClassNames = ['sub-tabs__content'];
    if (isSwitching) {
      contentClassNames.push('sub-tabs__content--closing');
    }

    return (
      <div>
        <nav className="sub-tabs-mobile">
          <PriorityNav items={mobileNavItems} />
        </nav>

        <div className="sub-tabs">
          <nav className="sub-tabs__list sub-tabs-list">
            {children.map((child) => {
              const { id, label, disabled } = child.props;

              return (
                <SubTab
                  activeTab={activeSubTab}
                  key={label}
                  id={id}
                  label={label}
                  onClick={handleClick}
                  disabled={disabled}
                />
              );
            })}
          </nav>

          <div className={contentClassNames.join(' ')} key={activeSubTab}>
            {children.map((child) => {
              if (child.props.id !== activeSubTab) {
                return undefined;
              }
              return child.props.children;
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default SubTabs;
