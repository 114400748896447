import React from 'react';
import Ink from 'react-ink';

class SelectMenuItem extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.props.handleClick(this.props);
  }

  render() {
    return (
      <li className="block-list__item">
        <button
          className="block-list__link"
          href={this.props.href}
          onClick={this.handleClick}
          tabIndex={!this.props.menuOpen ? -1 : null}
          type="button"
          style={this.props.style}
        >
          {this.props.label}
        </button>
      </li>
    );
  }
}

export default SelectMenuItem;
