/** @jsxImportSource theme-ui */
import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import uniqid from 'uniqid';
import Modal from '../core/Modal';
import TemplateEditor from '../template-editor/TemplateEditor'; // LEGACY
import OverlayLayouts from './OverlayLayouts';
import OverlayCreateFromImage from './OverlayCreateFromImage';
import TemplateUploads from '../template-editor/TemplateUploads'; // LEGACY
import TemplateLibrary from '../template-editor/TemplateLibrary'; // LEGACY
import TemplateExitModal from '../template-editor/TemplateExitModal'; // LEGACY
import { useSteps, Step } from '../../../hooks/useSteps';
import { Grid, Box, Card, Heading } from '../atoms';
import { IconCard } from '../molecules';
import { getPrintTemplate } from '../../../actions/actions';

// Ensure all added placeholders have IDs for proper layering/reordering
const addPlaceholderIds = (data) => {
  return {
    ...data,
    placeholders: data.placeholders.map((p) => {
      return { ...p, id: p.id || uniqid() };
    }),
  };
};

const OverlayModal = ({ currentOverlayId = null, isOpen, onClose, onSave }) => {
  const dispatch = useDispatch();
  const accessToken = useSelector((store) => store.operator.accessToken);
  const currentOverlay = useSelector((store) => store.printTemplates.data[currentOverlayId]);

  // Current selected aspect ratio
  const [activeRatio, setActiveRatio] = useState(null);

  // Current active overlay
  const [activeTemplate, setActiveTemplate] = useState(null);

  // Open state for close modal confirmation
  const [isExitModalOpen, setIsExitModalOpen] = useState(false);

  // Keep ref for modal
  const modalRef = useRef();
  const handleModalRef = (el) => {
    modalRef.current = el;
  };

  // Handle multiple steps:
  //
  // 0. Select source
  // 1. Select overlay layout (aspect ratio)
  // 2. Choose previously-saved overlay
  // 3. Choose PBSCO system overlay
  // 4. Choose image as source for new overlay
  // 5. Edit the chosen overlay
  const [currentStep, goToStep, goBack, goForward] = useSteps(5);

  // Fetch existing overlay by ID if necessary
  useEffect(() => {
    if (!currentOverlayId) {
      return;
    }
    dispatch(getPrintTemplate(currentOverlayId, accessToken));
  }, [currentOverlayId]);

  // If we're editing an existing overlay, set it as the active template
  // as soon as its data is available in the store.
  useEffect(() => {
    if (currentOverlay && !activeTemplate) {
      setActiveTemplate(currentOverlay);
    }
  }, [currentOverlay]);

  // If at any time a template is selected, go directly to the editor step
  useEffect(() => {
    if (activeTemplate) {
      goToStep(5);
    }
  }, [activeTemplate]);

  // Restore initial state and finally close the modal
  const handleCloseFinally = () => {
    setIsExitModalOpen(false);
    setActiveTemplate(null);
    onClose();
    goToStep(0);
  };

  // Set the active aspect ratio
  const handleSelectRatio = (ratio) => {
    setActiveRatio(ratio);
    // Go to system overlay list to show overlays for selected ratio
    goToStep(3);
  };

  // Set the active overlay template
  const handleSelectTemplate = (template) => {
    setActiveTemplate(addPlaceholderIds(template));
  };

  // Close when overlay is saved
  const handleSaveComplete = (overlayId) => {
    if (onSave) {
      onSave(overlayId);
    }
    handleCloseFinally();
  };

  // Attempt to close the modal
  const handleClose = () => {
    if (currentStep === 5 && !isExitModalOpen) {
      // If we're editing an Overlay, ask for confirmation first
      setIsExitModalOpen(true);
    } else {
      // Otherwise close immediately
      handleCloseFinally();
    }
  };

  // Render the current step
  const renderCurrentStep = () => {
    switch (currentStep) {
      // Select source
      case 0:
        return (
          <>
            <Heading as="h1" sx={{ textAlign: 'center', fontSize: 4 }}>
              Create New Overlay
            </Heading>
            <Grid columns={[1, 1, 3]}>
              <Box>
                <IconCard
                  iconVariant="Documents17"
                  buttonText="Use Previous Design"
                  onButtonPress={() => goToStep(2)}
                  mb={0}
                />
              </Box>
              <Box>
                <IconCard
                  iconVariant="Grid04"
                  buttonText="Use Overlay Library"
                  onButtonPress={() => goToStep(1)}
                  mb={0}
                />
              </Box>
              <Box>
                <IconCard iconVariant="Ui60" buttonText="Upload New Overlay" onButtonPress={() => goToStep(4)} mb={0} />
              </Box>
            </Grid>
          </>
        );
      // Select overlay layout (aspect ratio)
      case 1:
        return <OverlayLayouts onSelectRatio={handleSelectRatio} goBack={() => goToStep(0)} modalRef={modalRef} />;
      // Choose previously-saved overlay
      case 2:
        return (
          <TemplateUploads
            onSelect={handleSelectTemplate}
            goBack={() => goToStep(0)}
            type="overlay"
            modalRef={modalRef}
          />
        );
      // Choose PBSCO system overlay
      case 3:
        return (
          <>
            <Heading as="h1" sx={{ textAlign: 'center', fontSize: 4 }}>
              Choose an Overlay
            </Heading>
            <TemplateLibrary
              activeLayout={activeRatio}
              onSelect={handleSelectTemplate}
              goBack={() => goToStep(0)}
              type="overlay"
              modalRef={modalRef}
            />
          </>
        );
      // Choose image as source for new overlay
      case 4:
        return <OverlayCreateFromImage goBack={() => goToStep(0)} onCreateOverlay={handleSelectTemplate} />;
      // Edit the chosen overlay
      case 5:
        if (!activeTemplate) {
          return null;
        }
        return (
          <TemplateEditor
            key={activeTemplate.id}
            template={activeTemplate}
            goBack={() => setIsExitModalOpen(true)}
            onSave={handleSaveComplete}
            type="overlay"
          />
        );
      default:
        return null;
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} handleOverlayRef={handleModalRef}>
      <Step index={currentStep}>{renderCurrentStep()}</Step>
      <TemplateExitModal
        isOpen={isExitModalOpen}
        onClose={() => setIsExitModalOpen(false)}
        onCloseFinally={handleCloseFinally}
      />
    </Modal>
  );
};

export default OverlayModal;
