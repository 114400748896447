import React from 'react';
import moment from 'moment';
import Datetime from 'react-datetime';

class DateInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active: false,
      focused: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.input = React.createRef();
  }

  componentDidMount() {
    const { value, autofocus } = this.props;

    if (value) {
      this.setState({
        active: true,
      });
    }

    if (autofocus && this.input) {
      this.input.current.focus();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props;

    if (value && value !== prevProps.value) {
      this.setState({
        active: true,
      });
    }
  }

  handleChange(e) {
    const { onChange } = this.props;

    if (typeof onChange === 'function') {
      onChange(e);
    }
  }

  handleFocus() {
    const { onFocus } = this.props;

    if (typeof onFocus === 'function') {
      onFocus();
    }

    this.setState({
      active: true,
      focused: true,
    });
  }

  handleBlur() {
    const { value } = this.state;

    if (!value || (!value.length && typeof value !== 'object')) {
      this.setState({
        active: false,
        focused: false,
      });
    } else {
      this.setState({
        active: true,
        focused: false,
      });
    }
  }

  render() {
    const { id, defaultValue, label, type, date, value } = this.props;

    const classNames = ['form-row', 'form-row--text'];
    if (this.state.active) {
      classNames.push('form-row--active');
    }
    if (this.state.focused) {
      classNames.push('form-row--focused');
    }

    return (
      <div className={classNames.join(' ')}>
        <label htmlFor={id} className="form-row__label form-row--text__label">
          {label}
        </label>

        <Datetime
          isValidDate={this.props.isValidDate}
          viewDate={this.props.viewDate}
          ref={this.dateTime}
          key={value}
          value={value}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          dateFormat="MMMM Do, YYYY"
          timeFormat={false}
          inputProps={{
            id,
            className: 'form-row--text__input text-input',
            readOnly: true,
            ref: this.input,
            autoComplete: 'off',
          }}
        />
      </div>
    );
  }
}

export default DateInput;
