import React, { Component } from 'react';
import _ from 'lodash';
import Tab from './Tab';
import SelectMenu from './SelectMenu';
import SelectMenuItem from './SelectMenuItem';

class Tabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: this.props.activeTab ? this.props.activeTab : this.props.children[0].props.id,
      hash: false,
      isSwitching: false,
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleUrlHash = this.handleUrlHash.bind(this);
    this.timeout = null;
    this.switchTime = 250;
  }

  componentDidMount() {
    this.handleUrlHash();
    if (_.isFunction(this.props.onTabChange)) {
      this.props.onTabChange(this.state.activeTab);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.hash !== prevState.hash) {
      this.handleUrlHash();
    }

    if (_.isFunction(this.props.onTabChange) && !_.isEqual(prevState.activeTab, this.state.activeTab)) {
      this.props.onTabChange(this.state.activeTab);
    }

    if (prevProps.activeTab !== this.props.activeTab) {
      this.handleClick(this.props.activeTab);
    }
  }

  handleUrlHash() {
    if (window.location.hash) {
      const { children } = this.props;
      const hash = window.location.hash.substr(1);
      if (children.filter((el) => el).find((el) => el.props.id === hash)) {
        this.setState({
          activeTab: hash,
          hash: true,
        });
      }
    } else {
      this.setState({
        activeTab: this.props.children[0].props.id,
        hash: false,
      });
    }
  }

  handleClick(tab) {
    if (tab === this.state.activeTab) {
      return;
    }
    if (!this.props.children.filter((c) => c).find((c) => c.props.id === tab)) {
      return;
    }
    this.setState({ isSwitching: true });
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState({
        activeTab: tab,
        isSwitching: false,
      });
    }, this.switchTime);
  }

  render() {
    const {
      handleClick,
      props: { children, modal, autoWidth, padded, accentColor, tabsOnMobile, variant },
      state: { activeTab, isSwitching },
    } = this;

    const tabsClassNames = ['tabs'];
    if (modal) {
      tabsClassNames.push('tabs--modal');
    }
    if (autoWidth) {
      tabsClassNames.push('tabs--autowidth');
    }
    if (padded) {
      tabsClassNames.push('tabs--padded');
    }
    if (variant) {
      tabsClassNames.push(`tabs--${variant}`);
    }

    const contentClassNames = ['tabs__content'];
    if (isSwitching) {
      contentClassNames.push('tabs__content--closing');
    }

    return (
      <div>
        {!tabsOnMobile && (
          <nav className="tabs-mobile-nav">
            <SelectMenu value={activeTab}>
              {children
                .filter((c) => c)
                .map((child) => {
                  const { id, label } = child.props;
                  if (!id) {
                    return null;
                  }

                  return <SelectMenuItem key={id} id={id} label={label} value={id} onClick={() => handleClick(id)} />;
                })}
            </SelectMenu>
          </nav>
        )}

        <div className={tabsClassNames.join(' ')}>
          <nav className="tabs__list tab-list">
            {children
              .filter((c) => c)
              .map((child) => {
                const { id, label } = child.props;

                if (!id) {
                  return child;
                }

                return (
                  <Tab
                    activeTab={activeTab}
                    key={id}
                    id={id}
                    label={label}
                    onClick={handleClick}
                    accentColor={accentColor}
                  />
                );
              })}
          </nav>
          <div className={contentClassNames.join(' ')} key={activeTab}>
            {children
              .filter((c) => c)
              .map((child) => {
                if (child.props.id !== activeTab) {
                  return null;
                }
                return child.props.children;
              })}
          </div>
        </div>
      </div>
    );
  }
}

export default Tabs;
