import React from 'react';
import _ from 'lodash';
import Ink from 'react-ink';
import { Link } from '../../../routes';
import Checkbox from '../core/Checkbox';
import constants from '../../../constants';

class AssetTile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: false,
    };

    this.handleCheck = this.handleCheck.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectAll, selectNone } = this.props;

    if (selectAll && prevProps.selectAll !== selectAll) {
      this.setState({
        selected: true,
      });
    }

    if (selectNone && prevProps.selectNone !== selectNone) {
      this.setState({
        selected: false,
      });
    }
  }

  handleCheck(value) {
    if (typeof this.props.onSelect === 'function') {
      this.props.onSelect({
        isSelected: value,
        id: this.props.asset.id,
      });
    }

    this.setState({ selected: value });
  }

  handleClick() {
    const { onClick, asset } = this.props;
    if (_.isFunction(onClick)) {
      onClick(asset);
    }
  }

  render() {
    const { eventId, asset, selecting, selected } = this.props;

    const classNames = ['card', 'card--flat', 'card--compact', 'media-tile'];
    if (selecting) {
      classNames.push('media-tile--selectable');
    }

    const linkTabIndex = selecting ? '-1' : null;

    return (
      <li className="grid__tile">
        <div className={classNames.join(' ')}>
          <button className="card__click-area" tabIndex={linkTabIndex} onClick={this.handleClick}>
            <span className="sr-only">View Asset</span>
            <Ink />
          </button>

          <Checkbox id={`check-${asset.id}`} onCheck={this.handleCheck} checked={this.state.selected} />

          <div className="card__img media-tile__img">
            <img alt={asset.id} src={asset.thumbnailUrl} />
          </div>
        </div>
      </li>
    );
  }
}

export default AssetTile;
