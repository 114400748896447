import { useEffect, useState, useMemo } from 'react';
import { Dropbox, DropboxAuth } from 'dropbox';
import { motion, AnimateSharedLayout } from 'framer-motion';
import { Heading, Box, Button } from '../atoms';
import { FolderCrumbs, FolderList } from '.';

const { dropboxClientId } = require('../../../constants').get();

const filterByFolderType = (entries) => {
  return entries.filter((entry) => {
    return entry['.tag'] === 'folder' && entry.name !== 'PBSCO_Salsa';
  });
};

const DropboxFolderBrowser = (props) => {
  const { refreshToken, currentPath = null, onSelect } = props;

  // Current active path, e.g. '/folder'. Empty string for root
  const [activePath, setActivePath] = useState('');
  // Entries inside active folder
  const [folderData, setFolderData] = useState(null);
  // Current selected folder if any
  const [selectedPath, setSelectedPath] = useState(currentPath);
  // Display an error from Dropbox if necessary
  const [dropboxError, setDropboxError] = useState(null);
  const [dbx, setDbx] = useState(false);

  // On mount, trade Dropbox refreshToken for accessToken
  useEffect(async () => {
    if (!refreshToken) {
      return null;
    }
    const dbxAuth = new DropboxAuth({ clientId: dropboxClientId, refreshToken });
    await dbxAuth.checkAndRefreshAccessToken();
    const accessToken = dbxAuth.getAccessToken();
    setDbx(new Dropbox({ accessToken }));
  }, [refreshToken]);

  // Retrieve next entries from Dropbox based on a cursor returned by getFolders
  const getNextEntriesFromCursor = async (cursor, processFiles) => {
    let cursorResponse;
    try {
      cursorResponse = await dbx.filesListFolderContinue({ cursor });
    } catch (error) {
      setDropboxError(error);
      return;
    }
    if (processFiles) {
      processFiles(cursorResponse);
    }
    const { cursor: nextCursor, has_more: cursorHasMore } = cursorResponse;
    if (cursorHasMore) {
      await getNextEntriesFromCursor(nextCursor, processFiles);
    }
  };

  // Retrieve all folders at a given path, calling getNextEntriesFromCursor as necessary
  const getFolders = async (atPath = '') => {
    if (!dbx) {
      return null;
    }
    // Request list of files and folders from Dropbox
    let response;
    try {
      response = await dbx.filesListFolder({
        path: atPath,
        recursive: false,
      });
    } catch (error) {
      setDropboxError(error.message);
      return null;
    }
    // Destructure Dropbox response
    const {
      result: { entries, cursor, has_more: hasMore },
    } = response;
    // Filter response by folder type
    const folders = filterByFolderType(entries);
    // If hasMore is true, retrieve remaining entries
    const restOfFolders = [];
    if (hasMore && cursor) {
      // Recursively retrieve entries using the cursor(s)
      // until we've retrieved all subfolders at this level
      await getNextEntriesFromCursor(cursor, (more) => restOfFolders.push(filterByFolderType(more.entries)));
    }
    // Concatenate folders
    return restOfFolders.length ? [...folders, ...restOfFolders] : folders;
  };

  // When Dropbox is first available, and on every subsequent path change, update the path data
  useEffect(async () => {
    if (!dbx) {
      return;
    }
    setFolderData(null);
    setSelectedPath(currentPath); // Revert to existing selected path
    const newFolderData = await getFolders(activePath);
    setFolderData(newFolderData);
  }, [dbx, activePath]);

  // Handle final selection of a folder
  const handleSelectFolder = () => {
    if (typeof onSelect === 'function') {
      onSelect(selectedPath);
    }
  };

  return (
    <>
      <Heading as="h1" sx={{ fontSize: 4, mb: 5 }}>
        Dropbox Watch Folder
      </Heading>
      <FolderCrumbs activePath={activePath} onPathChange={setActivePath} />
      <FolderList
        folders={folderData}
        activePath={activePath}
        selectedPath={selectedPath}
        onFolderPress={setActivePath}
        onFolderSelect={setSelectedPath}
        isLoading={!folderData}
      />
      <Box sx={{ textAlign: 'center' }}>
        <Button variant="primary" onPress={handleSelectFolder} isDisabled={!selectedPath}>
          Select folder
        </Button>
      </Box>
    </>
  );
};

export default DropboxFolderBrowser;
