import Modal from '../core/Modal';
import Button from '../core/Button';

const TemplateExitModal = ({ isOpen, onClose, onCloseFinally }) => {
  return (
    <Modal isOpen={isOpen} size="small" centered={true}>
      <h2 className="beta text-center">Are you sure?</h2>
      <p className="text-center">You will lose all unsaved changes.</p>
      <div className="button-group">
        <Button variant="secondary" onClick={onCloseFinally}>
          Exit
        </Button>
        <Button variant="primary" onClick={onClose}>
          Stay on page
        </Button>
      </div>
    </Modal>
  );
};

export default TemplateExitModal;
