export const PHOTO_CAPTURE_MODE_IS_ENABLED = {
  key: 'captureConfig.modeConfiguration.photoConfiguration.isEnabled',
  label: 'Photo',
  control: {
    type: 'checkbox',
  },
};

export const GIF_CAPTURE_MODE_IS_ENABLED = {
  key: 'captureConfig.modeConfiguration.gifConfiguration.isEnabled',
  label: 'GIF',
  control: {
    type: 'checkbox',
  },
};

export const GIF_CAPTURE_MODE_FRAME_INTERVAL = {
  key: 'captureConfig.modeConfiguration.gifConfiguration.frameInterval',
  label: 'Playback speed',
  control: {
    type: 'inline-select',
    options: [
      {
        key: 'slow',
        label: 'Slow',
        value: 1,
      },
      {
        key: 'medium',
        label: 'Medium',
        value: 0.8,
      },
      {
        key: 'fast',
        label: 'Fast',
        value: 0.6,
      },
    ],
  },
};

export const GIF_CAPTURE_MODE_FRAME_COUNT = {
  key: 'captureConfig.modeConfiguration.gifConfiguration.frameCount',
  label: 'Number of frames',
  control: {
    type: 'number',
    min: 2,
    max: 6,
  },
};

export const GIF_CAPTURE_MODE_CAPTURE_INTERVAL = {
  key: 'captureConfig.modeConfiguration.gifConfiguration.captureInterval',
  label: 'Timer',
  control: {
    type: 'number',
    min: 1,
    max: 5,
    unit: 's',
  },
};

export const BOOMERANG_CAPTURE_MODE_IS_ENABLED = {
  key: 'captureConfig.modeConfiguration.boomerangConfiguration.isEnabled',
  label: 'Boomerang',
  control: {
    type: 'checkbox',
  },
};

export const BOOMERANG_CAPTURE_MODE_UNLOOPED_DURATION = {
  key: 'captureConfig.modeConfiguration.boomerangConfiguration.unloopedDuration',
  label: 'Unlooped video duration',
  control: {
    type: 'number',
    min: 0.5,
    max: 4,
    unit: 's',
    step: 0.5,
  },
};

export const VIDEO_CAPTURE_MODE_IS_ENABLED = {
  key: 'captureConfig.modeConfiguration.videoConfiguration.isEnabled',
  label: 'Video',
  control: {
    type: 'checkbox',
  },
};

export const VIDEO_CAPTURE_MODE_DURATION = {
  key: 'captureConfig.modeConfiguration.videoConfiguration.duration',
  label: 'Video duration',
  control: {
    type: 'number',
    min: 2,
    max: 15,
    unit: 's',
  },
  planOverrides: {
    pro: {
      max: 120,
    },
  },
};

export const VIDEO_CAPTURE_MODE_IS_AUDIO_ENABLED = {
  key: 'captureConfig.modeConfiguration.videoConfiguration.audioEnabled',
  label: 'Record audio',
  control: {
    type: 'inline-select',
    options: [
      {
        key: 'off',
        label: 'Off',
        value: false,
      },
      {
        key: 'on',
        label: 'On',
        value: true,
      },
    ],
  },
};

export const GALLERY_SHARING_MODE_IS_ENABLED = {
  key: 'captureConfig.modeConfiguration.galleryConfiguration.isEnabled',
  label: 'Gallery',
  description: 'Allow guests to share older captures from the device itself.',
  control: {
    type: 'checkbox',
  },
};

export const SHARING_STATION_IS_ENABLED = {
  key: 'sharingStationConfig.isEnabled',
  label: 'Sharing Station',
  description: 'Allow device to be turned into a standalone sharing station.',
  supportLink: 'https://support.photoboothsupplyco.com/salsa/sharing-station',
  control: {
    type: 'checkbox',
  },
  excludedPlans: ['plus'],
};
