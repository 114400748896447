import { Container, Row, Col, Modal, ModalBody, Input, FormFeedback, FormGroup, Form } from 'reactstrap';
import React, { Component } from 'react';
import autoBind from 'auto-bind';
import Button from './core/Button';

export class EventPasswordModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
    };
  }

  setPassword(event) {
    this.setState({
      password: event.target.value,
    });
  }

  onKeyPress(event) {
    if (event.key === 'Enter') {
      this.props.submitEventPassword(this.state.password);
    }
  }

  render() {
    const { error, hint, name, label, variant } = this.props;
    let inputClass = 'form-input';
    if (error) {
      inputClass += ' error';
    }

    return (
      <Modal className="event-dialog" isOpen={true}>
        <ModalBody className={`event-modal ${variant || ''}`}>
          <Container>
            <Row noGutters={true} className="center-me justify-content-center">
              <Col>
                <p>Welcome! Please enter the password to view the {name}.</p>
              </Col>
            </Row>
            <Row noGutters={true} className="center-me justify-content-center">
              <Col>
                <div className="section">
                  <label htmlFor="password" className="label">
                    Password
                  </label>
                  <Input
                    className={inputClass}
                    type="password"
                    id="password"
                    onKeyPress={(event) => this.onKeyPress(event)}
                    onChange={(event) => this.setPassword(event)}
                  />
                </div>
              </Col>
            </Row>
            {hint && <p className="lockscreen-text hint">Hint: {hint}</p>}
            <div>
              <Button onClick={() => this.props.submitEventPassword(this.state.password)} type="primary">
                {label}
              </Button>
              {!!error && <p className="text-danger">{error}</p>}
            </div>
          </Container>
        </ModalBody>
      </Modal>
    );
  }
}

export default EventPasswordModal;
