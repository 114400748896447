import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Item } from 'react-stately';
import { listCountries } from '../../../actions/countries';
import { Spinner } from '../atoms';
import { SelectField } from '../molecules';

const CountrySelect = (props) => {
  const { onSelect = () => {} } = props;

  const dispatch = useDispatch();

  // List of countries
  const countries = useSelector((store) => store.countries);

  // Fetch list of countries on mount
  useEffect(() => {
    dispatch(listCountries());
  }, []);

  const handleSelect = (code) => {
    const country = countries.data.find((country) => country.code === code);
    if (!country) {
      return false;
    }
    onSelect(country);
  };

  if (countries.loading) {
    return <Spinner size={18} />;
  }

  return (
    <SelectField
      aria-label="Country"
      name="country"
      onChange={handleSelect}
      initialValue={countries.data[0]?.code}
      isTransparent={true}
    >
      {countries.data.map((country) => (
        <Item key={country.code}>{country.name}</Item>
      ))}
    </SelectField>
  );
};

export default CountrySelect;
