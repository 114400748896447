import React from 'react';

function getColor(props) {
  if (props.color) {
    return props.color;
  }
  return '#000000';
}

function svg(props, icon, box) {
  const classNames = ['icon'];
  const viewBox = box || '0 0 48 48';
  if (props.size) {
    classNames.push(`icon--${props.size}`);
  }

  return (
    <svg
      className={classNames.join(' ')}
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox={viewBox}
      fill={props.color}
      style={{ fill: props.color }}
    >
      {icon}
    </svg>
  );
}

export const KebabIcon = (props) => {
  return (
    <svg fill={props.color} className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
      <circle cx="20" cy="3.7" r="3.7" />
      <circle cx="20" cy="36.3" r="3.7" />
      <circle cx="20" cy="20" r="3.7" />
    </svg>
  );
};

export const GearIcon = (props) =>
  svg(
    props,
    <path
      fillRule="evenodd"
      d="M30.451 10.593l1.486.867s4.436-.774 4.693-.798c1.343-.165 2.788.556 3.463 1.726l1.862 3.224c.678 1.175.58 2.793-.204 3.817-.182.256-3.065 3.71-3.065 3.71s.018.255.018.861c0 .292-.025.873-.025.873s2.89 3.442 3.04 3.653c.813 1.08.911 2.692.236 3.862l-1.862 3.224c-.678 1.175-2.128 1.9-3.42 1.73-.01 0-4.76-.804-4.76-.804l-1.462.862s-1.551 4.23-1.66 4.464C28.263 43.11 26.916 44 25.566 44h-3.724c-1.356 0-2.71-.894-3.204-2.085-.13-.286-1.686-4.53-1.686-4.53l-1.49-.85s-4.428.78-4.685.803c-1.342.165-2.788-.556-3.463-1.726l-1.862-3.224c-.678-1.175-.58-2.793.204-3.817.183-.257 3.069-3.713 3.069-3.713s-.021-.252-.021-.858c0-.286.024-.856.024-.856s-2.89-3.458-3.039-3.67c-.813-1.08-.912-2.692-.237-3.862l1.862-3.224c.678-1.175 2.128-1.9 3.407-1.732.313.03 4.784.808 4.784.808l1.468-.873s1.535-4.22 1.643-4.455C19.144 4.891 20.492 4 21.842 4h3.724c1.356 0 2.708.893 3.203 2.085.13.286 1.682 4.508 1.682 4.508zm-3.298 2.916l-.268-.899c-.391-1.307-1.498-4.27-1.616-4.547-.069-.16-3.062-.16-3.13 0-.118.278-1.225 3.24-1.616 4.547l-.268.899-.863.368c-.816.348-1.586.793-2.294 1.326l-.75.565-.915-.217c-1.327-.315-4.449-.838-4.748-.875-.174-.02-1.67 2.571-1.565 2.711.181.24 2.194 2.682 3.131 3.674l.643.681-.111.93a11.133 11.133 0 000 2.655l.111.93-.643.682c-.937.992-2.95 3.433-3.131 3.674-.105.14 1.391 2.732 1.565 2.71.3-.036 3.42-.559 4.748-.874l.914-.217.75.565c.71.533 1.48.978 2.295 1.326l.863.368.268.9c.39 1.306 1.498 4.268 1.616 4.546.068.16 3.061.16 3.13 0 .118-.278 1.225-3.24 1.616-4.547l.268-.899.863-.368a10.986 10.986 0 002.294-1.327l.75-.564.914.217c1.328.315 4.45.838 4.749.874.102.013 1.67-2.57 1.565-2.71-.182-.241-2.195-2.683-3.132-3.675l-.643-.68.112-.931a11.133 11.133 0 000-2.654l-.112-.93.643-.681c.937-.992 2.95-3.434 3.132-3.675.105-.14-1.392-2.732-1.565-2.71-.3.036-3.42.559-4.749.874l-.914.217-.75-.564a10.986 10.986 0 00-2.294-1.327l-.863-.368zM23.703 30a6 6 0 110-12 6 6 0 010 12zm0-4a2 2 0 100-4 2 2 0 000 4z"
    ></path>
  );

export const SettingsIcon = (props) => {
  return (
    <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
      <path
        fill="#000"
        fillRule="nonzero"
        d="M26.194 27.135a10 10 0 0010.557-2.305c1.535-1.536 2.413-3.402 2.633-5.446-.887.887-3.336 3.32-3.336 3.32-.865.865-2.303 1.301-3.501 1.062l-5.105-1.021c-1.334-.267-2.482-1.416-2.749-2.749l-1.02-5.105c-.24-1.2.196-2.635 1.062-3.501l3.325-3.325c-.693.097-2.976.147-5.452 2.622a10 10 0 00-2.305 10.557 2 2 0 01-.46 2.11l-9.96 9.958a2.996 2.996 0 00-.003 4.246 3.006 3.006 0 004.246-.004l9.959-9.958a2 2 0 012.11-.461zM42.91 14.43c1.304 4.885.197 9.701-3.33 13.228a13.997 13.997 0 01-13.523 3.626l-9.101 9.099a7.006 7.006 0 01-9.903.003 6.996 6.996 0 01.003-9.903l9.092-9.092c-1.357-3.698-.274-9.625 3.633-13.532 3.527-3.527 8.343-4.634 13.228-3.33 1.488.397 1.987 2.257.898 3.346l-6.343 6.343.98 4.677 4.677.98 6.343-6.343c1.089-1.089 2.949-.59 3.346.898z"
      ></path>
    </svg>
  );
};

export const ShareIcon = (props) =>
  svg(
    props,
    <path d="M16,24 C16,24.3975663 15.9613327,24.7861014 15.8875538,25.1620497 L33.8175133,33.6980412 C34.8982164,32.6471664 36.3735313,32 38,32 C41.3137085,32 44,34.6862915 44,38 C44,41.3137085 41.3137085,44 38,44 C34.6862915,44 32,41.3137085 32,38 C32,37.7574051 32.0143975,37.518173 32.0423847,37.2831115 L13.8346671,28.6148865 C12.7949031,29.4798143 11.4581995,30 10,30 C6.6862915,30 4,27.3137085 4,24 C4,20.6862915 6.6862915,18 10,18 C11.459072,18 12.7965027,18.5208084 13.8365333,19.3866664 L32.0426602,10.7191988 C32.014492,10.4833945 32,10.2433897 32,10 C32,6.6862915 34.6862915,4 38,4 C41.3137085,4 44,6.6862915 44,10 C44,13.3137085 41.3137085,16 38,16 C36.3743769,16 34.8997505,15.3535064 33.8191993,14.3035976 L15.8879915,22.8401833 C15.9614857,23.215437 16,23.6032208 16,24 Z M10,26 C11.1045695,26 12,25.1045695 12,24 C12,22.8954305 11.1045695,22 10,22 C8.8954305,22 8,22.8954305 8,24 C8,25.1045695 8.8954305,26 10,26 Z M38,12 C39.1045695,12 40,11.1045695 40,10 C40,8.8954305 39.1045695,8 38,8 C36.8954305,8 36,8.8954305 36,10 C36,11.1045695 36.8954305,12 38,12 Z M38,40 C39.1045695,40 40,39.1045695 40,38 C40,36.8954305 39.1045695,36 38,36 C36.8954305,36 36,36.8954305 36,38 C36,39.1045695 36.8954305,40 38,40 Z" />
  );

export const CloseIcon = (props) => {
  return (
    <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
      <path
        fill={getColor(props)}
        fillRule="nonzero"
        stroke="none"
        strokeWidth="1"
        d="M39.414 11.414a2 2 0 10-2.828-2.828L24 21.17 11.414 8.586a2 2 0 00-2.828 2.828L21.172 24 8.586 36.585a2 2 0 002.828 2.828L24 26.828l12.586 12.585a2 2 0 002.828-2.828L26.828 24l12.586-12.586z"
      ></path>
    </svg>
  );
};

export const CancelIcon = (props) => {
  return (
    <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
      <path
        fill="#000"
        fillRule="evenodd"
        d="M33.811 11.36A15.93 15.93 0 0024 8C15.163 8 8 15.163 8 24a15.93 15.93 0 003.36 9.811l22.451-22.45zm2.829 2.829A15.93 15.93 0 0140 24c0 8.837-7.163 16-16 16a15.93 15.93 0 01-9.811-3.36l22.45-22.451zM44 24c0-11.046-8.954-20-20-20S4 12.954 4 24s8.954 20 20 20 20-8.954 20-20z"
      ></path>
    </svg>
  );
};

export const FilterIcon = (props) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path
          d="M21.2173005,24.6543889 C21.6495735,24.998524 22,25.7220683 22,26.2785911 L22,37.7686799 L26,35.6036983 L26,26.2676199 C26,25.7207921 26.3460687,25.0019928 26.7826982,24.6543889 L35.1413564,18 L12.8586274,18 L21.2173005,24.6543889 Z M39.999976,14 L40,9.99582624 L8.00002553,10 L8.00005685,14 L39.999976,14 Z M30,36.0054385 C30,37.1070044 29.2126465,38.4252898 28.2422456,38.9494527 L21.519314,42.5808507 C19.5756505,43.6307224 18,42.6998281 18,40.4857202 L18,27.2 L5.56936646,17.2554932 C4.7026293,16.5621034 4,15.0980496 4,13.9914698 L4,9.99582624 C4,7.78899235 5.79642476,6 8.00002553,6 L39.9999745,6 C42.2091276,6 44,7.79652125 44,9.99582624 L44,13.9914698 C44,15.1007504 43.2999164,16.5600668 42.4306335,17.2554932 L30,27.2 L30,36.0054385 Z"
          fill={getColor(props)}
        />
      </g>
    </svg>
  );
};

export const FacebookIcon = (props) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path
          d="M38.0098214,40 C39.1096697,40 40,39.1094819 40,38.0098214 L40,9.99017859 C40,8.89033027 39.1094819,8 38.0098214,8 L9.99017859,8 C8.89033027,8 8,8.89051807 8,9.99017859 L8,38.0098214 C8,39.1096697 8.89051807,40 9.99017859,40 L24,40 L24,28 L21.9990234,28 C20.8944539,28 19.9990234,27.1045695 19.9990234,26 C19.9990234,24.8954305 20.8944539,24 21.9990234,24 L24,24 C24,20.2063774 24.4283294,17.8154552 26.2077389,15.8238905 C27.9672489,13.8545978 30.0233859,13 32,13 C33.1045695,13 34,13.8954305 34,15 C34,16.1045695 33.1045695,17 32,17 C31.1257632,17 30.1636487,17.3998862 29.1905766,18.4889757 C28.3049571,19.4801857 28,21.1824474 28,24 L32,24 C33.1045695,24 34,24.8954305 34,26 C34,27.1045695 33.1045695,28 32,28 L28,28 L28,40 L38.0098214,40 Z M4,38.0098214 L4,9.99017859 C4,6.6815679 6.68100239,4 9.99017859,4 L38.0098214,4 C41.3184321,4 44,6.68100239 44,9.99017859 L44,38.0098214 C44,41.3184321 41.3189976,44 38.0098214,44 L9.99017859,44 C6.6815679,44 4,41.3189976 4,38.0098214 Z"
          fill={getColor(props)}
        />
      </g>
    </svg>
  );
};

export const TwitterIcon = (props) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path
          d="M15.5996288,31.3432038 C16.8870507,32.2134911 16.7335156,34.1553489 15.3253248,34.8125046 C14.7977985,35.0586835 10.108277,36.3251505 10.108277,36.3251505 C11.9766352,37.4256014 14.5953651,38.0001383 18.0191046,38.0001383 C29.0043694,38.0001383 37.0201898,29.9312721 37.0283824,17.8547386 C37.0285239,17.6461855 37.0191046,17.0567137 37.0191046,17.0001383 C37.0191046,12.8414602 34.8379585,9.98027215 31.029523,10.0001112 C27.1939345,10.0200917 25.0191046,12.8607128 25.0191046,17.0001383 C25.0191046,17.2404549 25.012757,18.733159 25.012757,18.9758463 C25.012757,20.1753796 23.9641604,21.1050978 22.7732587,20.9614547 C17.7570764,20.3564175 13.202745,18.1545629 9.68865351,14.7430314 C9.68865351,14.7430314 9.22663279,16.4849939 9.1137381,17.2855811 C9.179079,17.363209 10.4193389,18.6182621 11.126547,19.1232422 C11.6692127,19.5107312 12.256792,19.8620165 12.8900669,20.1683705 C13.884398,20.6493897 14.3005195,21.8453974 13.8195003,22.8397285 C13.3384812,23.8340596 12.1424735,24.2501812 11.1481424,23.769162 C10.5192663,23.4649359 9.92366431,23.1269675 9.36096131,22.7600295 C10.1527358,25.9935044 12.1521133,29.0127099 15.5996288,31.3432038 Z M41.9042941,10.0204785 C43.9205247,9.91223189 44.6291575,11.1666649 43.4659997,12.8234642 L41.0043542,16.3298268 C41.0141699,16.5514014 41.0285625,17.5906841 41.0283815,17.8574521 C41.0186954,32.1355788 31.2189869,42.0001383 18.0191046,42.0001383 C11.5004081,42.0001383 7.28892975,40.2971186 4.38666092,36.323278 C3.52084081,35.1377809 4.1846894,33.4545324 5.62664404,33.179188 C5.69278669,33.1665579 10.3089325,32.1688785 10.3089325,32.1688785 C4.2128302,26.004777 3.65075128,17.2483003 7.25708861,10.1082236 C7.92251028,8.7907756 9.73687421,8.62327056 10.6322507,9.79662308 C13.2247124,13.1939333 16.8673714,15.577306 21.0311488,16.588527 C21.2299186,10.5851282 24.8882057,6.03204851 31.0086863,6.00016547 C34.6592887,5.98114861 37.438396,7.60264274 39.131368,10.1693504 L41.9042941,10.0204785 Z"
          fill={getColor(props)}
        />
      </g>
    </svg>
  );
};

export const TumblrIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
      <path
        fill="#000"
        fillRule="evenodd"
        d="M4 38.01V9.99A5.99 5.99 0 019.99 4h28.02A5.99 5.99 0 0144 9.99v28.02A5.99 5.99 0 0138.01 44H9.99A5.99 5.99 0 014 38.01zM9.99 8C8.89 8 8 8.89 8 9.99v28.02c0 1.1.89 1.99 1.99 1.99h28.02c1.1 0 1.99-.89 1.99-1.99V9.99C40 8.89 39.11 8 38.01 8H9.99zM25 29.508c0 .822.672 1.492 1.5 1.492.4 0 .77-.155 1.051-.43a2 2 0 112.798 2.86A5.493 5.493 0 0126.5 35c-3.036 0-5.5-2.46-5.5-5.492V23h-1.994A1.998 1.998 0 0117 21v-1c0-.552.445-1 .99-1h.045s1.085.1 2.058-.817c.973-.916.907-2.135.907-2.135V15a2 2 0 114 0v4h3.994c1.108 0 2.006.888 2.006 2 0 1.105-.897 2-2.006 2H25v6.508z"
      ></path>
    </svg>
  );
};

export const PinterestIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
      <path
        fill="#000"
        fillRule="nonzero"
        d="M27.399 34.02c4.549 0 8.292-2.491 10.268-5.857 1.603-2.732 2.309-5.545 2.309-9.163 0-9.337-6.624-15-16-15C14.999 4 8 10.407 8 19.122c-.01.517.04 1.243.2 2.103.249 1.342.713 2.68 1.449 3.94a11.074 11.074 0 003.166 3.464 2 2 0 002.32-3.258 7.081 7.081 0 01-2.032-2.223C12.26 21.702 11.98 20.2 12 19.185 12.001 12.699 17.133 8 23.976 8c7.294 0 12 4.023 12 11 0 2.931-.53 5.045-1.76 7.139A7.902 7.902 0 0127.4 30.02c-2.828 0-4.19-1.213-5.051-3.734-.013-.037 2.559-9.766 2.559-9.766.287-1.067-.619-2.727-1.685-3.014-1.067-.287-3.219.197-3.98 3.211-.72 2.847.35 4.156-.375 6.853l-4.822 17.91a2 2 0 103.862 1.04l2.956-10.981c1.54 1.59 3.672 2.481 6.536 2.481z"
      ></path>
    </svg>
  );
};

export const RedditIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
      <path
        fill="#000"
        fillRule="evenodd"
        d="M32.498 9.385c-2.51 1.035-4.426 2.578-5.62 4.75 5.127.483 9.73 2.242 13.135 4.855a5 5 0 115.665 8.233c.211.9.322 1.827.322 2.777 0 9.02-9.97 16-22 16S2 39.02 2 30c0-.95.11-1.877.323-2.777a5 5 0 115.665-8.233c3.713-2.85 8.854-4.684 14.544-4.955 1.5-3.984 4.482-6.716 8.442-8.348 1.741-.717 3.578-1.182 5.432-1.445a4 4 0 11.282 4.002c-1.447.224-2.868.597-4.19 1.141zM6 30c0 6.444 7.938 12 18 12 10.062 0 18-5.556 18-12s-7.938-12-18-12C13.938 18 6 23.556 6 30zm11.431 6.882c-1.19-.435-2.048-.886-2.548-1.223a2 2 0 112.234-3.318c.035.023 1.174.597 1.688.785 1.504.55 3.244.882 5.195.882s3.691-.332 5.195-.882c.514-.188 1.653-.762 1.688-.785a2 2 0 112.234 3.318c-.5.337-1.358.788-2.548 1.223-1.928.706-4.13 1.126-6.569 1.126-2.44 0-4.64-.42-6.569-1.126zM18 30a3 3 0 110-6 3 3 0 010 6zm12 0a3 3 0 110-6 3 3 0 010 6z"
      ></path>
    </svg>
  );
};

export const InstagramIcon = (props) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path
          d="M13.9905068,4 L34.0094932,4 C39.5270978,4 44,8.47207592 44,13.9905068 L44,34.0094932 C44,39.5270978 39.5279241,44 34.0094932,44 L13.9905068,44 C8.47290224,44 4,39.5279241 4,34.0094932 L4,13.9905068 C4,8.47290224 8.47207592,4 13.9905068,4 Z M8,34.0094932 C8,37.3184485 10.6817047,40 13.9905068,40 L34.0094932,40 C37.3184485,40 40,37.3182953 40,34.0094932 L40,13.9905068 C40,10.6815515 37.3182953,8 34.0094932,8 L13.9905068,8 C10.6815515,8 8,10.6817047 8,13.9905068 L8,34.0094932 Z M14,24 C14,18.4771525 18.4771525,14 24,14 C29.5228475,14 34,18.4771525 34,24 C34,29.5228475 29.5228475,34 24,34 C18.4771525,34 14,29.5228475 14,24 Z M30,24 C30,20.6862915 27.3137085,18 24,18 C20.6862915,18 18,20.6862915 18,24 C18,27.3137085 20.6862915,30 24,30 C27.3137085,30 30,27.3137085 30,24 Z M34,16 C32.8954305,16 32,15.1045695 32,14 C32,12.8954305 32.8954305,12 34,12 C35.1045695,12 36,12.8954305 36,14 C36,15.1045695 35.1045695,16 34,16 Z"
          fill={getColor(props)}
        />
      </g>
    </svg>
  );
};

export const SlideshowIcon = (props) => {
  return (
    <svg width="48px" height="48px" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M39.9999745,7 C42.2091276,7 44,8.79219474 44,11.0018785 L44,32.0000059 C45.1050248,32.0026326 46,32.8893786 46,34 C46,35.1045695 45.1011449,36 43.9950767,36 L32.7370342,36 L36.6641006,41.8905996 C37.2768055,42.809657 37.0284578,44.0513957 36.1094004,44.6641006 C35.190343,45.2768055 33.9486043,45.0284578 33.3358994,44.1094004 L27.9296325,36 L20.0703675,36 L14.6641006,44.1094004 C14.0513957,45.0284578 12.809657,45.2768055 11.8905996,44.6641006 C10.9715422,44.0513957 10.7231945,42.809657 11.3358994,41.8905996 L15.2629658,36 L4.00492334,36 C2.89763476,36 2,35.1122704 2,34 C2,32.8970698 2.89618902,32.0026584 4,32.0000059 L4,11.0018785 C4,8.79170204 5.79642476,7 8.00002553,7 L22,7 L22,4.99810135 C22,3.89458045 22.8877296,3 24,3 C25.1045695,3 26,3.88670635 26,4.99810135 L26,7 L39.9999745,7 Z M40,32 L40,11.0018785 L8.00002553,11 L8,32 L18.9726552,32 C18.9920982,31.9997168 28.9689799,32 28.9689799,32 C28.9884447,31.9997164 40,32 40,32 Z M33,21 C31.3431458,21 30,19.6568542 30,18 C30,16.3431458 31.3431458,15 33,15 C34.6568542,15 36,16.3431458 36,18 C36,19.6568542 34.6568542,21 33,21 Z M16.8859326,17.8275613 C17.420651,16.8551044 18.4246034,16.767864 19.1217062,17.6245725 L22.1116922,21.2991334 C22.8117574,22.1594826 23.9398758,22.1547557 24.6259902,21.2953705 L25.1130234,20.6853422 C25.8015666,19.8229148 26.9384255,19.8055428 27.6549128,20.649656 L32.5987488,26.4741233 C33.3140524,27.3168417 33.0079172,28 31.8881934,28 L13.2982075,28 C12.1904751,28 11.7230084,27.2170276 12.2606739,26.2392112 L16.8859326,17.8275613 Z"
          fill={getColor(props)}
        />
      </g>
    </svg>
  );
};

export const PlayIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 25 40.3"
      version="1.1"
      viewBox="0 0 25 40.3"
      xmlSpace="preserve"
    >
      <path
        fill={getColor(props)}
        d="M4.1 4L21 20.9 4 36.3 4.1 4m0-4C3.5 0 3 .1 2.5.3 1 .9.1 2.4.1 4L0 36.2c0 1.6.9 3 2.4 3.7.5.2 1.1.3 1.6.3 1 0 1.9-.4 2.7-1l17-15.3c.8-.7 1.3-1.8 1.3-2.9 0-1.1-.4-2.2-1.2-2.9L6.9 1.2C6.1.4 5.1 0 4.1 0z"
      />
    </svg>
  );
};

export const ViewIcon = (props) => {
  return (
    <svg width="48px" height="48px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          fill={getColor(props)}
          d="M39.83549,34.0044589 C35.4943607,37.7410675 29.9925391,40 24,40 C18.0082591,40 12.5069355,37.7375757 8.16385557,33.9960218 C4.48803142,30.8293072 2,26.8274787 2,24 C2,21.1720273 4.48411314,17.1700245 8.15610513,14.0044245 C12.4969785,10.2621861 17.99976,8 24,8 C30.0010391,8 35.5043145,10.2586929 39.8432405,13.9959871 C43.5175192,17.1607933 46,21.1627946 46,24 C46,26.8367098 43.5136018,30.8385397 39.83549,34.0044589 Z M10.774621,30.9655261 C14.425061,34.1103722 19.0198904,36 24,36 C28.9820968,36 33.5776597,34.1131583 37.2260335,30.9728361 C40.1080046,28.4921916 42,25.4470464 42,24 C42,22.5508402 40.1113808,19.5062017 37.2327482,17.026718 C33.5869811,13.8864699 28.9906222,12 24,12 C19.0113652,12 14.4157372,13.8892565 10.7679061,17.0340277 C7.89079843,19.5143632 6,22.560513 6,24 C6,25.4373739 7.89417932,28.4840318 10.774621,30.9655261 Z M32,24 C32,28.418278 28.418278,32 24,32 C19.581722,32 16,28.418278 16,24 C16,19.581722 19.581722,16 24,16 C28.418278,16 32,19.581722 32,24 Z M24,20 C21.790861,20 20,21.790861 20,24 C20,26.209139 21.790861,28 24,28 C26.209139,28 28,26.209139 28,24 L27,24 C25.3431458,24 24,22.6568542 24,21 L24,20 Z"
          id="Combined-Shape"
        ></path>
      </g>
    </svg>
  );
};

export const SearchIcon = (props) => {
  return (
    <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
      <path
        fill={getColor(props)}
        fillRule="evenodd"
        stroke="none"
        strokeWidth="1"
        d="M42.784 39.956a1.99 1.99 0 01-.006 2.822 1.994 1.994 0 01-2.822.006L29.098 31.927A14.934 14.934 0 0120 35c-8.284 0-15-6.716-15-15 0-8.284 6.716-15 15-15 8.284 0 15 6.716 15 15 0 3.421-1.145 6.575-3.073 9.098l10.857 10.858zM20 31c6.075 0 11-4.925 11-11S26.075 9 20 9 9 13.925 9 20s4.925 11 11 11z"
      ></path>
    </svg>
  );
};

export const GalleryIcon = (props) => {
  const classNames = ['icon'];
  if (props.size) {
    classNames.push(`icon--${props.size}`);
  }

  return (
    <svg className={classNames.join(' ')} xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
      <path
        fill="#000"
        fillRule="evenodd"
        d="M2 9.99A3.997 3.997 0 016.002 6h35.996A3.995 3.995 0 0146 9.99v28.02A3.997 3.997 0 0141.998 42H6.002A3.995 3.995 0 012 38.01V9.99zm4 28.02L41.998 38C42.001 38 42 9.99 42 9.99L6.002 10C5.999 10 6 38.01 6 38.01zm10.055-19.204c.494-.99 1.375-1.035 1.96-.112l3.398 5.359a2.019 2.019 0 002.77.622l1.979-1.23a2.157 2.157 0 012.867.571l4.528 6.353c.642.9.264 1.631-.84 1.631H11.484c-1.106 0-1.6-.806-1.108-1.793l5.68-11.401zM35 22a3 3 0 110-6 3 3 0 010 6z"
      ></path>
    </svg>
  );
};

export const GalleryFolderIcon = (props) => {
  const classNames = ['icon'];
  if (props.size) {
    classNames.push(`icon--${props.size}`);
  }

  return (
    <svg
      className={classNames.join(' ')}
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new -281 373 48 48"
      version="1.1"
      viewBox="-281 373 48 48"
      xmlSpace="preserve"
    >
      <path d="M-279 383c0-2.2 1.8-4 4-4h12.1c1.1 0 2.5.7 3.1 1.7l2.8 4.3h18c2.2 0 4 1.8 4 4v22c0 2.2-1.8 4-4 4h-36c-2.2 0-4-1.8-4-4v-28zm4 28h36v-22h-18c-1.3 0-2.6-.7-3.3-1.8l-2.7-4.2h-12v28zm11.9-14.1c.5-1 1.4-1.1 2-.3l2.9 3.7c.7.9 1.9 1.1 2.8.4l.6-.4c.9-.7 2.1-.4 2.7.5l2.8 4.4c.6.9.2 1.7-.9 1.7h-15.4c-1.1 0-1.6-.8-1.2-1.8l3.7-8.2zm13 2.1c-1.7 0-3-1.3-3-3s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3z"></path>
    </svg>
  );
};

export const EditIcon = (props) => {
  return (
    <svg
      className="icon"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new -268.7 377 31.4 31.4"
      version="1.1"
      viewBox="-268.7 377 31.4 31.4"
      xmlSpace="preserve"
    >
      <path d="M-241.3 378.2l2.8 2.8c1.6 1.6 1.6 4.1 0 5.7l-19.8 19.8-7.4 1.9c-2.1.5-3.4-.8-2.9-2.9l1.9-7.4 19.8-19.8c1.5-1.7 4.1-1.7 5.6-.1zm-17.6 23.3l-2.8-2.8-1.3 1.3-.9 3.8 3.7-.9 1.3-1.4zm13.4-13.4l-2.8-2.8-10.6 10.6 2.8 2.8 10.6-10.6z"></path>
    </svg>
  );
};

export const EditIcon2 = (props) => {
  return (
    <svg
      className="icon icon--small"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new -268.7 377 31.4 31.4"
      version="1.1"
      viewBox="-268.7 377 31.4 31.4"
      xmlSpace="preserve"
    >
      <path d="M-241.3 378.2l2.8 2.8c1.6 1.6 1.6 4.1 0 5.7l-19.8 19.8-7.4 1.9c-2.1.5-3.4-.8-2.9-2.9l1.9-7.4 19.8-19.8c1.5-1.7 4.1-1.7 5.6-.1zm-17.6 23.3l-2.8-2.8-1.3 1.3-.9 3.8 3.7-.9 1.3-1.4zm13.4-13.4l-2.8-2.8-10.6 10.6 2.8 2.8 10.6-10.6z"></path>
    </svg>
  );
};

export const SpinnerIcon = (props) => {
  return (
    <svg
      className="icon"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new -268.6 377.1 31.2 31.2"
      version="1.1"
      viewBox="-268.6 377.1 31.2 31.2"
      xmlSpace="preserve"
    >
      <path d="M-253 408.3c-4.2 0-8.1-1.6-11-4.6-2.9-2.9-4.6-6.9-4.6-11s1.6-8.1 4.6-11c2.9-2.9 6.9-4.6 11-4.6v4c-3.1 0-6 1.2-8.2 3.4-2.2 2.2-3.4 5.1-3.4 8.2 0 3.1 1.2 6 3.4 8.2 2.2 2.2 5.1 3.4 8.2 3.4 3.1 0 6-1.2 8.2-3.4s3.4-5.1 3.4-8.2-1.2-6-3.4-8.2l2.8-2.8c2.9 2.9 4.6 6.9 4.6 11 0 4.2-1.6 8.1-4.6 11-2.9 3-6.8 4.6-11 4.6z"></path>
    </svg>
  );
};

export const CheckIcon = (props) => {
  return (
    <svg
      className="icon"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new -268.6 377.1 31.2 31.2"
      version="1.1"
      viewBox="-268.6 377.1 31.2 31.2"
      xmlSpace="preserve"
      style={{ fill: props.color || null }}
    >
      <path d="M-258.2 404.2l-9.2-9.2c-.8-.8-.8-2 0-2.8.8-.8 2-.8 2.8 0l6.4 6.4 17.3-17.3c.8-.8 2-.8 2.8 0 .8.8.8 2 0 2.8l-20.1 20.1z"></path>
    </svg>
  );
};

export const DownArrowIcon = (props) => {
  return (
    <svg
      className="icon"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new -289 381.8 31.2 31.2"
      version="1.1"
      viewBox="-289 381.8 31.2 31.2"
      xmlSpace="preserve"
    >
      <path d="M-273.3 407l-14.8-14.8c-.8-.8-.8-2 0-2.8.8-.8 2-.8 2.8 0l12 12 12.1-12.1c.8-.8 2-.8 2.8 0 .8.8.8 2 0 2.8l-14.9 14.9z"></path>
    </svg>
  );
};

export const RightArrowIcon = (props) => {
  return svg(
    props,
    <path
      d="M38.63,26.44H2.91c-1.37,0-2.47-1.16-2.47-2.62c0-1.45,1.1-2.62,2.47-2.62h35.72l-6-6
		c-1.02-1.02-1.02-2.68,0-3.7c1.02-1.02,2.68-1.02,3.7,0L46.8,21.97c1.02,1.02,1.02,2.68,0,3.7L36.33,36.15
		c-1.02,1.02-2.68,1.02-3.7,0c-1.02-1.02-1.02-2.68,0-3.7L38.63,26.44z"
    />
  );
};

export const DeviceIcon = (props) => {
  return (
    <svg
      className="icon"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new -281 373 48 48"
      version="1.1"
      viewBox="-281 373 48 48"
      xmlSpace="preserve"
    >
      <path d="M-243.1 377v40H-271v-40h27.9m.8-4h-29.5c-1.8 0-3.2 1.4-3.2 3.2v41.6c0 1.8 1.4 3.2 3.2 3.2h29.5c1.8 0 3.2-1.4 3.2-3.2v-41.6c0-1.8-1.4-3.2-3.2-3.2z"></path>
      <circle cx="-257" cy="412.5" r="2.3"></circle>
    </svg>
  );
};

export const SupportIcon = () => (
  <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
    <path
      fill="#000"
      fillRule="evenodd"
      stroke="none"
      strokeWidth="1"
      d="M37.861 31.997A15.926 15.926 0 0040 24c0-2.836-.738-5.5-2.033-7.81l-3.743 3.743c.5 1.258.776 2.63.776 4.067 0 1.516-.307 2.96-.861 4.275l3.722 3.722zM24 35a10.97 10.97 0 01-4.067-.776l-3.744 3.743A15.927 15.927 0 0024 40c3.021 0 5.847-.837 8.258-2.293l-3.696-3.695A10.96 10.96 0 0124 35zM13 24c0-1.628.353-3.173.988-4.562l-3.695-3.696A15.925 15.925 0 008 24c0 3.097.88 5.99 2.404 8.44l3.677-3.678A10.956 10.956 0 0113 24zm11-11c1.706 0 3.321.388 4.762 1.081l3.677-3.677A15.925 15.925 0 0024 8c-2.913 0-5.644.779-7.997 2.139l3.722 3.722A10.966 10.966 0 0124 13zm20 11c0 11.046-8.954 20-20 20S4 35.046 4 24 12.954 4 24 4s20 8.954 20 20zm-27 0a7 7 0 1014 0 7 7 0 00-14 0z"
    ></path>
  </svg>
);

export const BoomerangIcon = () => (
  <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
    <path
      fill="#000"
      fillRule="evenodd"
      stroke="none"
      strokeWidth="1"
      d="M16.506 9.414a2 2 0 012.828-2.828l6 6a2 2 0 010 2.828l-6 6a2 2 0 01-2.828-2.828L19.079 16h-2.082C12.032 16 8 20.032 8 25c0 4.664 4.186 9 7.995 9a2 2 0 110 4C9.945 38 4 31.842 4 25c0-7.177 5.823-13 12.997-13h2.082l-2.573-2.586zm14.828 31.172a2 2 0 11-2.828 2.828l-6-6a2 2 0 010-2.828l6-6a2 2 0 112.828 2.828L28.761 34h2.162c4.964 0 8.997-4.032 8.997-9 0-4.664-4.187-9-7.995-9a2 2 0 110-4c6.05 0 11.995 6.158 11.995 13 0 7.177-5.824 13-12.997 13h-2.162l2.573 2.586z"
    ></path>
  </svg>
);

export const GifIcon = () => (
  <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
    <path
      fill="#000"
      fillRule="evenodd"
      stroke="none"
      strokeWidth="1"
      d="M4.894 6.214l4 2a2 2 0 11-1.788 3.577L6 11.248V36.74l1.106-.526a2 2 0 111.788 3.577l-4 2A2 2 0 012 40.003v-32a2 2 0 012.894-1.79zm10 0l4 2a2 2 0 11-1.788 3.577L16 11.248V36.74l1.106-.526a2 2 0 011.788 3.577l-4 2A2 2 0 0112 40.003v-32a2 2 0 012.894-1.79zM22 40.003v-32a2 2 0 012.392-1.962l20 4A2 2 0 0146 12.003v24a2 2 0 01-1.608 1.96l-20 4A2 2 0 0122 40.004zm20-26.36L26 10.46v27.133l16-3.234V13.643z"
    ></path>
  </svg>
);

export const PhotoIcon = (props) =>
  svg(
    props,
    <path
      fill="#000"
      fillRule="evenodd"
      stroke="none"
      strokeWidth="1"
      d="M24 19a6 6 0 105.736 4.233 3 3 0 11-3.97-3.969A5.999 5.999 0 0024 19zm-12.4-9l1.657-4.143C13.667 4.832 14.898 4 15.991 4h16.018c1.1 0 2.325.835 2.734 1.857L36.4 10h5.598A4 4 0 0146 14.004v23.992A4.004 4.004 0 0141.998 42H6.002A4 4 0 012 37.996V14.004A4.004 4.004 0 016.002 10H11.6zm-5.598 4L6 37.996 41.998 38 42 14.004 34.694 14c-.553 0-1.168-.412-1.374-.924L31.28 8H16.707l-2.025 5.062c-.207.518-.821.938-1.377.938H6.002zM14 25c0-5.523 4.477-10 10-10s10 4.477 10 10-4.477 10-10 10-10-4.477-10-10z"
    ></path>
  );

export const VideoIcon = () => (
  <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
    <path
      fill="#000"
      fillRule="evenodd"
      stroke="none"
      strokeWidth="1"
      d="M32 17.09l6.191-4.502c.447-.325 1.247-.588 1.812-.588h3.991c1.108 0 2.006.897 2.006 2.005v19.99A1.998 1.998 0 0143.994 36h-3.991c-.554 0-1.369-.266-1.812-.588L32 30.909v5.087A4 4 0 0128 40H6a4 4 0 01-4-4.004V12.004A4 4 0 016 8h22a4 4 0 014 4.004v5.087zM6 12v24h22V12H6zm4 18c0-1.105.897-2 2.006-2h9.988c1.108 0 2.006.888 2.006 2 0 1.105-.897 2-2.006 2h-9.988A1.998 1.998 0 0110 30zm22.024-4.02l8.29 6.02L42 31.998v-16l-1.692-.003-8.283 6.024v3.962z"
    ></path>
  </svg>
);

export const PlusIcon = () => (
  <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
    <path
      fillRule="evenodd"
      stroke="none"
      strokeWidth="1"
      d="M26 8c0-1.11-.895-2-2-2-1.112 0-2 .895-2 2v14H8c-1.11 0-2 .895-2 2 0 1.112.895 2 2 2h14v14c0 1.11.895 2 2 2 1.112 0 2-.895 2-2V26h14c1.11 0 2-.895 2-2 0-1.112-.895-2-2-2H26V8z"
    ></path>
  </svg>
);

export const MinusIcon = () => (
  <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
    <rect width="36" height="4" x="6" y="22" fillRule="evenodd" stroke="none" strokeWidth="1" rx="2"></rect>
  </svg>
);

export const TrashIcon = (props) =>
  svg(
    props,
    <path
      fillRule="nonzero"
      stroke="none"
      strokeWidth="1"
      d="M14 40c0 .003 20.003 0 20.003 0C34 40 34 20.406 34 14H14v26zm24 0c0 2.21-1.794 4-3.997 4H13.997A3.996 3.996 0 0110 40V14c-1.105 0-2-.888-2-2 0-1.105.89-2 2.002-2H20a4 4 0 118 0h9.998c1.106 0 2.002.888 2.002 2 0 1.104-.888 2-2 2v26zM26 19.996c0-1.102.888-1.995 2-1.995 1.105 0 2 .893 2 1.995v14.01A1.993 1.993 0 0128 36c-1.105 0-2-.893-2-1.995v-14.01zm-8 0c0-1.102.888-1.995 2-1.995 1.105 0 2 .893 2 1.995v14.01A1.993 1.993 0 0120 36c-1.105 0-2-.893-2-1.995v-14.01z"
    ></path>
  );

export const FullscreenIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new -281 373 48 48"
      version="1.1"
      viewBox="-281 373 48 48"
      xmlSpace="preserve"
    >
      <path
        fill={getColor(props)}
        d="M-263.6 415.2h-9.8c-1.1 0-2-.9-2-2v-9.8c0-1.1.9-2 2-2s2 .9 2 2v7.8h7.8c1.1 0 2 .9 2 2s-.9 2-2 2zM-273.4 392.2c-1.1 0-2-.9-2-2v-9.8c0-1.1.9-2 2-2h9.8c1.1 0 2 .9 2 2s-.9 2-2 2h-7.8v7.8c0 1.1-.9 2-2 2zM-240.6 392.2c-1.1 0-2-.9-2-2v-7.8h-7.8c-1.1 0-2-.9-2-2s.9-2 2-2h9.8c1.1 0 2 .9 2 2v9.8c0 1.1-.9 2-2 2zM-240.6 415.2h-9.8c-1.1 0-2-.9-2-2s.9-2 2-2h7.8v-7.8c0-1.1.9-2 2-2s2 .9 2 2v9.8c0 1.1-.9 2-2 2z"
      ></path>
    </svg>
  );
};

export const InfoIcon = (props) => {
  return (
    <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
      <path
        fillRule="evenodd"
        stroke="none"
        strokeWidth="1"
        d="M26 31h1c1.105 0 2 .888 2 2 0 1.105-.887 2-2 2h-6c-1.105 0-2-.888-2-2 0-1.105.887-2 2-2h1v-6h-.994A1.998 1.998 0 0119 23c0-1.105.887-2 2.006-2h2.988c.557 0 1.057.223 1.418.585.364.36.588.86.588 1.415v8zm-2 13C12.954 44 4 35.046 4 24S12.954 4 24 4s20 8.954 20 20-8.954 20-20 20zm0-4c8.837 0 16-7.163 16-16S32.837 8 24 8 8 15.163 8 24s7.163 16 16 16zm0-21a3 3 0 110-6 3 3 0 010 6z"
      ></path>
    </svg>
  );
};

export const DirectoryIcon = (props) =>
  svg(
    props,
    <path d="M-244.8 405l-4.6 4.6c-.8.8-.8 2 0 2.8.8.8 2 .8 2.8 0l8-8c.8-.8.8-2 0-2.8l-8-8c-.8-.8-2-.8-2.8 0s-.8 2 0 2.8l4.6 4.6H-264c-4.4 0-8-3.6-8-8v-10c0-1.1-.9-2-2-2s-2 .9-2 2v10c0 6.6 5.4 12 12 12h19.2z"></path>,
    '-281 373 48 48'
  );

export const SurveyIcon = (props) =>
  svg(
    props,
    <path
      fillRule="evenodd"
      stroke="none"
      strokeWidth="1"
      d="M32 2c1.105 0 2 .887 2 1.998L37.998 4A3.997 3.997 0 0142 8v32c0 2.211-1.786 4-4.002 4H10.002A3.997 3.997 0 016 40V8c0-2.211 1.786-4 4.002-4H14c0-1.105.888-2 2-2 1.105 0 2 .887 2 1.998L22 4c0-1.105.888-2 2-2 1.105 0 2 .887 2 1.998L30 4c0-1.105.888-2 2-2zm-6 5.999A1.995 1.995 0 0124 10c-1.105 0-2-.887-2-1.998-1.378-.003-2.73-.003-4-.003A1.995 1.995 0 0116 10c-1.105 0-2-.887-2-1.998C11.585 7.999 10 8 10 8l.002 32H38l-.002-32H34c0 1.105-.888 2-2 2-1.105 0-2-.887-2-1.998-1.27-.003-2.622-.003-4-.003zM24 19c0-1.105.902-2 2.009-2h7.982a2 2 0 110 4H26.01A2 2 0 0124 19zm0 11c0-1.105.902-2 2.009-2h7.982a2 2 0 110 4H26.01A2 2 0 0124 30zm-9.414-9.586l-2-2a2 2 0 012.828-2.828l.586.586 2.586-2.586a2 2 0 012.828 2.828l-4 4a2 2 0 01-2.828 0zm0 11l-2-2a2 2 0 012.828-2.828l.59.59 2.582-2.59a2 2 0 012.828 2.828l-4 4a2 2 0 01-2.828 0z"
    ></path>
  );

export const DownloadIcon = (props) =>
  svg(
    props,
    <path
      fillRule="nonzero"
      stroke="none"
      strokeWidth="1"
      d="M19.771 26.915l2.237 2.238V6a2 2 0 114 0v23.152l2.236-2.238a1.999 1.999 0 012.835-.006 2.007 2.007 0 01-.006 2.836l-5.645 5.647c-.396.395-.907.59-1.419.59a1.974 1.974 0 01-1.416-.584l-5.65-5.653a2 2 0 112.828-2.83zM36 40v-6.003a2 2 0 114 0V40A4.002 4.002 0 0136.009 44H11.99A3.991 3.991 0 018 39.999v-6.003a2 2 0 114 0l-.009 6.002H36z"
    ></path>
  );

export const UploadIcon = (props) =>
  svg(
    props,
    <path
      fillRule="nonzero"
      stroke="none"
      strokeWidth="1"
      d="M19.771 25.078a2 2 0 11-2.828-2.828l5.65-5.651a1.974 1.974 0 011.416-.584c.512 0 1.023.194 1.419.59l5.645 5.645a2 2 0 11-2.828 2.828l-2.237-2.237v19.143a2 2 0 11-4 0V22.84l-2.237 2.237zM37.01 32H32a2 2 0 110-4h5.01c2.75 0 4.99-2.241 4.99-5 0-2.757-2.238-5-4.99-5h-2.868l-.28-1.67C33.06 11.553 28.902 8 24 8s-9.06 3.552-9.862 8.33l-.28 1.67H10.99C8.24 18 6 20.241 6 23c0 2.757 2.238 5 4.99 5H16a2 2 0 110 4h-5.01C6.027 32 2 27.964 2 23c0-4.84 3.828-8.794 8.614-8.992C11.525 9.344 17.137 4 24 4a14.008 14.008 0 0113.44 10.067c4.41 0 8.56 3.969 8.56 8.933 0 4.967-4.03 9-8.99 9z"
    ></path>
  );

export const StatsIcon = (props) =>
  svg(
    props,
    <path d="M33,5.99729162 C33,4.89421792 33.8874333,4 34.999615,4 L41.000385,4 C42.1047419,4 43,4.89153983 43,5.99729162 L43,40 L44.9938589,40 C46.10182,40 47,40.8877296 47,42 C47,43.1045695 46.1017583,44 44.9938589,44 L3.0061411,44 C1.89817996,44 1,43.1122704 1,42 C1,40.8954305 1.89824172,40 3.0061411,40 L5,40 L5,29.9940603 C5,28.8927712 5.88743329,28 6.99961498,28 L13.000385,28 C14.1047419,28 15,28.8945138 15,29.9940603 L15,40 L19,40 L19,15.991617 C19,14.8916773 19.8874333,14 20.999615,14 L27.000385,14 C28.1047419,14 29,14.8978404 29,15.991617 L29,40 L33,40 L33,5.99729162 Z M9,32 L9,40 L11,40 L11,32 L9,32 Z M23,18 L23,40 L25,40 L25,18 L23,18 Z M37,8 L37,40 L39,40 L39,8 L37,8 Z"></path>
  );

export const LinkIcon = (props) =>
  svg(
    props,
    <path d="M30.383 17.24a2 2 0 01.006 2.823L19.063 31.389a1.99 1.99 0 01-2.822-.006 2 2 0 01-.007-2.822l11.327-11.327a1.99 1.99 0 012.822.007zM16.24 39.869a6.006 6.006 0 01-8.486 0 6.005 6.005 0 010-8.485l5.657-5.657a2 2 0 10-2.828-2.828l-5.657 5.656c-3.902 3.902-3.904 10.239 0 14.143 3.9 3.9 10.239 3.903 14.142 0l5.657-5.657a2 2 0 10-2.828-2.829l-5.657 5.657zM41.697 20.07c3.903-3.903 3.9-10.241 0-14.142-3.904-3.904-10.24-3.902-14.143 0l-5.656 5.657a2 2 0 102.828 2.828l5.657-5.657a6.005 6.005 0 018.485 0 6.006 6.006 0 010 8.486l-5.657 5.657a2 2 0 102.829 2.828l5.657-5.657z"></path>
  );

export const HomeIcon = (props) =>
  svg(
    props,
    <path d="M10.002 40l5.998.001V27.995c0-1.102.895-1.995 1.994-1.995h12.012c1.101 0 1.994.893 1.994 1.995v12.01l6 .004V20.832L24 6.845 10 20.833 10.002 40zM5.414 25.414a2 2 0 01-2.828-2.828l20-20a2 2 0 012.828 0l20 20a2 2 0 01-2.828 2.828L42 24.83v15.18A3.992 3.992 0 0137.998 44H10.002A3.995 3.995 0 016 40.009v-15.18l-.586.585zM28 40.004V29.997L20 30v10.002l8 .002z"></path>
  );

export const BackIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    width="24"
    height="24"
    enableBackground="new -281 373 48 48"
    version="1.1"
    viewBox="-281 373 48 48"
    xmlSpace="preserve"
  >
    <g>
      <path
        fill={getColor(props)}
        d="M-262.2 397l12.6 12.6c.8.8.8 2 0 2.8s-2 .8-2.8 0l-14-14c-.8-.8-.8-2 0-2.8l14-14c.8-.8 2-.8 2.8 0s.8 2 0 2.8l-12.6 12.6z"
      ></path>
    </g>
  </svg>
);

export const AwardIcon = (props) =>
  svg(
    props,
    <path d="M19.86 31.224l-.427.741a1.994 1.994 0 01-2.731.73 2.001 2.001 0 01-.733-2.73l.34-.589a14.071 14.071 0 01-1.497-1.24L11.53 33.82l2.466-.16c1.358-.087 2.764.725 3.367 1.944l1.117 2.26 3.483-6.089a13.899 13.899 0 01-2.102-.552zM10.47 18c0-7.732 6.268-14 14-14s14 6.268 14 14c0 2.41-.609 4.676-1.68 6.656l5.905 10.23a2.09 2.09 0 01-1.97 3.153l-5.637-.363-2.533 5.08c-.737 1.492-2.837 1.652-3.716.13l-4.343-7.523-4.343 7.522c-.88 1.523-2.98 1.363-3.717-.128l-2.514-5.086-5.656.368a2.09 2.09 0 01-1.97-3.154l5.88-10.183A13.937 13.937 0 0110.47 18zm20.064 19.822l1.095-2.217c.602-1.219 2.008-2.031 3.366-1.944l2.458.159-3.294-5.715c-.478.459-.988.883-1.527 1.27l.34.59c.55.952.224 2.178-.733 2.73a1.994 1.994 0 01-2.73-.73l-.429-.742c-.66.23-1.342.412-2.043.542l3.497 6.057zM24.47 28c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10zm.108-6l-2.924 1.538c-.982.516-1.626.046-1.439-1.045l.559-3.257-2.366-2.306c-.794-.774-.546-1.532.55-1.69l3.27-.476 1.461-2.963c.491-.995 1.288-.993 1.778 0l1.462 2.963 3.27.475c1.098.16 1.342.918.55 1.69l-2.367 2.307.559 3.257c.188 1.093-.458 1.56-1.438 1.045L24.578 22z"></path>
  );

export const HamburgerIcon = (props) => (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    enableBackground="new -281 373 48 48"
    version="1.1"
    viewBox="-281 373 48 48"
    xmlSpace="preserve"
  >
    <g>
      <path fill={props.color} d="M-273 395h32c1.1 0 2 .9 2 2s-.9 2-2 2h-32c-1.1 0-2-.9-2-2s.9-2 2-2z"></path>
    </g>
    <g>
      <path fill={props.color} d="M-273 406h32c1.1 0 2 .9 2 2s-.9 2-2 2h-32c-1.1 0-2-.9-2-2s.9-2 2-2z"></path>
    </g>
    <g>
      <path fill={props.color} d="M-273 384h32c1.1 0 2 .9 2 2s-.9 2-2 2h-32c-1.1 0-2-.9-2-2s.9-2 2-2z"></path>
    </g>
  </svg>
);

export const FileIcon = (props) =>
  svg(
    props,
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <path d="M35.996 40H12l-.01-32H22v12a2 2 0 002 2h12l-.004 18zM26 8v10h10L26 8zM11.99 4h14.616c.551 0 1.318.319 1.7.702l10.992 10.996c.388.388.702 1.158.702 1.7v22.603A3.999 3.999 0 0135.996 44H12.004A4.006 4.006 0 018 40V8c0-2.21 1.792-4 3.99-4z"></path>
    </g>
  );

export const ExternalIcon = (props) =>
  svg(
    props,
    <path
      d="M38,12.8284271 L19.4142136,31.4142136 C18.633165,32.1952621 17.366835,32.1952621 16.5857864,31.4142136 C15.8047379,30.633165 15.8047379,29.366835 16.5857864,28.5857864 L35.1715729,10 L30,10 C28.8954305,10 28,9.1045695 28,8 C28,6.8954305 28.8954305,6 30,6 L40,6 C41.1045695,6 42,6.8954305 42,8 L42,18 C42,19.1045695 41.1045695,20 40,20 C38.8954305,20 38,19.1045695 38,18 L38,12.8284271 Z M38,26 C38,24.8954305 38.8954305,24 40,24 C41.1045695,24 42,24.8954305 42,26 L42,38.0049107 C42,40.2134024 40.2126114,42 38.0002927,42 L9.99970728,42 C7.78969516,42 6,40.2136474 6,38.0002927 L6,9.99970728 C6,7.79164701 7.78713255,6 9.99508929,6 L22,6 C23.1045695,6 24,6.8954305 24,8 C24,9.1045695 23.1045695,10 22,10 L10,10 L10,38 L38,38 L38,26 Z"
      fillRule="nonzero"
    ></path>
  );

export const VirtualPhotoIcon = (props) =>
  svg(
    props,
    <g>
      <path
        d="M-257.8,385.4h-5.3l-3-3.3h-9.9l-3,3.3h-5.3c-1.8,0-3.3,1.5-3.3,3.3v19.8c0,1.8,1.5,3.3,3.3,3.3h26.5
      c1.8,0,3.3-1.5,3.3-3.3v-19.8C-254.4,386.9-255.9,385.4-257.8,385.4z M-271,406.9c-4.6,0-8.3-3.6-8.3-8.3s3.6-8.3,8.3-8.3
      c4.6,0,8.3,3.6,8.3,8.3S-266.4,406.9-271,406.9z"
      />
      <circle cx="-271" cy="398.7" r="4.8" />
    </g>,
    '-288 382 34 30'
  );

export const VirtualVideoIcon = (props) =>
  svg(
    props,
    <path
      d="M46.6,10.5h-5.9c-0.5,0-0.9,0.2-1.1,0.5l-6.2,6.2V11c0-2.6-2.1-4.7-4.7-4.7H4.8c-2.6,0-4.7,2.1-4.7,4.7V36
  c0,2.6,2.1,4.7,4.7,4.7h23.9c2.6,0,4.7-2.1,4.7-4.7v-6.4l6.1,6.1c0.3,0.3,0.7,0.5,1.1,0.5h5.9c0.9,0,1.6-0.7,1.6-1.6V12
  C48.1,11.2,47.4,10.5,46.6,10.5z"
    />
  );

export const VirtualTrashIcon = (props) =>
  svg(
    props,
    <path
      d="M46.6,7.9H34.9V4c0-2.1-1.7-3.9-3.9-3.9H16.2c-2.1,0-3.9,1.7-3.9,3.9v3.9H1.4c-0.4,0-0.7,0.3-0.7,0.7v3.8
  c0,0.4,0.3,0.7,0.7,0.7h2l3.3,35.1H24h17.2L44.5,13h2c0.4,0,0.7-0.3,0.7-0.7V8.5C47.2,8.2,46.9,7.9,46.6,7.9z M15.3,4
  c0-0.5,0.4-1,1-1H31c0.5,0,1,0.4,1,1v3.9H15.3V4z M16.2,40.3C16.2,40.3,16.2,40.3,16.2,40.3c-0.6,0-1.1-0.5-1.1-1.1l-0.8-22
  c0-0.6,0.4-1.1,1.1-1.1c0.6-0.1,1.1,0.5,1.1,1.1l0.8,22C17.3,39.8,16.8,40.3,16.2,40.3z M25,39.2c0,0.6-0.5,1.1-1.1,1.1
  s-1.1-0.5-1.1-1.1v-22c0-0.6,0.5-1.1,1.1-1.1s1.1,0.5,1.1,1.1V39.2z M32.9,39.3c0,0.6-0.5,1.1-1.1,1.1c0,0,0,0,0,0
  c-0.6,0-1.1-0.5-1.1-1.1l0.8-22c0-0.6,0.6-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1L32.9,39.3z"
    />
  );

export const VirtualBoomerangIcon = () => (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    enableBackground="new -281 373 48 48"
    version="1.1"
    viewBox="-281 373 48 48"
    xmlSpace="preserve"
    viewBox="0 13.5 48 20.9"
  >
    <path
      d="M43.6,15.4c-2.8-2-6.3-2.4-9.7-1.2c-4,1.4-7.8,4.4-9.9,6.3c-2.1-1.9-5.9-4.9-9.9-6.3c-3.4-1.2-6.9-0.8-9.7,1.2
      C1.7,17.4,0,20.6,0,24s1.5,6.5,4.1,8.4c1.8,1.3,3.9,2,6.2,2c1.2,0,2.4-0.2,3.7-0.6c4.1-1.3,7.9-4.3,10-6.3c2.1,2,5.9,5,10,6.3
      c1.2,0.4,2.5,0.6,3.7,0.6c2.3,0,4.5-0.7,6.2-2c2.6-1.9,4.1-5,4.1-8.4S46.4,17.4,43.6,15.4z M12.6,29c-2.1,0.6-4.1,0.4-5.5-0.7
      C5.8,27.4,5,25.8,5,24s0.8-3.5,2.3-4.5c0.9-0.6,1.9-1,3-1c0.7,0,1.4,0.1,2.1,0.4c3.1,1.1,6.1,3.5,8,5.1C18.5,25.8,15.5,28.1,12.6,29
      z M41,28.4c-1.4,1.1-3.4,1.3-5.5,0.7c-3.1-0.9-6.1-3.3-7.9-5c1.9-1.6,4.9-4,8-5.1c1.8-0.7,3.7-0.5,5.1,0.6c1.4,1,2.3,2.7,2.3,4.5
      C43,25.8,42.3,27.4,41,28.4z"
    />
  </svg>
);

export const VirtualGifIcon = (props) =>
  svg(
    props,
    <>
      <g>
        <path
          d="M14.7,17.8c-0.3,0-0.7-0.1-1-0.2L1,10.7c-0.6-0.4-1-1-1-1.8s0.4-1.4,1-1.8l12.7-6.9
        c0.6-0.3,1.3-0.3,1.9,0l12.7,6.9c0.6,0.4,1,1,1,1.8s-0.4,1.4-1,1.8l-12.7,6.9C15.4,17.7,15.1,17.8,14.7,17.8z M6.2,8.9l8.5,4.6
        l8.5-4.6l-8.5-4.6L6.2,8.9z"
        />
      </g>
      <g>
        <path
          d="M14.7,26.2c-0.3,0-0.7-0.1-1-0.2L1,19.1c-0.6-0.3-1-1-1-1.8c0-0.7,0.4-1.4,1-1.8L3.8,14
        c1-0.5,2.2-0.2,2.7,0.8c0.4,0.8,0.3,1.8-0.4,2.4l8.6,4.7l8.6-4.7c-0.7-0.6-0.9-1.6-0.4-2.4c0.5-1,1.7-1.3,2.7-0.8l2.8,1.5
        c0.6,0.4,1,1,1,1.8c0,0.7-0.4,1.4-1,1.8L15.7,26C15.4,26.1,15.1,26.2,14.7,26.2z"
        />
      </g>
      <g>
        <path
          d="M14.7,34.6c-0.3,0-0.7-0.1-1-0.2L1,27.5c-0.6-0.3-1-1-1-1.8s0.4-1.4,1-1.8l2.8-1.5
        c1-0.5,2.2-0.2,2.7,0.8c0.4,0.8,0.3,1.8-0.4,2.4l8.6,4.7l8.6-4.7c-0.7-0.6-0.9-1.6-0.4-2.4c0.5-1,1.7-1.3,2.7-0.8l2.8,1.5
        c0.6,0.3,1,1,1,1.8s-0.4,1.4-1,1.8l-12.7,6.9C15.4,34.5,15.1,34.6,14.7,34.6z"
        />
      </g>
    </>,
    '0 0 29.5 34.6'
  );

export const UnmutedIcon = (props) =>
  svg(
    props,
    <g>
      <path
        d="M23.29,0.34L7.75,12.82H1.51C0.68,12.82,0,13.49,0,14.33l0,0v19.33c0,0.83,0.68,1.51,1.51,1.51h6.24
l15.55,12.49c0.47,0.38,1.17,0.3,1.55-0.17c0.16-0.2,0.24-0.44,0.24-0.69V1.2c0-0.61-0.49-1.1-1.1-1.1
C23.73,0.1,23.49,0.18,23.29,0.34z"
      />
      <path
        d="M41.09,8.37c-1.13-0.95-2.82-0.81-3.77,0.32c-0.9,1.07-0.83,2.66,0.17,3.64c3.47,3.26,5.15,7.08,5.15,11.67
  c0.04,4.45-1.83,8.7-5.15,11.67c-1,0.93-1.14,2.48-0.31,3.57c0.88,1.17,2.55,1.41,3.72,0.53c0.06-0.05,0.12-0.09,0.18-0.15
  c8.63-7.85,9.26-21.2,1.42-29.83C42.06,9.3,41.58,8.82,41.09,8.37z"
      />
      <path
        d="M32.35,13.51c-0.99-0.99-2.6-0.99-3.59-0.01s-0.99,2.6-0.01,3.59l0,0c1.88,1.8,2.93,4.3,2.89,6.91
  c0.04,2.6-1.01,5.1-2.89,6.9c-0.99,1-0.98,2.61,0.02,3.59c0.48,0.47,1.12,0.73,1.79,0.73c0.66,0,1.29-0.26,1.76-0.72
  c2.8-2.69,4.38-6.41,4.38-10.3C36.83,20.18,35.24,16.3,32.35,13.51z"
      />
    </g>
  );

export const MutedIcon = (props) =>
  svg(
    props,
    <g>
      <path
        d="M2.61,12.55c-0.81,0-1.46,0.65-1.46,1.46v18.74c0,0.81,0.65,1.46,1.46,1.46h6.05l15.07,12.13
  		c0.46,0.37,1.13,0.31,1.51-0.15c0.16-0.19,0.24-0.43,0.24-0.68v-9.89L3.4,12.55H2.61z"
      />
      <path
        d="M40.99,38.55L40.99,38.55c8.37-7.61,8.98-20.56,1.38-28.93c-0.44-0.48-0.9-0.94-1.38-1.38
  		c-1.09-0.92-2.73-0.79-3.66,0.31c-0.88,1.04-0.8,2.58,0.17,3.53c3.37,3.15,5.02,6.86,5.02,11.33c0.03,4.31-1.8,8.42-5.01,11.29
  		c-0.03,0.03-0.05,0.08-0.08,0.12l-3.19-3.3c1.63-2.32,2.5-5.09,2.5-7.92c0.11-3.89-1.42-7.64-4.21-10.35
  		c-0.94-0.96-2.49-0.97-3.45-0.03c-0.09,0.09-0.18,0.19-0.25,0.29c-0.73,0.98-0.64,2.34,0.21,3.21c2.98,2.91,3.66,7.45,1.68,11.11
  		l-5.23-5.49V1.28c-0.01-0.6-0.51-1.07-1.1-1.06c-0.23,0-0.46,0.09-0.65,0.23L12.87,9.17L4.86,0.79C3.83-0.25,2.15-0.27,1.1,0.76
  		C0.08,1.77,0.04,3.42,1.02,4.47l7.69,8.04l0,0l15.94,16.67l17.21,17.98c1,1.07,2.68,1.13,3.76,0.13c1.07-1,1.13-2.68,0.13-3.76
  		c-0.02-0.02-0.03-0.03-0.05-0.05L40.99,38.55z"
      />
    </g>
  );

export const SwitchIcon = (props) =>
  svg(
    props,
    <path
      d="M15,5.3c-0.9-0.9-0.9-2.5,0-3.4c0.9-0.9,2.5-0.9,3.4,0l7.2,7.2c0.9,0.9,0.9,2.5,0,3.4
    l-7.2,7.2c-0.9,0.9-2.5,0.9-3.4,0c-0.9-0.9-0.9-2.5,0-3.4l3.1-3.1h-2.5C9.6,13.2,4.8,18,4.8,24c0,5.6,5,10.8,9.6,10.8
    c1.3,0,2.4,1.1,2.4,2.4s-1.1,2.4-2.4,2.4C7.1,39.6,0,32.2,0,24C0,15.4,7,8.4,15.6,8.4h2.5L15,5.3z M32.8,42.7
    c0.9,0.9,0.9,2.5,0,3.4c-0.9,0.9-2.5,0.9-3.4,0l-7.2-7.2c-0.9-0.9-0.9-2.5,0-3.4l7.2-7.2c0.9-0.9,2.5-0.9,3.4,0
    c0.9,0.9,0.9,2.5,0,3.4l-3.1,3.1h2.6c6,0,10.8-4.8,10.8-10.8c0-5.6-5-10.8-9.6-10.8c-1.3,0-2.4-1.1-2.4-2.4s1.1-2.4,2.4-2.4
    C40.8,8.4,48,15.8,48,24c0,8.6-7,15.6-15.6,15.6h-2.6L32.8,42.7z"
    />
  );

export const MicIcon = (props) =>
  svg(
    props,
    <path
      d="M38.1,22.9c1.8,0,3.3,1.5,3.3,3.3s-1.5,3.3-3.3,3.3h-0.4c-1.3,5-5.4,8.8-10.5,9.6v4.5
    h-4.4v-4.5c-5.1-0.9-9.2-4.7-10.5-9.6h-0.4c-1.8,0-3.3-1.5-3.3-3.3s1.5-3.3,3.3-3.3v-9.8C11.9,5.9,17.8,0,25,0
    c7.2,0,13.1,5.9,13.1,13.1V22.9z M25,4.4c-3.2,0-6.1,1.8-7.6,4.4h3.2c1.2,0,2.2,1,2.2,2.2c0,1.2-1,2.2-2.2,2.2h-4.4v2.2h4.4
    c1.2,0,2.2,1,2.2,2.2c0,1.2-1,2.2-2.2,2.2h-4.4V24h17.5v-4.4h-4.4c-1.2,0-2.2-1-2.2-2.2c0-1.2,1-2.2,2.2-2.2h4.4v-2.2h-4.4
    c-1.2,0-2.2-1-2.2-2.2c0-1.2,1-2.2,2.2-2.2h3.2C31,6.1,28.2,4.4,25,4.4z M16.5,28.4c1,3.8,4.4,6.5,8.5,6.5c4.1,0,7.5-2.8,8.5-6.5
    H16.5z M14.1,45.8c0-1.2,1-2.2,2.2-2.2h17.5c1.2,0,2.2,1,2.2,2.2c0,1.2-1,2.2-2.2,2.2H16.3C15.1,48,14.1,47,14.1,45.8z"
    />
  );

export const NoneIcon = (props) =>
  svg(
    props,
    <path
      style={{ fillRule: 'evenodd', clipRule: 'evenodd' }}
      d="M35.77,8.83C32.52,6.3,28.44,4.8,24,4.8C13.4,4.8,4.8,13.4,4.8,24
  		c0,4.44,1.5,8.52,4.03,11.77L35.77,8.83L35.77,8.83z M39.17,12.23C41.7,15.48,43.2,19.56,43.2,24c0,10.6-8.6,19.2-19.2,19.2
  		c-4.44,0-8.52-1.5-11.77-4.03L39.17,12.23L39.17,12.23z M48,24C48,10.75,37.25,0,24,0S0,10.75,0,24s10.75,24,24,24S48,37.25,48,24z
  		"
    />
  );

export const PrintIcon = (props) =>
  svg(
    props,
    <path
      d="M36.07,15.73h6c2.21,0,4,1.78,4,3.99v16.02c0,2.2-1.79,3.99-4,3.99h-6v2.01
  		c0,1.1-0.9,1.99-2,1.99H14.08c-1.11,0-2-0.89-2-1.99v-2.01h-6c-2.21,0-4-1.78-4-3.99V19.72c0-2.2,1.79-3.99,4-3.99h7V5.72
  		c0-1.1,0.9-1.99,2-1.99h19c1.11,0,2,0.89,2,1.99V15.73z M12.07,31.73c-1.11,0-2-0.89-2-2c0-1.1,0.9-2,1.99-2h24.02
  		c1.1,0,1.99,0.89,1.99,2c0,1.1-0.9,2-1.99,2l-0.01,4h6v-16h-36v16h6V31.73z M16.07,31.73v8h16v-8H16.07z M12.07,25.73
  		c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S13.18,25.73,12.07,25.73z M17.07,7.73v8h15v-8H17.07z"
    />
  );

export const TextIcon = (props) =>
  svg(
    props,
    <path
      d="M44.36,41.08c0,2.21-1.44,2.93-3.2,1.62l-9.8-7.33h-23c-2.21,0-4-1.79-4-4V7.37
  		c0-2.21,1.8-4,4-4h32c2.21,0,4,1.79,4,4V41.08z M40.36,7.37l-32,0l0,24l24.33,0l7.71,5.76L40.36,7.37z"
    />
  );

export const EmailIcon = (props) =>
  svg(
    props,
    <path
      d="M28.36,26.52l-2.59,2.59c-0.78,0.78-2.05,0.78-2.83,0l-2.59-2.59L9.18,37.7l30.35-0.01
  		L28.36,26.52z M42.36,12.52L31.19,23.69l11.17,11.17L42.36,12.52z M17.53,23.69L6.36,12.52l0,22.34L17.53,23.69z M24.36,24.93
  		L39.55,9.68L9.18,9.69L24.36,24.93z M6.36,5.69h36c2.21,0,4,1.79,4,3.99V37.7c0,2.2-1.79,3.99-4,3.99h-36c-2.21,0-4-1.79-4-3.99
  		V9.68C2.36,7.48,4.16,5.69,6.36,5.69z"
    />
  );

export const QRIcon = (props) =>
  svg(
    props,
    <path
      style={{ fillRule: 'evenodd', clipRule: 'evenodd' }}
      d="M34.36,21.69c1.11,0,2.01,0.89,2.01,2c0,1.1-0.9,2-2.01,2h-9.99c-1.11,0-2.01-0.89-2.01-2
  		v-8c0-1.1,0.89-2,2-2c1.1,0,2,0.89,2,2v6H34.36z M40.36,35.69v-6.01c0-1.1,0.89-1.99,2-1.99c1.1,0,2,0.89,2,1.99V41.7
  		c0,1.1-0.89,1.99-2,1.99c-1.1,0-2-0.89-2-1.99v-2.01h-4v1.99c0,1.11-0.89,2.01-2,2.01c-1.1,0-2-0.9-2-2.01v-1.99h-2.01
  		c-1.1,0-1.99-0.89-1.99-2c0-1.1,0.89-2,1.99-2h2.01V33.7c0-1.11,0.89-2.01,2-2.01c1.1,0,2,0.9,2,2.01v1.99H40.36z M4.36,5.69
  		c0-1.1,0.89-1.99,1.99-1.99h12.01c1.1,0,1.99,0.89,1.99,1.99V17.7c0,1.1-0.89,1.99-1.99,1.99H6.36c-1.1,0-1.99-0.89-1.99-1.99V5.69
  		z M28.36,5.69c0-1.1,0.89-1.99,1.99-1.99h12.01c1.1,0,1.99,0.89,1.99,1.99V17.7c0,1.1-0.89,1.99-1.99,1.99H30.36
  		c-1.1,0-1.99-0.89-1.99-1.99V5.69z M4.36,29.69c0-1.1,0.89-1.99,1.99-1.99h12.01c1.1,0,1.99,0.89,1.99,1.99V41.7
  		c0,1.1-0.89,1.99-1.99,1.99H6.36c-1.1,0-1.99-0.89-1.99-1.99V29.69z M8.36,7.69v8h8v-8H8.36z M32.36,7.69v8h8v-8H32.36z
  		 M8.36,31.69v8h8v-8H8.36z M4.36,23.69c0-1.1,0.89-2,2-2c1.1,0,2,0.89,2,2c0,1.1-0.89,2-2,2C5.26,25.69,4.36,24.8,4.36,23.69z
  		 M10.36,11.69c0-1.1,0.89-2,2-2c1.1,0,2,0.89,2,2c0,1.1-0.89,2-2,2C11.26,13.69,10.36,12.8,10.36,11.69z M22.36,29.69
  		c0-1.1,0.89-2,2-2h6c1.1,0,2,0.89,2,2c0,1.1-0.89,2-2,2h-6C23.26,31.69,22.36,30.8,22.36,29.69z M40.36,23.69c0-1.1,0.89-2,2-2
  		c1.1,0,2,0.89,2,2c0,1.1-0.89,2-2,2C41.26,25.69,40.36,24.8,40.36,23.69z M22.36,41.69c0-1.1,0.89-2,2-2c1.1,0,2,0.89,2,2
  		c0,1.1-0.89,2-2,2C23.26,43.69,22.36,42.8,22.36,41.69z M10.36,23.69c0-1.1,0.89-2,2-2h6c1.1,0,2,0.89,2,2c0,1.1-0.89,2-2,2h-6
  		C11.26,25.69,10.36,24.8,10.36,23.69z M10.36,35.69c0-1.1,0.89-2,2-2c1.1,0,2,0.89,2,2c0,1.1-0.89,2-2,2
  		C11.26,37.69,10.36,36.8,10.36,35.69z M34.36,11.69c0-1.1,0.89-2,2-2c1.1,0,2,0.89,2,2c0,1.1-0.89,2-2,2
  		C35.26,13.69,34.36,12.8,34.36,11.69z M22.36,5.69c0-1.1,0.89-2,2-2c1.1,0,2,0.89,2,2v4c0,1.1-0.89,2-2,2c-1.1,0-2-0.89-2-2V5.69z"
    />
  );

export const ChecklistIcon = (props) =>
  svg(
    props,
    <path
      d="M32.36,2.69c1.1,0,2,0.89,2,2l4,0c2.21,0,4,1.79,4,4v32c0,2.21-1.79,4-4,4h-28
		c-2.21,0-4-1.79-4-4v-32c0-2.21,1.79-4,4-4h4c0-1.11,0.89-2,2-2c1.1,0,2,0.89,2,2l4,0c0-1.11,0.89-2,2-2c1.1,0,2,0.89,2,2l4,0
		C30.36,3.59,31.25,2.69,32.36,2.69z M26.36,8.69c0,1.11-0.89,2-2,2c-1.1,0-2-0.89-2-2c-1.38,0-2.73,0-4,0c0,1.11-0.89,2-2,2
		c-1.1,0-2-0.89-2-2c-2.42,0-4,0-4,0l0,32l28,0l0-32c0,0-1.58,0-4,0c0,1.11-0.89,2-2,2c-1.1,0-2-0.89-2-2
		C29.09,8.69,27.74,8.69,26.36,8.69z M24.36,19.69c0-1.1,0.9-2,2.01-2h7.98c1.11,0,2.01,0.89,2.01,2c0,1.1-0.9,2-2.01,2h-7.98
		C25.26,21.69,24.36,20.8,24.36,19.69z M24.36,30.69c0-1.1,0.9-2,2.01-2h7.98c1.11,0,2.01,0.89,2.01,2c0,1.1-0.9,2-2.01,2h-7.98
		C25.26,32.69,24.36,31.8,24.36,30.69z M14.95,21.11l-2-2c-0.78-0.78-0.78-2.05,0-2.83c0.78-0.78,2.05-0.78,2.83,0l0.59,0.59
		l2.59-2.59c0.78-0.78,2.05-0.78,2.83,0c0.78,0.78,0.78,2.05,0,2.83l-4,4C17,21.89,15.73,21.89,14.95,21.11z M14.95,32.11l-2-2
		c-0.78-0.78-0.78-2.05,0-2.83c0.78-0.78,2.05-0.78,2.83,0l0.59,0.59l2.58-2.59c0.78-0.78,2.05-0.78,2.83,0
		c0.78,0.78,0.78,2.05,0,2.83l-4,4C17,32.89,15.73,32.89,14.95,32.11z"
    />
  );

export const AirDropIcon = (props) =>
  svg(
    props,
    <path
      d="M44.36,26.22c0,5.36-2.12,10.38-5.82,14.1c-0.78,0.78-2.05,0.79-2.83,0.01s-0.79-2.05-0.01-2.83
		c2.96-2.98,4.65-6.99,4.65-11.28c0-8.84-7.16-16-16-16s-16,7.16-16,16c0,4.31,1.71,8.34,4.7,11.33c0.78,0.78,0.78,2.05,0,2.83
		s-2.05,0.78-2.83,0C6.5,36.65,4.36,31.6,4.36,26.22c0-11.05,8.95-20,20-20S44.36,15.18,44.36,26.22z M38.36,26.22
		c0,3.75-1.49,7.28-4.08,9.88c-0.78,0.78-2.05,0.78-2.83,0s-0.78-2.05,0-2.83c1.86-1.86,2.92-4.38,2.92-7.06c0-5.52-4.48-10-10-10
		s-10,4.48-10,10c0,2.69,1.07,5.21,2.93,7.08c0.78,0.78,0.78,2.05,0,2.83c-0.78,0.78-2.05,0.78-2.83,0
		c-2.61-2.61-4.11-6.14-4.11-9.91c0-7.73,6.27-14,14-14S38.36,18.49,38.36,26.22z M32.36,26.22c0,2.15-0.85,4.17-2.34,5.66
		c-0.78,0.78-2.05,0.78-2.83,0c-0.78-0.78-0.78-2.05,0-2.83c0.75-0.75,1.17-1.75,1.17-2.83c0-2.21-1.79-4-4-4s-4,1.79-4,4
		c0,1.09,0.43,2.1,1.19,2.85c0.79,0.77,0.8,2.04,0.02,2.83c-0.77,0.79-2.04,0.8-2.83,0.02c-1.52-1.49-2.39-3.53-2.39-5.7
		c0-4.42,3.58-8,8-8S32.36,21.8,32.36,26.22z M24.36,28.22c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S25.47,28.22,24.36,28.22z"
    />
  );

export const WarningIcon = (props) =>
  svg(
    props,
    <>
      <g>
        <path
          d="M5.85,45c-1.05-0.01-2.06-0.3-2.94-0.82c-0.89-0.52-1.62-1.27-2.13-2.16C0.27,41.13,0,40.12,0,39.09
        c0-1.03,0.27-2.04,0.79-2.93L18.96,5.83c0,0,0-0.01,0-0.01c0.53-0.86,1.27-1.58,2.15-2.08c1.75-0.99,4.03-0.99,5.79,0
        c0.88,0.5,1.62,1.22,2.14,2.08L47.2,36.14c0.53,0.91,0.8,1.92,0.8,2.95c0,1.03-0.27,2.05-0.78,2.94
        c-0.51,0.89-1.24,1.64-2.13,2.16c-0.89,0.52-1.9,0.8-2.93,0.82H5.85z M21.71,7.49L3.56,37.79c-0.22,0.38-0.34,0.84-0.35,1.31
        c0,0.47,0.12,0.93,0.35,1.33c0.23,0.4,0.57,0.74,0.97,0.98c0.4,0.24,0.86,0.36,1.33,0.37h36.28c0.45-0.01,0.91-0.13,1.32-0.37
        c0.4-0.24,0.73-0.58,0.96-0.98c0.23-0.4,0.35-0.87,0.35-1.34c0-0.46-0.13-0.92-0.36-1.33L26.28,7.48
        c-0.23-0.39-0.57-0.71-0.97-0.94c-0.8-0.45-1.83-0.45-2.63,0C22.29,6.77,21.95,7.09,21.71,7.49z"
        />
      </g>
      <g>
        <path
          d="M24,27.85c-0.89,0-1.61-0.72-1.61-1.61v-8.57c0-0.89,0.72-1.61,1.61-1.61s1.61,0.72,1.61,1.61v8.57
        C25.61,27.13,24.89,27.85,24,27.85z"
        />
      </g>
      <g>
        <path
          d="M24.02,36.42c-0.89,0-1.62-0.72-1.62-1.61c0-0.89,0.71-1.61,1.6-1.61h0.02c0.89,0,1.61,0.72,1.61,1.61
        C25.63,35.71,24.91,36.42,24.02,36.42z"
        />
      </g>
    </>
  );

export const UndoIcon = (props) =>
  svg(
    props,
    <path
      d="M31.18,19.83c4.41,0,7.99,3.58,7.99,8c0,4.42-3.58,8-7.99,8H7.17c-1.1,0-2,0.9-2,2
		s0.9,2,2,2h24.01c6.62,0,11.99-5.38,11.99-12c0-6.63-5.37-12-11.99-12H12l4.59-4.59c0.78-0.78,0.78-2.05,0-2.83
		c-0.78-0.78-2.05-0.78-2.83,0l-8,8c-0.78,0.78-0.78,2.05,0,2.83l8,8c0.78,0.78,2.05,0.78,2.83,0c0.78-0.78,0.78-2.05,0-2.83
		L12,19.83H31.18z"
    />
  );

export const RedoIcon = (props) =>
  svg(
    props,
    <path
      d="M17.16,27.83c-4.41,0-7.99-3.58-7.99-8c0-4.42,3.58-8,7.99-8h24.01c1.1,0,2-0.9,2-2
		s-0.9-2-2-2H17.16c-6.62,0-11.99,5.38-11.99,12c0,6.63,5.37,12,11.99,12h19.18l-4.59,4.59c-0.78,0.78-0.78,2.05,0,2.83
		s2.05,0.78,2.83,0l8-8c0.78-0.78,0.78-2.05,0-2.83l-8-8c-0.78-0.78-2.05-0.78-2.83,0c-0.78,0.78-0.78,2.05,0,2.83l4.59,4.59H17.16z"
    />
  );

export const LeftAlignIcon = (props) =>
  svg(
    props,
    <path
      d="M6.07,38.73c0-1.1,0.9-2,1.99-2h16.02c1.1,0,1.99,0.89,1.99,2c0,1.1-0.9,2-1.99,2H8.06
		C6.96,40.73,6.07,39.84,6.07,38.73z M6.07,28.73c0-1.1,0.89-2,2-2h32c1.1,0,2,0.89,2,2c0,1.1-0.89,2-2,2h-32
		C6.97,30.73,6.07,29.84,6.07,28.73z M6.07,18.73c0-1.1,0.9-2,1.99-2h24.02c1.1,0,1.99,0.89,1.99,2c0,1.1-0.9,2-1.99,2H8.06
		C6.96,20.73,6.07,19.84,6.07,18.73z M6.07,8.73c0-1.1,0.89-2,2-2h32c1.1,0,2,0.89,2,2c0,1.1-0.89,2-2,2h-32
		C6.97,10.73,6.07,9.84,6.07,8.73z"
    />
  );

export const CenterAlignIcon = (props) =>
  svg(
    props,
    <path
      d="M16.07,38.73c0-1.1,0.89-2,1.99-2h12.01c1.1,0,1.99,0.89,1.99,2c0,1.1-0.89,2-1.99,2
		H18.07C16.96,40.73,16.07,39.84,16.07,38.73z M6.07,28.73c0-1.1,0.89-2,2-2h32c1.1,0,2,0.89,2,2c0,1.1-0.89,2-2,2h-32
		C6.97,30.73,6.07,29.84,6.07,28.73z M12.07,18.73c0-1.1,0.9-2,2-2h19.99c1.11,0,2,0.89,2,2c0,1.1-0.9,2-2,2H14.08
		C12.97,20.73,12.07,19.84,12.07,18.73z M6.07,8.73c0-1.1,0.89-2,2-2h32c1.1,0,2,0.89,2,2c0,1.1-0.89,2-2,2h-32
		C6.97,10.73,6.07,9.84,6.07,8.73z"
    />
  );

export const RightAlignIcon = (props) =>
  svg(
    props,
    <path
      d="M22.07,38.73c0-1.1,0.9-2,1.99-2h16.02c1.1,0,1.99,0.89,1.99,2c0,1.1-0.9,2-1.99,2H24.06
		C22.96,40.73,22.07,39.84,22.07,38.73z M6.07,28.73c0-1.1,0.89-2,2-2h32c1.1,0,2,0.89,2,2c0,1.1-0.89,2-2,2h-32
		C6.97,30.73,6.07,29.84,6.07,28.73z M14.07,18.73c0-1.1,0.9-2,1.99-2h24.02c1.1,0,1.99,0.89,1.99,2c0,1.1-0.9,2-1.99,2H16.06
		C14.96,20.73,14.07,19.84,14.07,18.73z M6.07,8.73c0-1.1,0.89-2,2-2h32c1.1,0,2,0.89,2,2c0,1.1-0.89,2-2,2h-32
		C6.97,10.73,6.07,9.84,6.07,8.73z"
    />
  );

export const BoldIcon = (props) =>
  svg(
    props,
    <path
      d="M12.67,39V9h9.83c3.5,0,6.33,0.67,8.33,2s3,3.5,3,6.17c0,1.33-0.33,2.67-1,3.67c-0.67,1.17-1.83,1.83-3.17,2.5
	c1.83,0.33,3.33,1.17,4.17,2.5c1,1.33,1.5,2.83,1.5,4.5c0,2.83-1,5-2.83,6.5c-2,1.5-4.83,2.17-8.17,2.17H12.67z M18.67,21.5h4
	c1.67,0,2.83-0.33,3.67-1c1-0.5,1.33-1.5,1.33-2.83s-0.5-2.33-1.33-3s-2.17-1-3.83-1h-3.83V21.5z M18.67,25.67v8.67h5.67
	c1.67,0,2.83-0.33,3.67-1c0.83-0.67,1.33-1.67,1.33-3c0-1.5-0.33-2.5-1.17-3.33c-0.83-0.83-2-1.17-3.67-1.17h-5.83V25.67z"
    />
  );

export const ItalicIcon = (props) =>
  svg(props, <path d="M19,9v5h3.85l-2.92,20h-4.27v5H29v-5h-4.06l2.92-20h4.48V9H19z" />);

export const TypeIcon = (props) => svg(props, <path d="M38.12,14.29H27.53V39h-7.06V14.29H9.88V9h28.24V14.29z" />);

export const LayerUpIcon = (props) =>
  svg(
    props,
    <>
      <g>
        <path
          d="M45.06,20.44l-3.63-1.68l3.63-1.68c0.31-0.14,0.5-0.45,0.5-0.78c0-0.34-0.19-0.64-0.5-0.78L29.13,8.14
        c-0.23-0.11-0.5-0.11-0.72,0l-15.93,7.38c-0.31,0.14-0.5,0.45-0.5,0.78c0,0.34,0.19,0.64,0.5,0.78l3.63,1.68l-3.63,1.68
        c-0.31,0.14-0.5,0.45-0.5,0.78s0.19,0.64,0.5,0.78l15.93,7.38c0.11,0.05,0.24,0.08,0.36,0.08c0.12,0,0.25-0.03,0.36-0.08
        l15.93-7.38c0.31-0.14,0.5-0.45,0.5-0.78S45.37,20.59,45.06,20.44z M28.77,9.87l13.88,6.43l-3.27,1.51l-1.03,0.47l-1.03,0.47
        l-8.56,3.97l-8.56-3.97l-1.03-0.47l-1.03-0.47l-3.27-1.51L28.77,9.87z"
        />
        <path
          d="M44.34,25.37l-15.57,7.21L13.2,25.37c-0.43-0.2-0.94-0.01-1.14,0.42c-0.2,0.43-0.01,0.94,0.42,1.14l16.29,7.55l16.3-7.55
        c0.43-0.2,0.62-0.71,0.42-1.14C45.28,25.36,44.77,25.17,44.34,25.37z"
        />
        <path
          d="M44.34,30.3l-15.57,7.21L13.2,30.3c-0.43-0.2-0.94-0.01-1.14,0.42c-0.2,0.43-0.01,0.94,0.42,1.14l16.29,7.55l16.3-7.55
        c0.43-0.2,0.62-0.71,0.42-1.14C45.28,30.29,44.77,30.09,44.34,30.3z"
        />
      </g>
      <g>
        <path
          d="M7.37,19.72v10.92c0,0.42-0.36,0.76-0.8,0.76c-0.44,0-0.8-0.34-0.8-0.76V19.72l-1.84,1.84
        c-0.31,0.31-0.82,0.31-1.13,0c-0.31-0.31-0.31-0.82,0-1.13l3.2-3.2c0.31-0.31,0.82-0.31,1.13,0l3.2,3.2c0.31,0.31,0.31,0.82,0,1.13
        c-0.31,0.31-0.82,0.31-1.13,0L7.37,19.72z"
        />
      </g>
    </>
  );

export const LayerDownIcon = (props) =>
  svg(
    props,
    <>
      <g>
        <path
          d="M12.38,17.34l15.95,7.39c0.12,0.05,0.24,0.08,0.36,0.08c0.12,0,0.25-0.03,0.36-0.08L45,17.34c0.3-0.14,0.5-0.45,0.5-0.78
        c0-0.34-0.2-0.64-0.5-0.78L29.05,8.39c-0.23-0.11-0.5-0.11-0.73,0l-15.95,7.39c-0.3,0.14-0.5,0.45-0.5,0.78
        C11.88,16.89,12.08,17.2,12.38,17.34z M28.69,10.12l13.89,6.44l-13.89,6.44L14.8,16.56L28.69,10.12z"
        />
        <path
          d="M45.42,21.13c-0.2-0.44-0.72-0.62-1.15-0.42l-5.26,2.43c-0.03,0.01-0.06,0.01-0.09,0.02l-10.24,4.76l-15.58-7.22
        c-0.43-0.2-0.95-0.02-1.15,0.42c-0.2,0.43-0.01,0.95,0.42,1.15l3.63,1.68l-3.63,1.68c-0.3,0.14-0.5,0.45-0.5,0.78
        s0.2,0.64,0.5,0.78l15.95,7.39c0.12,0.05,0.24,0.08,0.36,0.08c0.12,0,0.25-0.03,0.36-0.08L45,27.2c0.3-0.14,0.5-0.45,0.5-0.78
        s-0.2-0.64-0.5-0.78l-3.63-1.68L45,22.27C45.43,22.07,45.62,21.56,45.42,21.13z"
        />
        <path
          d="M44.27,30.57l-15.58,7.22l-15.58-7.22c-0.43-0.2-0.95-0.02-1.15,0.42c-0.2,0.43-0.01,0.95,0.42,1.15l16.31,7.56L45,32.14
        c0.43-0.2,0.62-0.71,0.42-1.15C45.22,30.55,44.7,30.37,44.27,30.57z"
        />
      </g>
      <g>
        <path
          d="M5.71,28.94V18c0-0.42,0.36-0.76,0.8-0.76c0.44,0,0.8,0.34,0.8,0.76v10.93l1.84-1.84
        c0.31-0.31,0.82-0.31,1.13,0s0.31,0.82,0,1.13l-3.21,3.21c-0.31,0.31-0.82,0.31-1.13,0l-3.21-3.21c-0.31-0.31-0.31-0.82,0-1.13
        c0.31-0.31,0.82-0.31,1.13,0L5.71,28.94z"
        />
      </g>
    </>
  );

export const ToolbarTrashIcon = (props) =>
  svg(
    props,
    <>
      <path
        d="M40.56,12.12h-8.55V9.27c0-1.54-1.24-2.85-2.85-2.85H18.33c-1.54,0-2.85,1.24-2.85,2.85v2.85H7.51
  	C7.22,12.12,7,12.34,7,12.63v2.78c0,0.29,0.22,0.51,0.51,0.51h1.46l2.41,25.66h12.65h12.57l2.41-25.73h1.46
  	c0.29,0,0.51-0.22,0.51-0.51v-2.78C41,12.34,40.78,12.12,40.56,12.12z M17.68,9.27c0-0.37,0.29-0.73,0.73-0.73h10.75
  	c0.37,0,0.73,0.29,0.73,0.73v2.85H17.68V9.27z M18.33,35.81L18.33,35.81c-0.44,0-0.8-0.37-0.8-0.8l-0.58-16.08
  	c0-0.44,0.29-0.8,0.8-0.8c0.44-0.07,0.8,0.37,0.8,0.8L19.14,35C19.14,35.44,18.77,35.81,18.33,35.81z M24.77,35
  	c0,0.44-0.37,0.8-0.8,0.8c-0.44,0-0.8-0.37-0.8-0.8V18.92c0-0.44,0.37-0.8,0.8-0.8c0.44,0,0.8,0.37,0.8,0.8V35z M30.54,35.08
  	c0,0.44-0.37,0.8-0.8,0.8l0,0c-0.44,0-0.8-0.37-0.8-0.8l0.58-16.08c0-0.44,0.44-0.8,0.8-0.8c0.44,0,0.8,0.37,0.8,0.8L30.54,35.08z"
      />
    </>
  );

export const FolderIcon = (props) =>
  svg(
    props,
    <path
      d="M44.36,19.74c2.55,0,4.04,2.31,3.01,4.64l-6.38,14.35c-0.77,1.73-2.75,3.01-4.63,3.01
		L3.73,41.72c-0.35,0-0.67-0.04-0.97-0.12c-1.74-0.44-3.02-2-3.02-3.87V9.71c0-2.2,1.8-3.99,3.99-3.99h12.09
		c1.11,0,2.49,0.75,3.1,1.67l2.83,4.33h13.98c2.21,0,4.01,1.79,4.01,4v4.01L44.36,19.74z M7.1,22.73c0.77-1.73,2.75-3.01,4.63-3.01
		l24,0.01l0-4.01l-13.99,0c-1.35,0-2.61-0.68-3.35-1.81l-2.66-4.18L3.73,9.72L3.74,30.3L7.1,22.73z M11.73,23.72
		c-0.3,0-0.85,0.36-0.98,0.64L4.74,37.72l31.62,0.01c0.3,0,0.85-0.36,0.98-0.64l5.93-13.38L11.73,23.72z"
    />
  );

export const LibraryIcon = (props) =>
  svg(
    props,
    <path
      d="M6.07,7.72c0-1.1,0.89-1.99,1.99-1.99h12.01c1.1,0,1.99,0.9,1.99,1.99v16.02
		c0,1.1-0.89,1.99-1.99,1.99H8.07c-1.1,0-1.99-0.9-1.99-1.99V7.72z M26.07,23.72c0-1.1,0.89-1.99,1.99-1.99h12.01
		c1.1,0,1.99,0.9,1.99,1.99v16.02c0,1.1-0.89,1.99-1.99,1.99H28.07c-1.1,0-1.99-0.9-1.99-1.99V23.72z M10.07,9.73v12h8v-12H10.07z
		 M30.07,25.73v12h8v-12H30.07z M6.07,31.74c0-1.11,0.89-2.01,1.99-2.01h12.01c1.1,0,1.99,0.9,1.99,2.01v7.98
		c0,1.11-0.89,2.01-1.99,2.01H8.07c-1.1,0-1.99-0.9-1.99-2.01V31.74z M10.07,33.73v4h8v-4H10.07z M26.07,7.74
		c0-1.11,0.89-2.01,1.99-2.01h12.01c1.1,0,1.99,0.9,1.99,2.01v7.98c0,1.11-0.89,2.01-1.99,2.01H28.07c-1.1,0-1.99-0.9-1.99-2.01
		V7.74z M30.07,9.73v4h8v-4H30.07z"
    />
  );

export const NewIcon = (props) =>
  svg(
    props,
    <path
      d="M36.01,39.78V25.79c0-1.1,0.9-2,2-2s2,0.9,2,2v13.99c0,2.21-1.79,4.01-4,4.01h-28
		c-2.21,0-4-1.79-4-4v-28c0-2.21,1.79-4,4.01-4h14.02c1.1,0,2,0.9,2,2s-0.9,2-2,2l-14.03,0l0,28L36.01,39.78z M42.57,8.06
		c1.56,1.56,1.56,4.09-0.01,5.66l-19.8,19.8l-7.45,1.87c-2.14,0.54-3.44-0.77-2.91-2.91l1.87-7.45l19.8-19.8
		c1.56-1.56,4.1-1.56,5.66-0.01L42.57,8.06z M20.71,29.92l1.36-1.36l-2.83-2.83l-1.34,1.34l-0.94,3.77L20.71,29.92z M35.5,15.13
		l-2.83-2.83l-10.6,10.61l2.83,2.83L35.5,15.13z"
    />
  );

export const CircleCheckIcon = (props) =>
  svg(
    props,
    <>
      <g>
        <path
          d="M24.01,47.99c-2.83,0-5.66-0.49-8.33-1.48c-4.82-1.78-9.02-5.14-11.82-9.45c-2.8-4.32-4.15-9.52-3.81-14.64
        c0.34-5.13,2.36-10.11,5.7-14.01c3.34-3.91,7.94-6.68,12.96-7.82c5.02-1.13,10.36-0.6,15.05,1.49c0.85,0.38,1.23,1.37,0.85,2.21
        s-1.38,1.22-2.21,0.85c-4.04-1.8-8.64-2.25-12.96-1.28c-4.32,0.97-8.27,3.36-11.15,6.72c-2.87,3.36-4.62,7.65-4.91,12.06
        s0.87,8.89,3.28,12.6c2.41,3.71,6.02,6.6,10.17,8.13c4.15,1.53,8.77,1.68,13.01,0.43c4.24-1.26,8.04-3.9,10.68-7.44
        c2.65-3.54,4.11-7.93,4.11-12.35v-2.05c0-0.93,0.75-1.67,1.67-1.67c0.93,0,1.67,0.75,1.67,1.67v2.05c0,5.14-1.7,10.24-4.78,14.36
        s-7.49,7.18-12.42,8.65C28.6,47.67,26.3,47.99,24.01,47.99z"
        />
      </g>
      <g>
        <path
          d="M16.19,36.85c-0.44,0-0.87-0.18-1.18-0.49l-6.7-6.7c-0.65-0.65-0.65-1.71,0-2.37s1.71-0.65,2.37,0l5.51,5.51
        l21.14-21.16c0.65-0.65,1.71-0.65,2.37,0c0.65,0.65,0.65,1.71,0,2.37L17.37,36.36C17.06,36.67,16.63,36.85,16.19,36.85z"
        />
      </g>
    </>
  );

export const ShowIcon = (props) =>
  svg(
    props,
    <path
      d="M40.19,33.74c-4.34,3.74-9.84,6-15.84,6c-5.99,0-11.49-2.26-15.84-6
  c-3.68-3.17-6.16-7.17-6.16-10c0-2.83,2.48-6.83,6.16-10c4.34-3.74,9.84-6,15.84-6c6,0,11.5,2.26,15.84,6
  c3.67,3.16,6.16,7.17,6.16,10C46.36,26.57,43.87,30.57,40.19,33.74z M11.13,30.7c3.65,3.14,8.25,5.03,13.23,5.03
  c4.98,0,9.58-1.89,13.23-5.03c2.88-2.48,4.77-5.53,4.77-6.97c0-1.45-1.89-4.49-4.77-6.97c-3.65-3.14-8.24-5.03-13.23-5.03
  c-4.99,0-9.58,1.89-13.23,5.03c-2.88,2.48-4.77,5.53-4.77,6.97C6.36,25.17,8.25,28.22,11.13,30.7z M32.36,23.73c0,4.42-3.58,8-8,8
  s-8-3.58-8-8s3.58-8,8-8S32.36,19.31,32.36,23.73z M24.36,19.73c-2.21,0-4,1.79-4,4s1.79,4,4,4s4-1.79,4-4h-1c-1.66,0-3-1.34-3-3
  V19.73z"
    />
  );

export const HideIcon = (props) =>
  svg(
    props,
    <path
      d="M10.21,40.7c-0.78,0.78-2.04,0.79-2.83,0c-0.78-0.78-0.78-2.05,0-2.83l2.81-2.81
  c-0.58-0.42-1.14-0.86-1.68-1.33c-3.68-3.17-6.16-7.17-6.16-10c0-2.83,2.48-6.83,6.16-10c4.34-3.74,9.84-6,15.84-6
  c3.81,0,7.41,0.91,10.65,2.53l3.5-3.5c0.78-0.78,2.04-0.79,2.83,0c0.78,0.78,0.78,2.05,0,2.83l-2.81,2.81
  c0.58,0.42,1.14,0.86,1.68,1.33c3.67,3.16,6.16,7.17,6.16,10c0,2.84-2.49,6.84-6.16,10c-4.34,3.74-9.84,6-15.84,6
  c-3.8,0-7.4-0.91-10.64-2.53L10.21,40.7z M24.36,35.73c4.98,0,9.58-1.89,13.23-5.03c2.88-2.48,4.77-5.53,4.77-6.97
  c0-1.45-1.89-4.49-4.77-6.97c-0.62-0.53-1.27-1.03-1.94-1.49l-5.88,5.88c0.37,0.78,0.58,1.66,0.58,2.59c0,3.31-2.69,6-6,6
  c-0.93,0-1.8-0.21-2.59-0.58l-5.04,5.04C19.1,35.18,21.67,35.73,24.36,35.73z M18.94,26.32c-0.37-0.78-0.58-1.66-0.58-2.59
  c0-3.31,2.69-6,6-6c0.93,0,1.8,0.21,2.59,0.58l5.04-5.04c-2.37-0.99-4.94-1.54-7.63-1.54c-4.99,0-9.58,1.89-13.23,5.03
  c-2.88,2.48-4.77,5.53-4.77,6.97c0,1.44,1.89,4.48,4.77,6.97c0.62,0.53,1.27,1.03,1.94,1.49L18.94,26.32z"
    />
  );

export const CardIcon = (props) =>
  svg(
    props,
    <path
      d="M42.36,11.73h-36v4h36V11.73z M42.36,21.73h-36v14h36V21.73z M2.36,11.74
  c0-2.21,1.79-4,4-4h36c2.21,0,4,1.79,4,4v23.99c0,2.21-1.79,4-4,4h-36c-2.21,0-4-1.79-4-4V11.74z M30.36,27.73c0-1.1,0.89-2,2-2h4
  c1.1,0,2,0.89,2,2c0,1.1-0.89,2-2,2h-4C31.25,29.73,30.36,28.85,30.36,27.73z"
    />
  );

export const CalendarIcon = (props) =>
  svg(
    props,
    <path
      d="M40.36,12.73c0-1.11-0.89-2-1.99-2c-0.01,1.1-0.9,2-2.01,2c-1.1,0-2-0.89-2-2l-4,0
  c0,1.1-0.89,2-2,2c-1.1,0-2-0.89-2-2l-4,0c0,1.1-0.89,2-2,2c-1.1,0-2-0.89-2-2l-4,0c0,1.1-0.89,2-2,2c-1.1,0-2-0.89-2-2
  c-1.11,0-2,0.89-2,2v4h32V12.73z M8.36,20.73v18c0,1.11,0.89,2,1.99,2h28.02c1.1,0,1.99-0.89,1.99-2v-18H8.36z M44.36,12.73v26
  c0,3.31-2.68,6-5.99,6H10.35c-3.31,0-5.99-2.69-5.99-6v-26c0-3.31,2.68-6,5.99-6l0.01-2c0-1.1,0.89-2,2-2c1.1,0,2,0.89,2,2v2h4v-2
  c0-1.1,0.89-2,2-2c1.1,0,2,0.89,2,2v2h4v-2c0-1.1,0.89-2,2-2c1.1,0,2,0.89,2,2v2h4v-2c0-1.1,0.89-2,2-2c1.1,0,2,0.89,2,2v2
  C41.67,6.73,44.36,9.42,44.36,12.73z"
    />
  );

export const LockIcon = (props) =>
  svg(
    props,
    <path
      d="M20.36,30.73c0-2.21,1.79-4,4-4s4,1.79,4,4c0,1.48-0.8,2.77-2,3.46v1.54c0,1.1-0.89,2-2,2
  c-1.1,0-2-0.89-2-2V34.2C21.16,33.51,20.36,32.21,20.36,30.73z M12.36,15.73c0-6.63,5.37-12,12-12c6.63,0,12,5.38,12,12v5
  c2.21,0,4,1.79,4,4.01v14.99c0,2.21-1.79,4.01-4,4.01H12.36c-2.21,0-4-1.79-4-4.01V24.74c0-2.21,1.79-4,4-4.01V15.73z M16.36,20.73
  h16v-5c0-4.42-3.58-8-8-8c-4.42,0-8,3.58-8,8V20.73z M12.36,24.73v15h24v-15H12.36z"
    />
  );
