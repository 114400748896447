import { Box, Flex, Paragraph, Button, Image, Icon, Radio } from '../atoms';

const FolderRow = (props) => {
  const { folder = {}, onPress, isPlaceholder } = props;

  const { name, path_display: path } = folder;

  const handlePress = () => {
    onPress(path);
  };

  return (
    <Flex
      as="li"
      sx={{ justifyContent: 'space-between', borderBottom: '1px solid', borderColor: 'grayLight', width: '100%' }}
    >
      {!isPlaceholder ? (
        <Flex sx={{ alignItems: 'center', width: '100%' }}>
          <Radio value={path} aria-label={path} />
          <Button onPress={handlePress} variant="listItem">
            <Flex sx={{ textAlign: 'left', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
              <Image src="/static/folder.png" alt="" sx={{ width: 48, mr: 2, flexShrink: 0 }} />
              <Box sx={{ pr: 1 }}>
                <Paragraph variant="truncated" sx={{ fontSize: 3 }}>
                  {name}
                </Paragraph>
              </Box>
              <Flex sx={{ ml: 'auto', alignItems: 'center', flexShrink: 0 }}>
                <Icon variant="Common06" color="grayMedium" />
              </Flex>
            </Flex>
          </Button>
        </Flex>
      ) : (
        <Flex sx={{ py: 3, alignItems: 'center' }}>
          <Box variant="boxes.placeholder" sx={{ height: '24px', width: '24px', mr: 2 }} />
          <Box variant="boxes.placeholder" sx={{ height: '48px', width: '48px', mr: 2 }} />
          <Box variant="boxes.placeholder" sx={{ height: '30px', width: '200px' }} />
        </Flex>
      )}
    </Flex>
  );
};

export default FolderRow;
