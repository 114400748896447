import React from 'react';
import ReactDOM from 'react-dom';
import ReactModal from 'react-modal';
import IconButton from './IconButton';
import { CloseIcon } from '../../Icons';
import { getScrollbarWidth } from '../../../utils/utils';

ReactModal.setAppElement('body');

class Modal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      scrollbar: null,
    };

    this.handleHashChange = this.handleHashChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.getScrollbarSize = this.getScrollbarSize.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);
    this.scrollToBottom = this.scrollToBottom.bind(this);
    this.timeout = null;
    this.closeTime = 500;
    this.container = null;
  }

  componentDidMount() {
    const { id, isOpen } = this.props;
    if (isOpen) {
      this.setState({ open: isOpen });
    }
    this.getScrollbarSize();
  }

  componentDidUpdate(prevProps) {
    const { id, isOpen, scrollToBottom } = this.props;

    if (id && isOpen && !prevProps.isOpen) {
      window.location.hash = id;
      window.addEventListener('hashchange', this.handleHashChange);
    }

    if (scrollToBottom && scrollToBottom !== prevProps.scrollToBottom) {
      this.scrollToBottom();
    }
  }

  componentWillUnmount() {
    const { id } = this.props;

    if (id) {
      window.history.pushState('', document.title, window.location.pathname + window.location.search);
      window.removeEventListener('hashchange', this.handleHashChange);
    }

    clearTimeout(this.timeout);
  }

  handleHashChange() {
    if (!window.location.hash) {
      this.closeModal();
    }
  }

  closeModal() {
    const { id, canClose = true, onClose, beforeClose } = this.props;
    if (!canClose) {
      return;
    }

    if (!beforeClose) {
      this.closeModalFinally();
    } else {
      beforeClose();
    }
  }

  closeModalFinally = () => {
    const { id, canClose = true, onClose, beforeClose } = this.props;

    if (typeof onClose === 'function') {
      onClose();
    }

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      // Remove padding from body
      document.body.style.paddingRight = null;
    }, this.closeTime);

    if (id) {
      window.history.pushState('', document.title, window.location.pathname + window.location.search);
    }
  };

  getScrollbarSize() {
    // Add padding to body to account for scrollbar size
    const scrollbarWidth = getScrollbarWidth();
    document.body.style.paddingRight = `${scrollbarWidth}px`;
  }

  scrollToTop() {
    if (this.container) {
      this.container.scrollTop = 0;
    }
  }

  scrollToBottom() {
    setTimeout(() => {
      if (this.container) {
        this.container.scrollTop = this.container.scrollHeight;
      }
    }, 1000);
  }

  handleOverlayRef = (el) => {
    this.container = el;
    if (this.props.handleOverlayRef) {
      this.props.handleOverlayRef(el);
    }
  };

  handleContentRef = (el) => {
    this.content = el;
    if (this.props.handleContentRef) {
      this.props.handleContentRef(el);
    }
  };

  render() {
    const { title, children, size, variant, centered, canClose, beforeClose } = this.props;

    const classNames = ['modal'];
    if (size) {
      classNames.push(`modal--${size}`);
    }
    if (variant) {
      classNames.push(`modal--${variant}`);
    }
    if (centered) {
      classNames.push('modal--centered');
    }

    return (
      <ReactModal
        overlayRef={this.handleOverlayRef}
        contentRef={this.handleContentRef}
        {...this.props}
        portalClassName={classNames.join(' ')}
        overlayClassName="modal__overlay"
        className="modal__content"
        bodyOpenClassName="modal-open"
        htmlOpenClassName="modal-open"
        closeTimeoutMS={this.closeTime}
        onRequestClose={this.closeModal}
        onAfterOpen={this.scrollToTop}
      >
        {(this.props.onClose || this.props.showClose) && (
          <IconButton alt="Close" className="modal__close" onClick={this.closeModal}>
            <CloseIcon />
          </IconButton>
        )}

        {title && <h1 className="modal__title">{title}</h1>}

        {children}
      </ReactModal>
    );
  }
}

export default Modal;
