import { forwardRef } from 'react';
import { Input as ThemeUIInput } from 'theme-ui';

const Input = forwardRef((props, ref) => (
  <ThemeUIInput {...props} ref={ref}>
    {props.children}
  </ThemeUIInput>
));

export default Input;
