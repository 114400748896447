import React from 'react';
import { SpinnerIcon } from '../Icons';

const Loading = (props) => {
  const classNames = ['loading'];
  if (props.centered) {
    classNames.push('loading--centered');
  }
  return (
    <div className={classNames.join(' ')}>
      <SpinnerIcon />
    </div>
  );
};

export default Loading;
