import { pick } from 'lodash';

export const templateIsDoubled = (template) => {
  return template.layout && template.layout.printSize === 'size2x6';
};

export const transformTemplateConfig = (config) => {
  /* eslint-disable no-param-reassign */
  const newConfig = ['type', 'width', 'height', 'images', 'texts', 'placeholders', 'layout'].reduce((result, prop) => {
    if (config[prop]) {
      result[prop] = config[prop];
    }
    return result;
  }, Object.create(null));
  /* eslint-enable no-param-reassign */
  newConfig.images = newConfig.images
    ? newConfig.images.map((shape) => {
        const newShape = pick(shape, ['height', 'width', 'xOrigin', 'yOrigin', 'rotationAngle', 'src', 'zIndex', 'id']);
        // Round values
        ['height', 'width', 'xOrigin', 'yOrigin'].forEach((prop) => {
          newShape[prop] = Math.ceil(newShape[prop]);
        });
        if (!newShape.rotationAngle) {
          newShape.rotationAngle = 0;
        }
        return newShape;
      })
    : [];
  newConfig.texts = newConfig.texts
    ? newConfig.texts
        // Filter out shape layers with no text
        .filter((shape) => !!shape.text.length)
        .map((shape) => {
          const newShape = pick(shape, [
            'height',
            'width',
            'xOrigin',
            'yOrigin',
            'rotationAngle',
            'zIndex',
            'id',
            'bold',
            'italic',
            'align',
            'color',
            'text',
            'size',
            'font',
            'transform',
          ]);
          // Round values
          ['height', 'width', 'xOrigin', 'yOrigin', 'size'].forEach((prop) => {
            newShape[prop] = Math.ceil(newShape[prop]);
          });
          if (!shape.transform) {
            newShape.transform = [1, 0, 0, 1, Math.ceil(shape.xOrigin), Math.ceil(shape.yOrigin)];
          }
          newShape.xOrigin = 0;
          newShape.yOrigin = 0;
          if (!newShape.rotationAngle) {
            newShape.rotationAngle = 0;
          }

          return newShape;
        })
    : [];

  return newConfig;
};
