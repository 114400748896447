// ===================================================== //
// MULTI SELECT DROPDOWN                                 //
// ----------------------------------------------------- //
// Select multiple checkboxes, categorized and displayed //
// in columns                                            //
// ===================================================== //

import { useState, useEffect } from 'react';
import Ink from 'react-ink';
import onClickOutside from 'react-onclickoutside';
import Checkbox from './Checkbox';
import { PlusIcon } from '../../Icons';

// Component
function MultiSelectDropdown(props) {
  const { items = [], selected = [] } = props;

  // State
  const [open, setOpen] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedNames, setselectedNames] = useState([]);

  // Set initial selected names
  useEffect(() => {
    const names = [];
    items.forEach((item) => {
      if (item.tags) {
        item.tags.forEach((tag) => {
          if (selected.includes(tag.id)) {
            names.push(tag.name);
          }
        });
      }
    });
    setselectedNames(names);
  }, []);

  // Show or hide the dropdown
  const toggleMenu = () => {
    setOpen((curr) => !curr);
  };

  // Handle checkboxes
  const handleCheck = (item, checked) => {
    setSelectAll(false);
    if (checked) {
      setselectedNames((curr) => {
        curr.push(item.name);
        return curr;
      });
    } else {
      setselectedNames((curr) => curr.filter((s) => s !== item.name));
    }
    if (props.onSelect) {
      props.onSelect(item.id, checked);
    }
  };

  // Handle select all
  const handleSelectAll = (checked) => {
    setSelectAll(checked);
    if (checked) {
      setselectedNames(() => {
        let categories = [];
        items.forEach((cat) => {
          categories = [...categories, ...cat.tags.map((t) => t.name)];
        });
        return categories;
      });
    } else {
      setselectedNames([]);
    }
    if (props.onSelectAll) {
      props.onSelectAll(checked);
    }
  };

  MultiSelectDropdown.handleClickOutside = () => {
    setOpen(false);
  };

  // Text for menu toggle
  let inputText = 'Select items...';
  if (selectedNames.length) {
    inputText = selectedNames.slice(0, 2).join(', ');
    if (selectedNames.length > 2) {
      inputText += `, and ${selectedNames.length - 2} more`;
    }
  }

  return (
    <div
      className={[
        'multi-select',
        !selectedNames.length ? 'multi-select--empty' : null,
        open ? 'multi-select--open' : null,
      ].join(' ')}
    >
      <button className="multi-select__toggle" onClick={toggleMenu}>
        {inputText}
        <PlusIcon />
        <Ink />
      </button>

      <div className="multi-select__menu">
        <Checkbox
          label="Select all"
          id="select-all-assets"
          onCheck={(checked) => handleSelectAll(checked)}
          checked={selectAll}
        />
        <div className="multi-select__cols" style={{ opacity: selectAll ? 0.5 : 1 }}>
          {items.map((cat) => (
            <div key={cat.type} className="multi-select__col">
              <strong className="multi-select__title">{cat.type}</strong>
              <ul className="multi-select__list">
                {cat.tags.map((tag) => (
                  <li key={tag.id} className="multi-select__opt">
                    <Checkbox
                      label={tag.name}
                      id={tag.id}
                      onCheck={(checked) => handleCheck(tag, checked)}
                      checked={selected.includes(tag.id)}
                    />
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

const clickOutsideConfig = {
  handleClickOutside: () => MultiSelectDropdown.handleClickOutside,
};

export default onClickOutside(MultiSelectDropdown, clickOutsideConfig);
