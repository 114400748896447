import React, { Component } from 'react';

class ErrorPage extends Component {
  render() {
    const { children, heading, autoHeight } = this.props;

    return (
      <div className={`error-page ${autoHeight ? 'error-page--autoheight' : ''}`}>
        <div>
          <img className="error-page__image" src="/static/frame.svg" alt="" />
          {heading && <h1 className="error-page__heading">{heading}</h1>}
          {children}
        </div>
      </div>
    );
  }
}

export default ErrorPage;
