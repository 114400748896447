import { forwardRef } from 'react';
import { Card as ThemeUICard } from 'theme-ui';

const Card = forwardRef((props, ref) => (
  <ThemeUICard ref={ref} variant="primary" {...props}>
    {props.children}
  </ThemeUICard>
));

export default Card;
