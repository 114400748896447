import _ from 'lodash';
import { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useStrictMode, Stage, Layer, Image, Text, Rect, Circle, Path, Group, Transformer } from 'react-konva';
import Konva from 'konva';
import useImage from 'use-image';
import { decomposeMatrix } from '../../../utils/transform';

useStrictMode(false);

const TemplateEditorCanvasShape = (props) => {
  const {
    shapeProps,
    category,
    index,
    stageRef,
    stageSize,
    isSelected,
    onSelect,
    onChange,
    onDelete,
    onSelectText,
    templateSize,
  } = props;

  const shapeRef = useRef();
  const trRef = useRef();
  const textarea = useRef();
  const deleteButtonRef = useRef();
  const deleteIconRef = useRef();
  const rotateAnchorRef = useRef();
  const rotateIconRef = useRef();
  const mountTime = useRef(Date.now());

  const [editingText, setEditingText] = useState(false);
  const [draggedOffCanvas, setDraggedOffCanvas] = useState(false);
  const [scaleData, setScaleData] = useState(null);
  const [shapeMounted, setShapeMounted] = useState(false);

  // Load sticker URL and return an image
  const [image] = category === 'images' ? useImage(shapeProps.src, 'Anonymous') : [null];

  // Update positioning of custom shapes on various events
  const updateKonvaShapes = () => {
    const shapeNode = trRef.current;
    if (!shapeNode) {
      return;
    }

    const anchorNode = rotateAnchorRef.current;
    const anchorIconNode = rotateIconRef.current;
    const width = shapeNode.getWidth();
    const height = shapeNode.getHeight();
    const rotation = shapeNode.getRotation();

    if (anchorNode && anchorIconNode) {
      [anchorNode, anchorIconNode].forEach((n) => {
        n.x(width / 2);
        n.y(-36);
      });
      anchorIconNode.rotation(-rotation);
    }
  };

  const setTextareaWidth = (w) => {
    const textNode = shapeRef.current;
    let newWidth = w || textNode.placeholder.length * textNode.fontSize();

    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    const isEdge = document.documentMode || /Edge/.test(navigator.userAgent);

    if (isSafari || isFirefox) {
      newWidth = Math.ceil(newWidth);
    }

    if (isEdge) {
      newWidth += 1;
    }

    textarea.current.style.width = `${newWidth}px`;
  };

  const handleOutsideClick = (e) => {
    if (!textarea.current) {
      return;
    }
    if (e.target !== textarea.current && !textarea.current.contains(e.target)) {
      setEditingText(false);
    }
  };

  const removeTextarea = () => {
    window.removeEventListener('mousedown', handleOutsideClick);
    if (!textarea.current) {
      return;
    }
    const textNode = shapeRef.current;
    const stageNode = stageRef.current;
    if (!stageNode) {
      return;
    }
    const stageContainer = stageNode.container();
    // Remove from DOM, unmount listener and reset ref
    stageContainer.removeChild(textarea.current);
    textarea.current = null;
    // Show real text node
    textNode.show();
    textNode.getLayer().batchDraw();
  };

  const handleTextSelect = () => {
    if (category !== 'texts') {
      return;
    }

    const textNode = shapeRef.current;
    const stageNode = stageRef.current;
    const stageContainer = stageNode.container();

    // Hide real text node
    textNode.hide();
    textNode.getLayer().batchDraw();
    const areaPosition = textNode.getAbsolutePosition();
    const scale = textNode.getAbsoluteScale();
    const fontStyle = textNode.fontStyle().split(' ');
    // Create textarea element and add to DOM
    textarea.current = document.createElement('textarea');
    stageContainer.appendChild(textarea.current);
    // Set textarea position and other styles
    textarea.current.rows = '1';
    textarea.current.value = textNode.text();
    textarea.current.style.position = 'absolute';
    textarea.current.style.zIndex = 1000;
    textarea.current.style.top = `${areaPosition.y}px`;
    textarea.current.style.left = `${areaPosition.x}px`;

    const [sdX, sdY] = [1, 1];

    textarea.current.style.width = `${(textNode.width() / sdX) * scale.x}px`;
    textarea.current.style.height = `${(textNode.height() / sdY) * scale.y}px`;
    textarea.current.style.fontSize = `${(textNode.fontSize() / sdY) * scale.y}px`;
    textarea.current.style.border = 'none';
    textarea.current.style.padding = '0px';
    textarea.current.style.margin = '0px';
    textarea.current.style.overflow = 'hidden';
    textarea.current.style.background = 'none';
    textarea.current.style.outline = 'none';
    textarea.current.style.resize = 'none';
    textarea.current.style.lineHeight = textNode.lineHeight();
    textarea.current.style.fontFamily = textNode.fontFamily();
    textarea.current.style.transformOrigin = 'left top';
    textarea.current.style.textAlign = textNode.align();
    textarea.current.style.color = textNode.fill();
    textarea.current.style.transform = `rotateZ(${textNode.rotation()}deg)`;
    textarea.current.style.transform += ` scale(${sdX},${sdY})`;

    // Focus textarea
    textarea.current.focus();

    textarea.current.addEventListener('input', (e) => {
      const scale = textNode.getAbsoluteScale().x;
      setTextareaWidth((textNode.width() / sdX) * scale);
      textarea.current.style.height = 'auto';
      textarea.current.style.height = `${textarea.current.scrollHeight}px`;
      textNode.setAttrs({ height: ((textarea.current.clientHeight + 2) * sdX) / scale });
    });

    setTimeout(() => {
      window.addEventListener('mousedown', handleOutsideClick);
    });
  };

  const handleShapeSelect = () => {
    onSelect();
    if (category === 'texts') {
      setEditingText(true);
      handleTextSelect();
    }
  };

  const handleDragMove = (e) => {
    const x = e.target.x();
    const y = e.target.y();
    const width = e.target.getWidth();
    const height = e.target.getHeight();
    if (x < -width || y < -height || x > templateSize.width || y > templateSize.height) {
      if (!draggedOffCanvas) {
        setDraggedOffCanvas(true);
      }
    } else if (draggedOffCanvas) {
      setDraggedOffCanvas(false);
    }
    if (![0, 90, 180, 360].includes(e.target.getRotation())) {
      return;
    }
    // Snap to edges
    const threshold = 10;
    const widthDiff = templateSize.width - width;
    const heightDiff = templateSize.height - height;
    // Left edge
    if ((x < 0 && x >= -threshold) || (x > 0 && x <= threshold)) {
      e.target.x(0);
    }
    // Top edge
    if ((y < 0 && y >= -threshold) || (y > 0 && y <= threshold)) {
      e.target.y(0);
    }
    // Right edge
    if ((x < widthDiff && x >= widthDiff - threshold) || (x > widthDiff && x <= widthDiff + threshold)) {
      e.target.x(templateSize.width - width);
    }
    // Bottom edge
    if ((y < heightDiff && y >= heightDiff - threshold) || (y > heightDiff && y <= heightDiff + threshold)) {
      e.target.y(templateSize.height - height);
    }
  };

  const handleDragEnd = (e) => {
    updateKonvaShapes();
    const x = e.target.x();
    const y = e.target.y();
    const width = e.target.getWidth();
    const height = e.target.getHeight();
    // Delete if dragged off canvas
    if (x < -width || y < -height || x > templateSize.width || y > templateSize.height) {
      onDelete();
      return;
    }
    onChange({ ...shapeProps, xOrigin: x, yOrigin: y });
  };

  // Attach Konva transformer on select
  useEffect(() => {
    updateKonvaShapes();
    if (isSelected) {
      const layer = trRef.current.getLayer();
      trRef.current.nodes([shapeRef.current]);
      trRef.current.zIndex(1000);

      [...trRef.current.children].forEach((child, i) => {
        child.shadowColor('black');
        child.shadowBlur(3);
        child.shadowOffset({ x: 0, y: 1 });
        child.shadowOpacity(i > 0 ? 0.3 : 0.6);
      });

      layer.batchDraw();
    }
    updateKonvaShapes();
  }, [isSelected]);

  // Bring transformer to top when zIndex changes
  useEffect(() => {
    if (isSelected) {
      trRef.current.zIndex(10);
    }
  }, [shapeProps.zIndex]);

  useEffect(() => {
    if (!shapeMounted && shapeProps.category === 'texts') {
      const shapeNode = shapeRef.current;
      if (!shapeNode) {
        return;
      }
      setShapeMounted(true);

      const { xOrigin, yOrigin, rotationAngle, transform, height, width } = shapeProps;
      const tf = shapeNode.getTransform();
      let dec;

      let nodeHeight = height;
      let nodeWidth = width;
      if (!nodeHeight) {
        nodeHeight = shapeNode.getHeight();
      }
      if (!nodeWidth) {
        nodeWidth = shapeNode.getWidth();
      }

      if (_.isEmpty(transform)) {
        // dec = tf.decompose();
        onChange(
          {
            ...shapeProps,
            xOrigin: 0,
            yOrigin: 0,
            transform: tf.getMatrix(),
            height: nodeHeight,
            width: nodeWidth,
            // size: shapeProps.fontSize * dec.scaleX
          },
          false
        );
      } else {
        tf.m = transform;
        // tf.translate(xOrigin, yOrigin);
        dec = tf.decompose();
        shapeNode.setAttrs(dec);
        onChange(
          {
            xOrigin: dec.x,
            yOrigin: dec.y,
            // width: Math.ceil(nodeWidth+2)
            // size: shapeProps.fontSize * dec.scaleX
          },
          false
        );
      }
    }
  });

  useEffect(() => {
    updateKonvaShapes();
    if (category !== 'texts') {
      return;
    }
    const textNode = shapeRef.current;
    const stageNode = stageRef.current;
    const stageContainer = stageNode.container();
    const trNode = trRef.current;
    if (!editingText) {
      if (!textarea.current) {
        return;
      }
      if (textarea.current.value !== shapeProps.text) {
        onChange({
          ...shapeProps,
          text: textarea.current.value,
          xOrigin: textNode.x(),
          yOrigin: textNode.y(),
          width: textNode.width(),
          height: Math.ceil(textNode.height()),
          rotationAngle: textNode.getRotation(),
          size: textNode.fontSize(),
          transform: textNode.getTransform().getMatrix(),
        });
      }
      removeTextarea();
    }
  }, [editingText]);

  return (
    <>
      {category === 'images' ? (
        <Image
          image={image}
          onClick={() => handleShapeSelect()}
          onTap={() => handleShapeSelect()}
          ref={shapeRef}
          {...shapeProps}
          // listening={!!shapeProps.editable}
          zIndex={null}
          x={shapeProps.xOrigin}
          y={shapeProps.yOrigin}
          rotation={shapeProps.rotationAngle}
          opacity={draggedOffCanvas ? 0.25 : 1}
          draggable
          onDragMove={handleDragMove}
          onDragEnd={handleDragEnd}
          onTransform={updateKonvaShapes}
          onTransformEnd={(e) => {
            // Get scale value to determine new width/height for state
            const node = shapeRef.current;
            if (!node) {
              return;
            }
            const scaleX = node.scaleX();
            const scaleY = node.scaleY();
            // Set scale back to 1
            node.scaleX(1);
            node.scaleY(1);
            onChange({
              ...shapeProps,
              xOrigin: node.x(),
              yOrigin: node.y(),
              // Minimum size
              width: node.width() * scaleX,
              height: node.height() * scaleY,
              rotationAngle: node.getRotation(),
            });
            updateKonvaShapes();
          }}
        />
      ) : category === 'texts' ? (
        <Text
          onClick={() => handleShapeSelect()}
          onTap={() => handleShapeSelect()}
          ref={shapeRef}
          zIndex={null}
          x={shapeProps.xOrigin}
          y={shapeProps.yOrigin}
          width={shapeProps.width}
          height={shapeProps.height}
          text={shapeProps.text}
          align={shapeProps.align || 'left'}
          fill={`rgba(${(shapeProps.color || [0, 0, 0, 1]).join(',')})`}
          fontFamily={`"${shapeProps.font}"` || 'Arial'}
          fontSize={shapeProps.size || 30}
          rotation={shapeProps.rotationAngle}
          opacity={draggedOffCanvas ? 0.25 : 1}
          draggable
          onDragMove={handleDragMove}
          onDragEnd={handleDragEnd}
          onTransform={(e) => {
            const node = shapeRef.current;
            const trNode = trRef.current;
            if (!node || !trNode) {
              return;
            }

            const scaleX = node.scaleX();
            const scaleY = node.scaleY();
            const nodeWidth = node.width();
            const nodeHeight = node.height();
            const areaPosition = node.getAbsolutePosition();
            const nodeScale = node.getAbsoluteScale();

            if (textarea.current) {
              const rotation = node.getRotation();
              let transform = '';
              if (rotation) {
                transform += `rotateZ(${rotation}deg) `;
              }
              if (scaleData) {
                const ratio = 1; // scaleData[0] / scaleData[1];
                transform += `scale(${ratio}, 1)`;
              }
              setTextareaWidth(nodeWidth * nodeScale.x);
              textarea.current.style.height = 'auto';
              textarea.current.style.height = `${textarea.current.scrollHeight}px`;
              textarea.current.style.top = `${areaPosition.y}px`;
              textarea.current.style.left = `${areaPosition.x}px`;
              textarea.current.style.transform = transform;
            }

            switch (trNode.getActiveAnchor()) {
              case 'middle-left':
              case 'middle-right':
                if (scaleData) {
                  node.setAttrs({
                    width: (nodeWidth / scaleData[0]) * scaleX,
                    height: null,
                    scaleX: scaleData[0],
                    scaleY: scaleData[1],
                    fontSize: (node.fontSize() / scaleData[1]) * scaleY,
                  });
                } else {
                  node.setAttrs({
                    width: nodeWidth * scaleX,
                    height: null,
                    scaleX: 1,
                    scaleY: 1,
                    fontSize: node.fontSize() * scaleY,
                  });
                }
                break;
              default:
                if (textarea.current) {
                  textarea.current.style.fontSize = `${node.fontSize() * nodeScale.x}px`;
                }
                if (scaleData) {
                  node.setAttrs({
                    width: (nodeWidth / scaleData[0]) * scaleX,
                    height: (nodeHeight / scaleData[1]) * scaleY,
                    scaleX: scaleData[0],
                    scaleY: scaleData[1],
                    fontSize: (node.fontSize() / scaleData[1]) * scaleY,
                  });
                } else {
                  node.setAttrs({
                    width: nodeWidth * scaleX,
                    height: nodeHeight * scaleY,
                    scaleX: 1,
                    scaleY: 1,
                    fontSize: node.fontSize() * scaleY,
                  });
                }
                break;
            }
            updateKonvaShapes();
          }}
          onTransformEnd={(e) => {
            const node = shapeRef.current;
            const scaleX = node.scaleX();
            const scaleY = node.scaleY();
            onChange({
              ...shapeProps,
              xOrigin: node.x(),
              yOrigin: node.y(),
              // Minimum size
              width: node.width(),
              height: node.height() + 1,
              rotationAngle: node.getRotation(),
              size: node.fontSize(),
              // font: node.fontFamily(),
              transform: node.getTransform().getMatrix(),
              modified: true,
            });
            updateKonvaShapes();
          }}
        />
      ) : category === 'placeholders' ? (
        <Rect
          {...shapeProps}
          zIndex={null}
          x={shapeProps.xOrigin}
          y={shapeProps.yOrigin}
          fill="#333333"
          listening={false}
        />
      ) : null}
      {isSelected && (
        <>
          <Transformer
            ref={trRef}
            keepRatio={true}
            borderStroke="#ffffff"
            rotateAnchorOffset={36}
            enabledAnchors={[
              ...['top-left', 'top-right', 'bottom-left', 'bottom-right'],
              ...(category === 'texts' ? ['middle-left', 'middle-right'] : [null]),
            ].filter((a) => a)}
            anchorSize={16}
            anchorCornerRadius={16}
            anchorStroke="#ffffff"
            // padding={18}
            boundBoxFunc={(oldBox, newBox) => {
              const { width, height, rotation } = newBox;
              // Limit resize
              if (width < 5 || height < 5) {
                return oldBox;
              }
              // Match auto textarea height
              const trNode = trRef.current;
              const anchor = trNode.getActiveAnchor();
              if (category === 'texts' && textarea.current && (anchor === 'middle-left' || anchor === 'middle-right')) {
                return { ...newBox, height: textarea.current.clientHeight };
              }
              return newBox;
            }}
          >
            {/* <Circle
              ref={rotateAnchorRef}
              fill="#ffffff"
              borderStroke="#B0BEC5"
              borderSize={1}
              radius={16}
              listening={false}
              x={shapeProps.width/2}
              y={0}
            />
            <Path
              ref={rotateIconRef}
              data="M32.37,19.69c-1.11,0-2.01-0.9-2.01-2c0-1.11,0.9-2,2.01-2h3.36c-3.05-4.07-6.7-6-11.37-6
		c-7.73,0-14,6.27-14,14s6.27,14,14,14c7.08,0,13.02-5.29,13.89-12.25c0.14-1.1,1.14-1.87,2.23-1.74c1.1,0.14,1.87,1.14,1.74,2.23
		c-1.12,8.96-8.76,15.75-17.86,15.75c-9.94,0-18-8.06-18-18s8.06-18,18-18c5.72,0,10.31,2.32,14,6.87V9.7c0-1.11,0.9-2.01,2-2.01
		c1.11,0,2,0.9,2,2.01c0,0,0,7.99,0,7.99c0,0.55-0.22,1.05-0.59,1.41c-0.36,0.36-0.86,0.59-1.42,0.59H32.37z"
              fill="#37474F"
              listening={false}
              x={shapeProps.width/2}
              y={0}
              scaleX={0.4}
              scaleY={0.4}
              offsetX={24}
              offsetY={24}
              rotation={-shapeProps.rotationAngle}
            /> */}
          </Transformer>
        </>
      )}
    </>
  );
};

export default TemplateEditorCanvasShape;
