import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import withIntersectionObserver from '../../../utils/withIntersectionObserver';
import { Button } from '../atoms';
import { getVideoFirstFrameSrc } from '../../../utils/video';

const UploadModalItem = (props) => {
  const { id, type, thumbnail, onClick, isVisible } = props;

  const video = useRef();

  // Track video .play() promise
  const [playPromise, setPlayPromise] = useState(false);

  // Pause the active video
  const pauseVideo = () => {
    if (!video.current) {
      return;
    }
    video.current.pause();
    video.current.currentTime = 0;
  };

  // Destroy the active video
  const destroyVideo = () => {
    if (!video.current) {
      return;
    }
    video.current.pause();
    video.current.removeAttribute('src');
    video.current.load();
  };

  // Play the video when hovered
  const handleMouseOver = (e) => {
    if (!video.current) {
      return;
    }
    setPlayPromise(
      video.current
        .play()
        .then(() => {
          setPlayPromise(false);
        })
        .catch((err) => {})
    );
  };

  // Pause and return to start when no longer hovered
  const handleMouseOut = (e) => {
    if (playPromise) {
      playPromise.then(() => {
        pauseVideo();
      });
    } else {
      pauseVideo();
    }
  };

  // Attach event listeners on mount
  useEffect(() => {
    if (video.current) {
      video.current.addEventListener('mouseover', handleMouseOver);
      video.current.addEventListener('mouseout', handleMouseOut);
    }

    // Remove listeners on unmount
    return () => {
      if (video.current) {
        video.current.removeEventListener('mouseover', handleMouseOver);
        video.current.removeEventListener('mouseout', handleMouseOut);
      }
    };
  }, []);

  useEffect(() => {
    if (!video.current) {
      return;
    }
    if (isVisible) {
      // Reset src
      video.current.setAttribute('src', getVideoFirstFrameSrc(thumbnail));
    } else if (playPromise) {
      playPromise.then(() => {
        destroyVideo();
      });
    } else {
      destroyVideo();
    }
  }, [isVisible]);

  const style = { height: '200px' };
  let media;
  switch (type) {
    case 'photo':
      media = <img src={thumbnail} alt={id} style={style} />;
      break;
    case 'video':
      media = <video ref={video} playsInline muted loop src={getVideoFirstFrameSrc(thumbnail)} style={style} />;
      break;
    default:
      return null;
  }

  return (
    <Button variant="bare" onPress={onClick}>
      {media}
    </Button>
  );
};

export default withIntersectionObserver(0)(UploadModalItem);
