import React, { Component } from 'react';
import Ink from 'react-ink';
import _ from 'lodash';

class Button extends Component {
  render() {
    let isSecondary = false;
    const classNames = ['btn'];

    if (this.props.types && Array.isArray(this.props.types)) {
      this.props.types.forEach((type) => {
        classNames.push(`btn-${type}`);
        if (type === 'secondary') {
          isSecondary = true;
        }
      });
    } else if (typeof this.props.type === 'string') {
      classNames.push(`btn-${this.props.type}`);
      if (this.props.type === 'secondary') {
        isSecondary = true;
      }
    } else {
      classNames.push('btn-secondary');
      isSecondary = true;
    }
    if (this.props.className) {
      classNames.push(this.props.className);
    }

    let shadowClass = 'button-shadow';
    if (isSecondary || this.props.disabled) {
      shadowClass += ' button-shadow--flat';
    }

    if (_.get(this.props, 'style.backgroundColor') === '#FFFFFF') {
      classNames.push('gray-border');
    }

    if (this.props.href) {
      return (
        <a
          className={classNames.join(' ')}
          href={this.props.href}
          onClick={this.props.onClick}
          disabled={this.props.disabled}
          style={this.props.style}
          target={this.props.target}
        >
          {this.props.children}
          <Ink />
        </a>
      );
    }
    return (
      <button
        className={classNames.join(' ')}
        onClick={this.props.onClick}
        disabled={this.props.disabled}
        style={this.props.style}
      >
        {this.props.children}
        <Ink />
      </button>
    );
  }
}

export default Button;
