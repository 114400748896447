/** @jsxImportSource theme-ui */
import { motion } from 'framer-motion';
import { Paragraph } from 'theme-ui';

const Success = ({ children }) => (
  <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} layout>
    <Paragraph variant="successDark" mb={2} role="alert">
      {children}
    </Paragraph>
  </motion.div>
);

export default Success;
