import { useMemo, useReducer, useEffect } from 'react';
import dot from 'dot-object';
import useAppEvent from './useAppEvent';

const useAppSettings = (settings = []) => {
  // Use the active event
  const [appEvent] = useAppEvent();

  // Initial checked state for checkboxes (only updates when appEvent settings change)
  const initialSettingValues = useMemo(() => {
    return settings.reduce((allSettings, { key }) => {
      // Set checked values to values from appEvent
      allSettings[key] = dot.pick(key, appEvent);
      return allSettings;
    }, Object.create(null));
  }, [appEvent]);

  // Reducer for local setting state
  const [settingValues, updateSettingValues] = useReducer((state, action) => {
    const { type, key, value } = action;
    switch (type) {
      case 'reset':
        return initialSettingValues;
      default:
        if (!key || state[key] === undefined || value === undefined) {
          return state;
        }
        return { ...state, [key]: value };
    }
  }, initialSettingValues);

  // Reset setting state from store (this makes server-sent events work)
  useEffect(() => {
    updateSettingValues({ type: 'reset' });
  }, [appEvent]);

  return [settingValues, updateSettingValues, initialSettingValues];
};

export default useAppSettings;
