/** @jsxImportSource theme-ui */
import { Card, Icon, Button, Box } from '../atoms';

const IconCard = (props) => {
  const { iconVariant, buttonText, onButtonPress, mb } = props;

  return (
    <Card variant="secondary" p={[4, 4, 4, 6]} px={[null, 1, 1]} mb={mb} sx={{ textAlign: 'center', borderRadius: 1 }}>
      <Box mb={4}>
        <Icon variant={iconVariant} color="gray" size={[80, 80, 120]} />
      </Box>
      <Button variant="primary" onPress={onButtonPress} sx={{}}>
        {buttonText}
      </Button>
    </Card>
  );
};

export default IconCard;
