import { useState, useEffect } from 'react';
import dynamic from 'next/dynamic';
import useAppSettings from '../../../hooks/useAppSettings';
import useRestrictSettingByPlan from '../../../hooks/useRestrictSettingByPlan';
import { Flex, Button, Heading, Box, Label, Paragraph } from '../atoms';
import { ListGroup } from '../molecules';
import { SettingControl } from '.';

const Modal = dynamic(() => import('../core/Modal')); // LEGACY

const SettingCheckboxWithModal = (props) => {
  const { setting, value, onChange, modalSettings = [], modalOverride: ModalOverride, onSaveModalSettings } = props;
  const { label } = setting;

  const [isSettingAllowedByPlan] = useRestrictSettingByPlan(setting);
  if (!isSettingAllowedByPlan) {
    return null;
  }

  // Modal open state
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Modal setting values state
  const [modalSettingValues, updateModalSettingValues] = useAppSettings(modalSettings);

  // Toggle modal visibility
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  // Save modal settings
  const handleSaveModalSettings = (e) => {
    e.preventDefault();
    if (typeof onSaveModalSettings === 'function') {
      onSaveModalSettings(modalSettingValues);
    }
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (!isModalOpen) {
      updateModalSettingValues({ type: 'reset' });
    }
  }, [isModalOpen]);

  const settingHasModal = !!modalSettings.length || !!ModalOverride;

  return (
    <>
      <Flex sx={{ justifyContent: 'space-between', width: '100%', maxWidth: 510 }}>
        <Box>
          <SettingControl setting={setting} value={value} onChange={onChange} />
        </Box>
        {settingHasModal && (
          <Button variant="textPrimary" onPress={toggleModal}>
            Edit
          </Button>
        )}
      </Flex>

      {ModalOverride ? (
        <ModalOverride isOpen={isModalOpen} onClose={toggleModal} />
      ) : (
        <Modal size="small" isOpen={isModalOpen} onClose={toggleModal}>
          <Box as="form" onSubmit={handleSaveModalSettings} sx={{ textAlign: 'center' }}>
            <Heading as="h3" sx={{ fontSize: 4 }}>
              {label} Settings
            </Heading>
            <ListGroup>
              {modalSettings.map((modalSetting) => (
                <Label key={modalSetting.key} for={modalSetting.key} sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Paragraph sx={{ fontWeight: 'bold', fontSize: 1 }} mb={3}>
                    {modalSetting.label}
                  </Paragraph>
                  <SettingControl
                    key={modalSetting.key}
                    setting={modalSetting}
                    value={modalSettingValues[modalSetting.key]}
                    onChange={(value) => updateModalSettingValues({ key: modalSetting.key, value })}
                  />
                </Label>
              ))}
            </ListGroup>
            <Button type="submit" variant="primary">
              Save
            </Button>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default SettingCheckboxWithModal;
