import React, { Component } from 'react';
import _ from 'lodash';
import Ink from 'react-ink';
import Modal from './Modal';
import Button from './Button';
import FileButton from './FileButton';
import UploadModalItem from './UploadModalItem';
import Tabs from './Tabs';
import AssetGrid from '../events/AssetGrid';
import AssetTile from '../events/AssetTile';
import { UploadIcon } from '../../Icons';
import { InfiniteScrollWithStaticData } from '../organisms';
import { listAssets } from '../../../actions/actions';

class UploadModal extends Component {
  state = {
    category: null,
    showError: false,
  };

  selectAsset = (asset) => {
    const { onSelectAsset } = this.props;
    if (_.isFunction(onSelectAsset)) {
      onSelectAsset(asset);
    }
  };

  handleUpload = (e) => {
    const { uploadAsset } = this.props;
    for (let i = 0; i < e.target.files.length; i += 1) {
      const size = Math.round(e.target.files.item(i).size / 1024);
      if (size > 5126) {
        alert('Maximum upload size is 5mb.');
        return;
      }
    }
    if (_.isFunction(uploadAsset)) {
      uploadAsset(e.target.files).catch((error) => alert(error));
    }
  };

  resetAssets = () => {
    const { resetAssets, loadAssets } = this.props;
    if (_.isFunction(resetAssets) && _.isFunction(loadAssets)) {
      resetAssets();
      // loadAssets();
    }
  };

  modalOverlay = null;

  timeout = null;

  componentDidMount() {
    const { category, categoryName } = this.props;
    let categoryFormatted;

    if (categoryName) {
      categoryFormatted = categoryName;
    } else if (!category) {
      categoryFormatted = 'Asset';
    } else {
      categoryFormatted = (() => {
        switch (category) {
          case 'greenScreenBackground':
            return 'Green Screen Background';
          case 'greenScreenOverlay':
            return 'Green Screen Overlay';
          case 'startScreen':
            return 'Tap to Start Screen';
          case 'boothBackgroundImage':
            return 'Background';
          case 'disclaimerScreen':
            return 'Disclaimer';
          case 'leadSectionLogo':
            return 'Logo';
          case 'liveGalleryFeaturedImage':
            return 'Featured Image';
          case 'printTemplateOverlay':
            return 'Print Template Overlay';
          case 'printTemplateBackground':
            return 'Print Template Background';
          case 'customButton':
            return 'Custom Button';
          default:
            return category;
        }
      })();
    }

    // Filter system assets
    if (_.get(this.props, 'sysAssets.data.length')) {
      const defaultAssets = this.props.sysAssets.data.filter((a) => {
        return _.get(a, 'category', []).includes(category);
      });
      this.setState({ category: categoryFormatted, defaultAssets });
    } else {
      this.props
        .loadSysAssets()
        .then(() => {
          const defaultAssets = _.get(this.props, 'sysAssets.data', []).filter((a) => {
            return _.get(a, 'category', []).includes(category);
          });
          this.setState({ category: categoryFormatted, defaultAssets });
        })
        .catch((error) => {
          this.setState({ category: categoryFormatted });
        });
    }

    // Load saved assets immediately if initially open
    const { resetAssets, loadAssets, loadSysAssets } = this.props;
    if (_.isFunction(loadAssets)) {
      resetAssets();
      loadAssets();
    }
  }

  componentDidUpdate(prevProps) {
    // Load saved assets when modal is opened
    if (!prevProps.isOpen && this.props.isOpen) {
      const { resetAssets, loadAssets, loadSysAssets } = this.props;
      if (_.isFunction(loadAssets)) {
        resetAssets();
        loadAssets();
      }
    }

    // Reset saved assets when the list is reset
    if (
      this.props.isOpen &&
      (prevProps.userAssets.success || (prevProps.userAssets.data.length && !this.props.userAssets.data.length))
    ) {
      const { loadAssets } = this.props;
      if (_.isFunction(loadAssets)) {
        loadAssets();
      }
    }

    // Reset list after an upload finishes
    if (prevProps.userAssets.uploading && this.props.userAssets.success) {
      const { resetAssets, loadAssets } = this.props;
      if (_.isFunction(resetAssets)) {
        resetAssets();
        this.setState({ reset: true });
      }
    }

    // Load more if we're scrolled to the bottom
    if (prevProps.userAssets.loading && !this.props.userAssets.loading && this.props.userAssets.hasMore) {
      if (window.innerHeight === document.documentElement.scrollHeight) {
        // this.props.loadAssets();
      }
    }
  }

  render() {
    const { onChange, id, accept, multiple, userAssets } = this.props;

    const { category, defaultAssets = [] } = this.state;

    const defaultAssetItems = defaultAssets.map((asset) => (
      <UploadModalItem
        key={asset.id}
        id={asset.id}
        type="video"
        thumbnail={asset.thumbnailUrl}
        onClick={() => this.selectAsset(asset)}
      />
    ));

    const assetLibrary = <AssetGrid {...this.props} container={this.modalOverlay} onSelectAsset={this.selectAsset} />;

    return (
      <Modal {...this.props} overlayRef={(node) => (this.modalOverlay = node)}>
        <div className="asset-lib">
          <div className="asset-lib__header">
            <h1 className="gamma asset-lib__title">Choose a file</h1>
            <FileButton
              id={id}
              accept={accept}
              multiple={multiple}
              onChange={this.handleUpload}
              className="asset-lib__button"
              variant="primary"
              icon={<UploadIcon />}
              loading={userAssets.uploading}
              disabled={userAssets.uploading}
            >
              Upload file
            </FileButton>
          </div>
        </div>

        {defaultAssetItems.length > 0 && (
          <Tabs autoWidth={true} modal={true} padded={true}>
            <div id="your-uploads" label="Your Uploads">
              {assetLibrary}
            </div>

            <div id="library" label={`${category} Library`}>
              <InfiniteScrollWithStaticData scrollableTarget={this.modalOverlay} data={defaultAssetItems} />
            </div>
          </Tabs>
        )}

        {defaultAssetItems.length === 0 && assetLibrary}
      </Modal>
    );
  }
}

export default UploadModal;
