import { useRef } from 'react';
import { useButton } from 'react-aria';
import { Card } from '../atoms';

const ButtonCard = (props) => {
  const { onPress, children } = props;
  const ref = useRef();
  const { buttonProps } = useButton(props, ref);

  return (
    <Card
      {...buttonProps}
      ref={ref}
      as="button"
      variant="outlineButton"
      sx={{ pt: 4, pb: 3, mb: 3, borderRadius: 1, borderWidth: '1px' }}
    >
      {children}
    </Card>
  );
};

export default ButtonCard;
