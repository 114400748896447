import { useRef } from 'react';
import { IconButton as ThemeUIIconButton } from 'theme-ui';
import { useButton, useTooltipTrigger, useFocusRing, mergeProps, VisuallyHidden } from 'react-aria';
import { useTooltipTriggerState } from '@react-stately/tooltip';
import { Icon } from '../atoms';
import { Tooltip } from '.';

const IconButton = (props) => {
  const { label, variant, iconVariant, fontSize = 5, color = 'grayDark', isDisabled } = props;

  const buttonRef = useRef();
  const { buttonProps } = useButton(props, buttonRef);

  const tooltipState = useTooltipTriggerState(props);
  const { triggerProps, tooltipProps } = useTooltipTrigger(props, tooltipState, buttonRef);

  const { isFocusVisible, focusProps } = useFocusRing();

  return (
    <ThemeUIIconButton
      type="button"
      variant={variant}
      disabled={isDisabled}
      ref={buttonRef}
      sx={{
        position: 'relative',
        cursor: 'pointer',
        fontSize,
        outline: 0,
        bg: isFocusVisible ? 'gray' : 'transparent',
      }}
      {...mergeProps(buttonProps, triggerProps)}
      {...focusProps}
    >
      <Icon variant={iconVariant} isInline={true} color={color} />
      <VisuallyHidden>{label}</VisuallyHidden>

      {tooltipState.isOpen && (
        <Tooltip state={tooltipState} {...tooltipProps}>
          {label}
        </Tooltip>
      )}
    </ThemeUIIconButton>
  );
};

export default IconButton;
