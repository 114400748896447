import React, { Component } from 'react';
// import 'intersection-observer';
import { InView, defaultFallbackInView } from 'react-intersection-observer';

// Fallback for browsers that don't support IntersectionObserver
// https://github.com/thebuilder/react-intersection-observer#unsupported-fallback
defaultFallbackInView(true);

export default (threshold) => (BaseComponent) => {
  const displayName = BaseComponent.displayName || BaseComponent.name || 'Component';

  return class WithIntersectionObserver extends Component {
    static displayName = `withIntersectionObserver(${displayName})`;

    state = {
      isIntersecting: false,
    };

    handleChange = (inView, entry) => {
      this.setState({ isIntersecting: inView });
    };

    render() {
      return (
        <InView as="div" onChange={this.handleChange} threshold={threshold}>
          <BaseComponent {...this.props} isVisible={this.state.isIntersecting} />
        </InView>
      );
    }
  };
};
