import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '../core/Button';
import { logEvent } from '../../utils/analytics';

class CtaButton extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { eventId, mediaId, context } = this.props;

    logEvent('event', 'cta_button', eventId);
  }

  render() {
    return (
      <Button
        onClick={this.handleClick}
        link={true}
        href={this.props.buttonLink}
        className="btn"
        type="primary"
        target="_blank"
        style={{
          backgroundColor: this.props.accentColor,
          color: this.props.contrastColor,
        }}
      >
        {this.props.buttonText}
      </Button>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const event = state.activeEvent;
  const media = state.activeMedia;
  const { eventId } = event;

  return {
    eventId,
    mediaId: media.id ? media.id : null,
    accentColor: event.accentColor,
    contrastColor: event.contrastColor,
    buttonLink: event.buttonLink,
    buttonText: event.buttonText,
  };
};

export default connect(mapStateToProps)(CtaButton);
