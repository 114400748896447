/** @jsxImportSource theme-ui */
import _ from 'lodash';
import { useState, useEffect, useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { WAIT_FOR_ACTION, ERROR_ACTION } from 'redux-wait-for-action';
import { Card, Box, Paragraph, Heading, Link, Flex } from 'theme-ui';
import { motion, AnimatePresence } from 'framer-motion';
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { CardIcon, CalendarIcon, LockIcon, HomeIcon } from '../../Icons';
import Button from '../core/Button'; // LEGACY
import { updateOperator, UPDATE_OPERATOR_SUCCESS, UPDATE_OPERATOR_FAILURE } from '../../../actions/actions';
import { Spinner, Error } from '../atoms';
import { CountrySelect } from '.';

dayjs.extend(customParseFormat);

const stripeFieldOptions = {
  style: {
    base: {
      fontSize: '16px',
      lineHeight: '24px',
      fontFamily: '"sofia-pro", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      iconColor: '#9775f7',
    },
  },
};

const StripeField = ({ icon, label, errorMessage, children }) => (
  <motion.div layout>
    <Box
      as="label"
      py={2}
      sx={{
        display: 'block',
        borderBottom: '1px solid rgba(0,0,0,0.1)',
      }}
    >
      <motion.div layout>
        <Flex>
          <Flex sx={{ width: '33%', flex: '0 0 auto' }}>
            {icon}
            <Box pl={1}>{label}</Box>
          </Flex>
          <Box sx={{ flex: '1 0 auto' }}>{children}</Box>
        </Flex>
      </motion.div>
      <AnimatePresence exitBeforeEnter>{errorMessage && <Error mb={0}>{errorMessage}</Error>}</AnimatePresence>
    </Box>
  </motion.div>
);

const PaymentFormInner = ({ hasSiblings, children }) => {
  if (hasSiblings) {
    return (
      <Card variant="accent" mb={0}>
        {children}
      </Card>
    );
  }
  return children;
};

const PaymentForm = (props) => {
  const dispatch = useDispatch();

  const {
    isAmountDueVisible = false,
    isCouponFieldVisible = false,
    isHeadingVisible = true,
    isDisabled = false,
    isLoading = false,
    beforeComplete,
    onComplete = () => {},
    submitText = 'Save card',
    disclaimerText,
    children,
  } = props;
  const hasChildren = !!children;

  // Stripe hooks
  const stripe = useStripe();
  const elements = useElements();
  // Set ref to a card element (necessary to generate Stripe token)
  const cardElement = elements ? elements.getElement(CardNumberElement) : null;

  // Immediately after a new signup, the organization ID and
  // access token will be available in `userData`. Otherwise
  // we use the logged-in user's data (if available)
  const organizationId = useSelector((store) => {
    return _.get(store, 'userData.user.organizationId') || _.get(store, 'fetchProfile.profile.organizationId', null);
  });
  const accessToken = useSelector((store) => {
    return _.get(store, 'userData.user.accessToken') || _.get(store, 'operator.accessToken', null);
  });

  // Track values for additional fields not provided by Stripe by default
  const [zip, setZip] = useState('');
  const [coupon, setCoupon] = useState('');
  const [country, setCountry] = useState({});

  // Submit loading state
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  // Error state
  const [errors, setErrors] = useState({});

  // Attempt to generate a Stripe token and apply it to the organization.
  // Also handles per-field errors from Stripe.
  const createPaymentToken = () => {
    setIsSubmitLoading(true);
    stripe
      /* eslint-disable camelcase */
      // Include custom fields
      .createToken(cardElement, { address_zip: zip, address_country: country.code })
      /* eslint-enable camelcase */
      .then((result) => {
        if (result.error) {
          setIsSubmitLoading(false);
          // Show error by appropriate field
          let key;
          switch (result.error.code) {
            case 'incomplete_number':
              key = 'cardNumber';
              break;
            case 'incomplete_expiry':
              key = 'expiry';
              break;
            case 'incomplete_cvc':
              key = 'cvc';
              break;
            default:
              key = 'global';
          }
          setErrors({ [key]: result.error.message });
        } else if (result.token) {
          // Save card token to the user's account
          const action = updateOperator({ cardToken: result.token.id, zip, country: country.code }, accessToken);
          action[WAIT_FOR_ACTION] = UPDATE_OPERATOR_SUCCESS;
          action[ERROR_ACTION] = UPDATE_OPERATOR_FAILURE;
          dispatch(action)
            .then((data) => {
              if (!beforeComplete) {
                onComplete({ coupon });
                return;
              }
              beforeComplete({ coupon })
                .then(() => onComplete({ coupon }))
                .catch((err) => {
                  setErrors({ global: err });
                })
                .finally(() => {
                  setIsSubmitLoading(false);
                });
            })
            .catch((err) => {
              setErrors({ global: err });
              setIsSubmitLoading(false);
            });
        }
      })
      .catch((err) => {
        setErrors({ global: err.message });
        setIsSubmitLoading(false);
      });
  };

  // Handle form submit
  const handleSubmit = (e) => {
    e.preventDefault();
    // Return early if Stripe hasn't loaded
    if (!stripe || !elements) {
      setErrors({ global: 'Stripe.js is not loaded' });
      return;
    }
    // No sense collecting payment info with nowhere to save it
    if (!organizationId || !accessToken) {
      setErrors({ global: 'Invalid credentials' });
      return;
    }
    // Country is always required
    if (!country.code) {
      setErrors({ country: 'Please select a country.' });
    }
    // Perform zip code validation if necessary
    const {
      /* eslint-disable camelcase */
      settings: { postal_code_format: postalCodeFormat, requires_postal_code: requiresPostalCode },
      /* eslint-enable camelcase */
    } = country;
    if (requiresPostalCode && (!zip.length || !RegExp(postalCodeFormat).test(zip))) {
      setErrors({ zip: 'Invalid postal code.' });
      return;
    }
    // Finally attempt to validate actual card fields
    // and generate Stripe token
    setErrors({});
    createPaymentToken();
  };

  return (
    <Box as="form" onSubmit={handleSubmit}>
      <Flex
        sx={{
          flexWrap: ['wrap', 'nowrap'],
        }}
        mb={4}
      >
        <Box
          sx={{
            flex: ['1 1 auto', '1 1 0'],
            pr: hasChildren ? [0, 4] : 0,
            mb: [5, 0],
            borderRight: hasChildren ? '1px solid #f4f4f4' : null,
          }}
        >
          <motion.div layout>
            <PaymentFormInner hasSiblings={hasChildren}>
              {isHeadingVisible && (
                <motion.div layout>
                  <Heading as="h4" mb={4} sx={{ fontSize: 2 }}>
                    Payment info
                  </Heading>
                </motion.div>
              )}
              <AnimatePresence exitBeforeEnter>
                {errors.global && <Error key={errors.global}>{errors.global}</Error>}
              </AnimatePresence>
              <Box mb={4}>
                <StripeField label="Card" errorMessage={errors.cardNumber} icon={<CardIcon />}>
                  <CardNumberElement options={stripeFieldOptions} />
                </StripeField>
                <StripeField label="Expiry" errorMessage={errors.expiry} icon={<CalendarIcon />}>
                  <CardExpiryElement options={stripeFieldOptions} />
                </StripeField>
                <StripeField label="CVC" errorMessage={errors.cvc} icon={<LockIcon />}>
                  <CardCvcElement options={stripeFieldOptions} />
                </StripeField>
                <StripeField label="Country" errorMessage={errors.country} icon={<HomeIcon />}>
                  <Box sx={{ mb: -5, mt: -2, ml: -2 }}>
                    <CountrySelect onSelect={setCountry} />
                  </Box>
                </StripeField>
                {country.settings?.requires_postal_code && (
                  <StripeField label="Zip Code" errorMessage={errors.zip} icon={<HomeIcon />}>
                    <input
                      type="text"
                      value={zip}
                      onChange={(e) => setZip(e.target.value)}
                      placeholder="09323"
                      style={{
                        ...stripeFieldOptions.style.base,
                        padding: 0,
                        border: 0,
                        outline: 'none',
                        background: 'transparent',
                        width: '100%',
                      }}
                    />
                  </StripeField>
                )}
                {isCouponFieldVisible && (
                  <StripeField label="Coupon code">
                    <input
                      type="text"
                      value={coupon}
                      onChange={(e) => setCoupon(e.target.value)}
                      style={{
                        ...stripeFieldOptions.style.base,
                        padding: 0,
                        border: 0,
                        outline: 'none',
                        background: 'transparent',
                        width: '100%',
                      }}
                    />
                  </StripeField>
                )}
              </Box>
              <motion.div layout>
                {isAmountDueVisible && (
                  <>
                    <Paragraph sx={{ fontSize: 2 }} mb={3}>
                      Amount due today is <strong>$0</strong>
                    </Paragraph>
                    <Paragraph sx={{ fontSize: 2 }} mb={4}>
                      Amount due on {dayjs().add(1, 'month').format('MMMM D, YYYY')} is <strong>$99</strong>
                    </Paragraph>
                  </>
                )}
                {disclaimerText || (
                  <Paragraph sx={{ fontSize: 1 }} variant="muted">
                    Photobooth Supply Co will make a temporary authorization on your card to verify it. This is an
                    authorization only and NOT a charge. Your bank may inform you of the authorization.
                  </Paragraph>
                )}
              </motion.div>
            </PaymentFormInner>
          </motion.div>
        </Box>
        {hasChildren && (
          <Box sx={{ flex: ['1 1 auto', '1 1 0'], pl: [0, 4] }}>
            <motion.div layout>{children}</motion.div>
          </Box>
        )}
      </Flex>
      <motion.div layout>
        <Flex sx={{ justifyContent: 'center' }}>
          <Button
            variant="primary"
            type="submit"
            loading={isLoading || isSubmitLoading}
            disabled={isDisabled || isLoading || isSubmitLoading}
          >
            {submitText}
          </Button>
        </Flex>
      </motion.div>
    </Box>
  );
};

export default PaymentForm;
