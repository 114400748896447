import React from 'react';
import _ from 'lodash';
import AssetTile from './AssetTile';
import Filters from './Filters';
import Button from '../core/Button';
import SelectMenu from '../core/SelectMenu';
import SelectMenuItem from '../core/SelectMenuItem';
import Tabs from '../core/Tabs';
import SubTabs from '../core/SubTabs';
import { GalleryIcon, StatsIcon, TrashIcon } from '../../Icons';
import ConfirmDialog from '../core/ConfirmDialog';
import ErrorPage from '../ErrorPage';
import Loading from '../Loading';
import MultiSelectDropdown from '../core/MultiSelectDropdown';

const { liveGalleryURL } = require('../../../constants').get();

const assetCategories = [
  {
    type: 'Interface Assets',
    tags: [
      { id: 'startScreen', name: 'Tap to Start Screens' },
      { id: 'boothBackgroundImage', name: 'Backgrounds' },
      { id: 'disclaimerScreen', name: 'Disclaimers' },
      { id: 'customButton', name: 'Custom Buttons' },
    ],
  },
  {
    type: 'Capture Assets',
    tags: [
      { id: 'overlay', name: 'Overlays' },
      { id: 'greenScreenOverlay', name: 'Green Screen Overlays' },
      { id: 'greenScreenBackground', name: 'Green Screen Backgrounds' },
      { id: 'printTemplateOverlay', name: 'Template Overlays' },
      { id: 'printTemplateBackground', name: 'Template Backgrounds' },
      { id: 'printTemplateAsset', name: 'Template Assets' },
    ],
  },
  {
    type: 'Other Assets',
    tags: [
      { id: 'leadSectionLogo', name: 'Logos' },
      { id: 'liveGalleryFeaturedImage', name: 'Featured Images' },
      { id: 'uncategorized', name: 'Uncategorized' },
    ],
  },
];

class AssetGrid extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: [],
      totalAssets: props.userAssets.data.length,
      selectAll: false,
      categories: [props.category],
    };

    this.onSelectItem = this.onSelectItem.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.selectNone = this.selectNone.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleScroll = _.debounce(this.handleScroll.bind(this), 250);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectAll) {
      this.setState({ selectAll: false });
    }
    if (prevState.selectNone) {
      this.setState({ selectNone: false });
    }
    if (prevProps.userAssets.data.length !== this.props.userAssets.data.length) {
      this.setState({ totalAssets: this.props.userAssets.data.length });
    }

    // Auto-load next page if the user can't yet scroll
    if (
      this.props.container &&
      prevProps.userAssets.loading &&
      !this.props.userAssets.loading &&
      this.props.userAssets.hasMore
    ) {
      if (this.props.container.scrollHeight === document.documentElement.scrollHeight) {
        this.props.loadAssets(this.state.categories);
      }
    }

    if (!prevProps.container && this.props.container) {
      this.props.container.addEventListener('scroll', this.handleScroll);
    }
  }

  componentDidMount() {
    // Handle infinite scroll loading
    const { container } = this.props;
    if (container) {
      container.addEventListener('scroll', this.handleScroll);
    }
  }

  componentWillUnmount() {
    const { container } = this.props;
    if (container) {
      container.removeEventListener('scroll', this.handleScroll);
    }
  }

  handleScroll() {
    const { userAssets, loadAssets, container } = this.props;
    if (container && userAssets.hasMore) {
      if (Math.round(container.scrollTop) === Math.round(container.scrollHeight - container.offsetHeight)) {
        loadAssets(this.state.categories);
      }
    }
  }

  selectAll() {
    this.setState({
      selected: this.props.userAssets.data.map((asset) => asset.id),
      selectAll: true,
    });
  }

  selectNone() {
    this.setState({
      selected: [],
      selectNone: true,
    });
  }

  onSelectItem(item) {
    const { isSelected, id } = item;
    this.setState((prevState) => {
      const selected = isSelected
        ? _.concat(prevState.selected, id)
        : prevState.selected.filter((optionId) => optionId !== id);
      return {
        selected,
        selectAll: false,
      };
    });
  }

  handleDelete() {
    if (this.state.selected.length) {
      this.props.deleteAssets(this.state.selected).then(() => {
        this.setState({ selected: [], selectAll: false });
        this.props.resetAssets();
      });
    }
  }

  handleClick(asset) {
    const { onSelectAsset } = this.props;
    if (_.isFunction(onSelectAsset)) {
      onSelectAsset(asset);
    }
  }

  handleCategorySelect = (id, checked) => {
    this.setState((prevState) => {
      if (checked) {
        const categories = prevState.categories;
        categories.push(id);
        return { categories };
      }
      return {
        categories: prevState.categories.filter((c) => c !== id),
      };
    });
    this.props.resetAssets();
    setTimeout(() => this.props.loadAssets(this.state.categories), 10);
  };

  handleSelectAllCategories = (checked) => {
    this.setState((prevState) => {
      if (checked) {
        let categories = [];
        assetCategories.forEach((cat) => {
          categories = [...categories, ...cat.tags.map((t) => t.id)];
        });
        return { categories };
      }
      return { categories: [] };
    });
    this.props.resetAssets();
    setTimeout(() => this.props.loadAssets(this.state.categories), 10);
  };

  render() {
    const { totalAssets, selected, selectAll, selectNone, categories } = this.state;
    const { eventId, userAssets, analyticsToken, isPro } = this.props;
    const loading = _.get(userAssets, 'loading');
    const hasMore = _.get(userAssets, 'hasMore');

    let noAssetsMessage;
    if (!loading && !_.get(userAssets, 'data.length')) {
      noAssetsMessage = (
        <ErrorPage autoHeight={true}>
          <h2 className="gamma mb-xs">No Assets</h2>
          <p>Assets will appear here when they are uploaded.</p>
        </ErrorPage>
      );
    }

    const deleteBtn = (
      <ConfirmDialog
        title={`Delete ${this.state.selected.length} asset${this.state.selected.length > 1 ? 's' : ''}`}
        description={`Are you sure you want to delete ${
          this.state.selected.length > 1 ? 'these assets' : 'this asset'
        }?`}
        buttonVariant="danger"
        confirmText="Delete"
        centered={true}
      >
        {(confirm) => (
          <Button variant="link-danger" size="compact" onClick={confirm(this.handleDelete)}>
            <div className="media media--sm">
              <div className="media__img">
                <TrashIcon size="small" />
              </div>
              <div className="media__body">Delete</div>
            </div>
          </Button>
        )}
      </ConfirmDialog>
    );

    const selectAllBtn = (
      <div className="select-all">
        <div>
          {selected.length} of {totalAssets} selected.
        </div>

        <Button variant="link" size="compact" onClick={this.selectAll}>
          Select all
        </Button>
      </div>
    );

    const selectNoneBtn = (
      <Button variant="link" size="compact" onClick={this.selectNone}>
        Cancel
      </Button>
    );

    return (
      <div>
        <div className="toolbar">
          <div className="toolbar__section">
            <MultiSelectDropdown
              items={assetCategories}
              selected={this.state.categories}
              onSelect={this.handleCategorySelect}
              onSelectAll={this.handleSelectAllCategories}
            />
          </div>
          <div className="toolbar__section">
            {!!selected.length && selectAllBtn}
            {!!selected.length && selectNoneBtn}
          </div>
          <div className="toolbar__section toolbar__section--btns">{!!selected.length && deleteBtn}</div>
        </div>

        {noAssetsMessage}

        <ul className="grid grid--small">
          {userAssets.data.map((asset) => {
            return (
              <AssetTile
                key={asset.id}
                asset={asset}
                selecting={!!selected.length}
                selectAll={selectAll}
                selectNone={selectNone}
                onSelect={this.onSelectItem}
                onClick={this.handleClick}
              />
            );
          })}
        </ul>

        {loading && <Loading centered={true} />}

        {!loading && !hasMore && userAssets.data.length > 0 && <div className="no-more-dot"></div>}
      </div>
    );
  }
}

export default AssetGrid;
