import React from 'react';

const PageSpinner = (props) => {
  const message = props.message || 'Loading Gallery...';
  return (
    <div className="page-loading" style={{ backgroundColor: props.backgroundColor }}>
      <div className="page-loading__loader">
        <div role="status" className="spinner-border" style={{ borderColor: props.color }}></div>
        <p className="page-loading__text" style={{ color: props.color }}>
          {message}
        </p>
      </div>
    </div>
  );
};

export default PageSpinner;
