import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Ink from 'react-ink';
import Button from '../core/Button';

class GridTile extends React.Component {
  constructor(props) {
    super(props);
    this.onTileClick = this.onTileClick.bind(this);
    this.destroyVideo = this.destroyVideo.bind(this);
    this.state = {
      active: false,
      playPromising: false,
    };
    this.video = HTMLVideoElement;
  }

  componentDidMount() {
    this.video.defaultMuted = true;

    if (this.props.event) {
      this.setState({
        eventDate: moment(this.props.event.eventDate).format('M/D/YYYY'),
      });
    }
  }

  onTileClick() {
    const { onTileClick } = this.props;

    if (onTileClick) {
      onTileClick(this.props.image.id);
    }
  }

  destroyVideo() {
    this.video.pause();
    this.video.removeAttribute('src');
    this.video.load();
  }

  componentWillUnmount() {
    if (this.props.image.renderType === 'video' && this.video) {
      this.destroyVideo();
    }
  }

  componentDidUpdate() {
    if (!this.props.image) {
      return;
    }

    if (this.props.image.renderType === 'video' && this.video && !this.state.playPromising) {
      if ((!this.props.isVisible || this.props.overlayOpen) && this.state.active) {
        this.destroyVideo();
        this.setState({ active: false });
      } else if (this.props.isVisible && !this.state.active) {
        setTimeout(() => {
          if (this.video && this.props.isVisible) {
            this.setState({ playPromising: true });
            this.video.setAttribute('src', this.props.image.src);
            const playPromise = this.video.play();
            if (playPromise !== undefined) {
              // Wait for promise to avoid console error
              playPromise
                .then(() => {
                  this.setState({
                    active: true,
                    playPromising: false,
                  });
                })
                .catch((error) => {
                  this.setState({
                    playPromising: false,
                  });
                });
            } else {
              // If .play() doesn't return a promise,
              // just play immediately like before
              this.setState({
                active: true,
                playPromising: false,
              });
            }
          }
        }, 250);
      }
    }
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.overlayOpen && this.props.overlayOpen) {
      return false;
    }
    return true;
  }

  render() {
    const { image, event, href, accentColor, contrastColor, newTab } = this.props;

    const displayName = !this.props.isVisible ? 'hidden' : 'visible';

    const w = this.props.windowSize.w;
    const r = image.size.h / image.size.w;
    let paddingBottom = `${r * 100}%`;
    if (w < 768 && event) {
      paddingBottom = `calc(${paddingBottom} + 54px)`;
    }
    const style = { paddingBottom };
    const renderType = image && image.renderType ? image.renderType : null;
    const TileTag = href ? 'a' : 'button';

    let hoverOverlay;
    if (event) {
      hoverOverlay = (
        <div className="imagetile__overlay">
          <div className="imagetile__content">
            <h2 className="imagetile__title">{event.title}</h2>
            <div className="imagetile__date">{this.state.eventDate}</div>
            <div
              className="btn btn--secondary"
              style={{
                backgroundColor: accentColor,
                color: contrastColor,
              }}
            >
              View Gallery
            </div>
          </div>
          <Ink />
        </div>
      );
    }

    let tileHtml;
    switch (renderType) {
      case 'image':
        tileHtml = (
          <TileTag
            className={`imagetile imagetile--image imagetile--${displayName}`}
            onClick={this.onTileClick}
            href={href}
            style={style}
            target={newTab ? '_blank' : null}
          >
            <img src={image.src} alt="" />
            {hoverOverlay}
          </TileTag>
        );
        break;
      case 'video':
        tileHtml = (
          <TileTag
            className={`imagetile imagetile--video imagetile--${displayName}`}
            onClick={this.onTileClick}
            href={href}
            style={style}
            target={newTab ? '_blank' : null}
          >
            <video
              muted
              loop
              playsInline
              disableRemotePlayback={true}
              preload="none"
              ref={(el) => (this.video = el)}
              src={image.src}
              type="video/mp4"
              style={{
                backgroundImage: `url(${this.props.image.static})`,
              }}
            ></video>
            {hoverOverlay}
          </TileTag>
        );
        break;
      default:
        tileHtml = (
          <TileTag
            className={`imagetile imagetile--image imagetile--${displayName}`}
            onClick={this.onTileClick}
            href={href}
            style={style}
            target={newTab ? '_blank' : null}
          >
            <div className="imagetile__noimage">No image</div>
            {hoverOverlay}
          </TileTag>
        );
    }

    return tileHtml;
  }
}

export default GridTile;
