import { useRef } from 'react';
import { Button as ThemeUIButton, Spinner } from 'theme-ui';
import { useButton } from 'react-aria';
import { AnimatePresence, motion } from 'framer-motion';

const Button = (props) => {
  const { type, variant, isLoading, isDisabled, mb, sx } = props;

  const ref = useRef();
  const { buttonProps } = useButton(props, ref);

  return (
    <ThemeUIButton
      type={type || 'button'}
      {...buttonProps}
      variant={variant}
      disabled={isDisabled}
      mb={mb}
      ref={ref}
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...sx,
      }}
    >
      <AnimatePresence>
        {isLoading && (
          <motion.div layout initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <Spinner size={16} color="white" mr={2} mb={0} p={0} />
          </motion.div>
        )}
      </AnimatePresence>
      {props.children}
    </ThemeUIButton>
  );
};

export default Button;
