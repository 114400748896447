import React from 'react';
import Ink from 'react-ink';
import { toast } from 'react-toastify';
import { PlusIcon, MinusIcon } from '../../Icons';

class NumberInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 0,
    };

    this.setInputValue = this.setInputValue.bind(this);
    this.add = this.add.bind(this);
    this.subtract = this.subtract.bind(this);
    this.widthCalc = React.createRef();
  }

  componentDidMount() {
    this.setInputValue(this.props.value || 0);
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props;
    if (prevProps.value !== value) {
      this.setInputValue(value || 0);
    }
  }

  validateValue = (v) => {
    const { min, max, unit = '', label, step = 1 } = this.props;
    let valid = true;
    let toastMessage;
    if (v % step > 0) {
      valid = false;
      toastMessage = `Only increments of ${step} are supported.`;
    }
    if ((max && v > max) || ((min || min === 0) && v < min)) {
      valid = false;
      if (label) {
        toastMessage = `${label} can only be set from ${min}${unit} to ${max}${unit}.`;
      } else {
        toastMessage = `Value can only be set from ${min}${unit} to ${max}${unit}.`;
      }
    }
    if (toastMessage) {
      toast.error(toastMessage, { autoClose: 2000 });
    }
    return valid;
  };

  setInputValue = (v) => {
    if (this.widthCalc.current) {
      this.widthCalc.current.innerHTML = v.toString();
    }
    this.setState({
      value: parseFloat(v, 10) || 0,
      inputWidth: this.widthCalc.current ? this.widthCalc.current.offsetWidth + 8 : 0,
    });
  };

  handleChange = (e) => {
    this.setInputValue(e.target.value);
  };

  handleBlur = () => {
    const { value } = this.state;
    const valid = this.validateValue(this.state.value);

    if (valid) {
      this.triggerOnChange(value);
    } else {
      this.setInputValue(this.props.value);
    }
  };

  add() {
    const { step = 1 } = this.props;
    const newValue = parseFloat(this.state.value, 10) + step;
    const valid = this.validateValue(newValue);

    if (valid) {
      this.setInputValue(newValue);
      this.triggerOnChange(newValue);
    }
  }

  subtract() {
    const { step = 1 } = this.props;
    const newValue = parseFloat(this.state.value, 10) - step;
    const valid = this.validateValue(newValue);

    if (valid) {
      this.setInputValue(newValue);
      this.triggerOnChange(newValue);
    }
  }

  triggerOnChange(value) {
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(value);
    }
  }

  render() {
    const { min, max, step, unit, tabbable } = this.props;
    const { value } = this.state;

    return (
      <div className="form-number">
        <button
          type="button"
          className="button button--secondary button--circle form-number__button"
          onClick={this.subtract}
          disabled={(min || min === 0) && value <= min}
          tabIndex={!tabbable ? -1 : null}
        >
          <span className="sr-only">Subtract</span>
          <span className="icon">
            <MinusIcon />
          </span>
          <Ink />
        </button>

        <div className="form-number__input">
          <input
            type="number"
            min={min}
            max={max}
            step={step}
            value={this.state.value}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            style={{
              width: `${this.state.inputWidth}px`,
            }}
          />
          <span className="form-number__unit">{unit}</span>
        </div>

        <span className="form-number__width-calc" ref={this.widthCalc}>
          {this.state.value}
        </span>

        <button
          type="button"
          className="button button--secondary button--circle form-number__button"
          onClick={this.add}
          disabled={max && value >= max}
          tabIndex={!tabbable ? -1 : null}
        >
          <span className="sr-only">Add</span>
          <span className="icon">
            <PlusIcon />
          </span>
          <Ink />
        </button>
      </div>
    );
  }
}

export default NumberInput;
