import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import Konva from 'konva';
import { render } from 'react-dom';
import { Stage, Layer, Group, Rect, Text, Image } from 'react-konva';
import useImage from 'use-image';

const TemplatePreviewImage = (shapeProps) => {
  const [image] = useImage(shapeProps.src, 'anonymous', 'origin');
  const [size, setSize] = useState({});

  const { resizeMode } = shapeProps;

  useEffect(() => {
    // Return if image isn't ready
    if (!image?.naturalHeight || !resizeMode) {
      return;
    }
    const widthRatio = shapeProps.width / image.naturalWidth;
    const heightRatio = shapeProps.height / image.naturalHeight;
    // Calculate ratio
    let r = 1;
    switch (resizeMode) {
      case 'fit':
        r = Math.min(widthRatio, heightRatio);
        break;
      case 'cover':
        r = Math.max(widthRatio, heightRatio);
        break;
      default:
        return;
    }
    setSize({ height: image.naturalHeight * r, width: image.naturalWidth * r });
  }, [image]);

  return (
    <Group
      clip={{
        x: shapeProps.xOrigin,
        y: shapeProps.yOrigin,
        width: shapeProps.width,
        height: shapeProps.height,
      }}
    >
      <Image
        image={image}
        {...shapeProps}
        x={size.width ? shapeProps.xOrigin + shapeProps.width / 2 - size.width / 2 : shapeProps.xOrigin}
        y={size.height ? shapeProps.yOrigin + (shapeProps.height - size.height) : shapeProps.yOrigin}
        rotation={shapeProps.rotationAngle}
        width={size.width || shapeProps.width}
        height={size.height || shapeProps.height}
      />
    </Group>
  );
};

const TemplatePreviewPlaceholder = (shapeProps) => (
  <Rect
    {...shapeProps}
    x={shapeProps.xOrigin}
    y={shapeProps.yOrigin}
    fill="#fff"
    strokeScaleEnabled={false}
    strokeWidth={1}
    stroke="#b7b7b7"
  />
);

const TemplatePreviewText = (shapeProps) => {
  const shapeRef = React.useRef(null);
  React.useEffect(() => {
    const shapeNode = shapeRef.current;
    if (!shapeNode) {
      return;
    }

    const { xOrigin, yOrigin, transform } = shapeProps;

    // Ignore deprecated transformation matrix if it is not set.
    if (_.isNil(transform) || transform.length === 0) {
      return;
    }

    const tf = shapeNode.getTransform();
    tf.m = transform;
    tf.translate(xOrigin, yOrigin);
  });
  return (
    <Text
      {...shapeProps}
      x={shapeProps.xOrigin}
      y={shapeProps.yOrigin}
      ref={shapeRef}
      fill={`rgba(${shapeProps.color.join(',')})`}
      fontFamily={`"${shapeProps.font}"`}
      fontSize={shapeProps.size}
      // fontStyle={[shapeProps.italic ? 'italic' : null, shapeProps.bold ? 'bold' : null].join(' ')}
      rotation={shapeProps.rotationAngle}
    />
  );
};

const TemplatePreview = ({ stageWidth, stageHeight, config }) => {
  if (!config || !config.placeholders) {
    return <div data-testid={'TemplatePreview-Test'} />;
  }

  const scale = Math.min(stageWidth / config.width, stageHeight / config.height);

  const templateScale = {
    x: config.width > config.height ? stageWidth / config.width : stageHeight / config.height,
    y: config.width > config.height ? stageWidth / config.width : stageHeight / config.height,
  };

  const shapes = Object.entries(config)
    .reduce((accumulator, [key, value]) => {
      if (!['placeholders', 'images', 'texts'].includes(key)) {
        return accumulator;
      }
      accumulator.push(...value.map((shape) => ({ ...shape, category: key })));
      return accumulator;
    }, [])
    .sort((a, b) => (a.zIndex > b.zIndex ? 1 : -1));

  return (
    <div style={{ pointerEvents: 'none' }} data-testid={`TemplatePreview-${config.id}`}>
      <Stage width={stageWidth} height={stageHeight} scale={templateScale}>
        <Layer
          x={stageWidth / templateScale.x / 2 - config.width / 2}
          y={stageHeight / templateScale.y / 2 - config.height / 2}
        >
          <Group
            scale={{ x: 0.9, y: 0.9 }}
            x={config.width / 2 - (config.width * 0.9) / 2}
            y={config.height / 2 - (config.height * 0.9) / 2}
          >
            <Rect
              x={0}
              y={0}
              strokeWidth={2}
              strokeScaleEnabled={false}
              stroke="rgba(0,0,0,0)"
              fill="white"
              width={config.width}
              height={config.height}
              shadowColor="#000"
              shadowBlur={50}
              shadowOffset={{ x: 0, y: 25 }}
              shadowOpacity={0.1}
            />
            <Group
              clip={{
                x: 0,
                y: 0,
                width: config.width,
                height: config.height,
              }}
            >
              <Rect
                fill="#e2e2e2"
                x={0}
                y={0}
                width={config.width}
                height={config.height}
                strokeScaleEnabled={false}
                strokeWidth={1}
                stroke="#b7b7b7"
              />
              {shapes.map((shape, i) => {
                switch (shape.category) {
                  case 'images':
                    return <TemplatePreviewImage key={i} {...shape} />;
                  case 'texts':
                    return <TemplatePreviewText key={i} {...shape} />;
                  case 'placeholders':
                    return <TemplatePreviewPlaceholder key={i} {...shape} />;
                  default:
                    return null;
                }
              })}
            </Group>
          </Group>
        </Layer>
      </Stage>
    </div>
  );
};

/* class PrintTemplate extends Component {
  state = {
    background: null,
    overlay: null,
    images: [],
    loadingImages: false
  }

  resetImages = () => {
    this.setState({ loadingImages: true });


  }

  componentDidMount() {
    this.resetImages();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.images, this.props.images)) {
      this.resetImages();
    }
  }

  render() {
    let {
      id,
      width,
      height,
      placeholders,
      stageWidth,
      stageHeight,
      showNumbers,
      doubled
    } = this.props;

    const { images } = this.state;

    const scale = Math.min(
      stageWidth / width,
      stageHeight / height
    );

    // Get largest side to use for centering layer
    const largest = Math.max(width, height);

    const transformedPlaceholders = placeholders.map(p => {
      const placeholder = {...p};
      placeholder.zIndex = 0;
      return placeholder;
    });
    const shapes = _.orderBy([...images, ...transformedPlaceholders], ['zIndex'], ['asc']);

    let x = largest/2 - width/2, x2;
    let y = largest/2 - height/2, y2;
    if (doubled) {
      if (height > width) {
        x = largest/2 - width;
        x2 = largest/2;
      } else {
        y = largest/2 - height;
        y2 = largest/2;
      }
    }

    const backdrop = <Rect
      fill="#e2e2e2"
      stroke="#b7b7b7"
      strokeWidth={1}
      x={0}
      y={0}
      width={width}
      height={height}
      strokeScaleEnabled={false}
    />

    let placeholderIndex = 0;
    const renderShapes = shapes.map((shape, i) => {
      if (!shape.element)
        placeholderIndex++;

      const scale = {
        x: shape.photo ? shape.width / shape.element.naturalWidth :
           shape.element ? shape.width / shape.element.naturalWidth : undefined,
        y: shape.photo ? shape.width / shape.element.naturalWidth :
           shape.element ? shape.height / shape.element.naturalHeight : undefined
      }

      return (
        <React.Fragment key={`${shape.xOrigin}-${shape.yOrigin}-${i}`}>
          <Rect
            x={shape.xOrigin}
            y={shape.yOrigin}
            width={shape.width}
            height={shape.height}
            fill={shape.element ? undefined : '#ffffff'}
            stroke={shape.element ? undefined : '#b7b7b7'}
            strokeWidth={shape.element ? 0 : 1}
            strokeScaleEnabled={false}
            fillPatternImage={shape.element ? shape.element : undefined}
            fillPatternScaleX={scale.x}
            fillPatternScaleY={scale.y}
            fillPatternRepeat='no-repeat'
          />
          {showNumbers && !shape.element ? (
            <Text
              text={placeholderIndex}
              x={shape.xOrigin + shape.width/2 - (3*(1/scale))}
              y={shape.yOrigin + shape.height/2 - (6*(1/scale))}
              fontFamily="sofia-pro"
              fontSize={14*(1/scale)}
              fill="#999999"
              align="center"
            />
          ) : null}
        </React.Fragment>
      );
    });

    return (
        <Stage width={stageWidth} height={stageHeight} scaleX={scale} scaleY={scale}>
          <Layer x={x} y={y}>
            {backdrop}
            {renderShapes}
          </Layer>

          {doubled &&
            <Layer x={x2} y={y2}>
              {backdrop}
              {renderShapes}
            </Layer>
          }
        </Stage>
    );
  }
} */

export default TemplatePreview;
