import { useState, useEffect, createContext, useContext, useRef } from 'react';
import { useFocusRing, VisuallyHidden, useRadioGroup, useRadio } from 'react-aria';
import { useRadioGroupState } from 'react-stately';
import Tooltip from './Tooltip';

const SwitchContext = createContext(null);

export const TextSwitch = (props) => {
  const { label, helperText, children } = props;
  const state = useRadioGroupState(props);
  const { radioGroupProps, labelProps } = useRadioGroup(props, state);
  const [activeIndex, setActiveIndex] = useState(
    children.map((child) => child.props.value).indexOf(state.selectedValue)
  );

  useEffect(() => {
    // Move active marker
    setActiveIndex(children.map((child) => child.props.value).indexOf(state.selectedValue));
  }, [state.selectedValue]);

  return (
    <div {...radioGroupProps} className="text-switch">
      <div className="text-switch__label">
        <span {...labelProps}>{label}</span>
        {!!helperText && <Tooltip tip={helperText} direction="top" />}
      </div>
      <div className="text-switch__options">
        <SwitchContext.Provider value={state}>{children}</SwitchContext.Provider>
        <div
          className="text-switch__active"
          style={{
            width: `calc(${100 / children.length}% + 1px)`,
            left: `${(100 / children.length) * activeIndex}%`,
          }}
        ></div>
      </div>
    </div>
  );
};

export const TextSwitchOption = (props) => {
  const { children } = props;
  const state = useContext(SwitchContext);
  const ref = useRef(null);
  const { inputProps } = useRadio(props, state, ref);
  const { isFocusVisible, focusProps } = useFocusRing();

  const isSelected = state.selectedValue === props.value;

  return (
    <label className={['text-switch__option', isFocusVisible ? 'text-switch__option--active' : null].join(' ')}>
      <VisuallyHidden>
        <input {...inputProps} {...focusProps} ref={ref} />
      </VisuallyHidden>
      {children}
    </label>
  );
};
