import React from 'react';
import { getContrastColor } from '../../../utils/utils';
import { CheckIcon } from '../../Icons';

class Checkbox extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    if (typeof this.props.onCheck === 'function') {
      this.props.onCheck(e.target.checked);
    }
  }

  render() {
    let { type } = this.props;
    const { id, label, name, checked, className, color } = this.props;

    type = type || 'checkbox';

    const classNames = ['form-check'];
    if (className) {
      classNames.push(className);
    }

    const checkStyle = {};
    let iconColor;
    if (color && checked) {
      checkStyle.borderColor = color;
      checkStyle.background = color;
      iconColor = getContrastColor(color);
    }

    return (
      <div className={classNames.join(' ')}>
        <input
          id={id}
          type={type}
          name={name}
          className="form-check__input"
          onChange={this.handleChange}
          checked={checked || false}
        />
        <label htmlFor={id} className="form-check__label" style={checkStyle}>
          <CheckIcon color={iconColor} />
        </label>

        {label && (
          <label htmlFor={id} className="form-check__text">
            {label}
          </label>
        )}
      </div>
    );
  }
}

export default Checkbox;
