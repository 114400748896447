import dot from 'dot-object';
import { useDispatch, useSelector } from 'react-redux';
import { deleteEvents as deleteEventAction, deleteEvents, updateEvent as updateEventAction } from '../actions/actions';

const useAppEvent = () => {
  const dispatch = useDispatch();

  const appEvent = useSelector((store) => store.appEvent);
  const accessToken = useSelector((store) => store.operator.accessToken);

  const updateEvent = (data, fieldValue) => {
    if (typeof data === 'object') {
      return dispatch(updateEventAction(appEvent.data.id, data, accessToken));
    }
    // If data parameter is a string, convert dot syntax of setting key
    if (typeof data === 'string') {
      if (fieldValue === undefined) {
        return new Error('No value provided to update');
      }
      const dataObj = dot.object({ [data]: fieldValue });
      return dispatch(updateEventAction(appEvent.data.id, dataObj, accessToken));
    }
    return new Error('Invalid data type');
  };

  const deleteEvent = () => {
    return dispatch(deleteEventAction(appEvent.data.id, accessToken));
  };

  return [appEvent.data, appEvent.loading, appEvent.error, updateEvent, deleteEvent];
};

export default useAppEvent;
