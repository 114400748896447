/** @jsxImportSource theme-ui */
import { useContext, useRef } from 'react';
import { useRadio } from 'react-aria';
import { VisuallyHidden } from '@react-aria/visually-hidden';
import { useFocusRing } from '@react-aria/focus';
import { Box, Card, Flex, Paragraph, Icon, Text } from '../atoms';
import { RadioContext } from '../molecules/RadioGroup';

const RadioCard = (props) => {
  const { children, label, description } = props;
  const state = useContext(RadioContext);
  const ref = useRef(null);
  const { inputProps } = useRadio(props, state, ref);
  const { isFocusVisible, focusProps } = useFocusRing();

  return (
    <Box as="label">
      <Card
        variant={isFocusVisible || inputProps.checked ? 'outlineSelected' : 'outlineButton'}
        p={4}
        sx={{
          textAlign: 'center',
          cursor: 'pointer',
        }}
      >
        <VisuallyHidden>
          <input {...inputProps} {...focusProps} ref={ref} />
        </VisuallyHidden>

        {children}

        <Paragraph sx={{ fontSize: [2, 3], fontWeight: 'bold' }}>{label}</Paragraph>
        <Paragraph sx={{ fontSize: 1 }} mb={3} variant="muted">
          {description}
        </Paragraph>

        <Flex
          sx={{
            color: 'accent',
            fontWeight: 'bold',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {inputProps.checked && (
            <Box pr={1} sx={{ display: 'flex' }}>
              <Icon variant="Common02" />
            </Box>
          )}
          <Text>{inputProps.checked ? 'Selected' : 'Select'}</Text>
        </Flex>
      </Card>
    </Box>
  );
};

export default RadioCard;
