import React from 'react';
import PropTypes from 'prop-types';

const LogoDiv = ({ logoSrc, link }) => {
  if (link && link.length) {
    return (
      <a className="logo" href={link}>
        <img src={logoSrc} alt="" />
      </a>
    );
  }
  return (
    <div className="logo">
      <img src={logoSrc} alt="" />
    </div>
  );
};

LogoDiv.propTypes = {
  logoSrc: PropTypes.string,
};

export default LogoDiv;
