import { forwardRef } from 'react';
import { Label as ThemeUILabel } from 'theme-ui';

const Label = forwardRef((props, ref) => (
  <ThemeUILabel {...props} ref={ref}>
    {props.children}
  </ThemeUILabel>
));

export default Label;
