import React from 'react';

class IconButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active: false,
    };

    this.handleClick = this.handleClick.bind(this);
    this.timeout = null;
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  handleClick(e) {
    this.setState({ active: true });

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState({ active: false });
    }, 600);
  }

  render() {
    const { className, onClick, variant } = this.props;
    const { active } = this.state;
    const classNames = ['icon-btn'];

    if (className) {
      classNames.push(className);
    }
    if (active) {
      classNames.push('icon-btn--active');
    }
    if (variant) {
      classNames.push(`icon-btn--${variant}`);
    }

    return (
      <button
        className={classNames.join(' ')}
        onMouseDown={this.handleClick}
        onClick={onClick}
        ref={(el) => (this.button = el)}
      >
        {this.props.children}
        <span className="sr-only">{this.props.alt}</span>
      </button>
    );
  }
}

export default IconButton;
