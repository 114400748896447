/** @jsxImportSource theme-ui */
import { useState, useRef } from 'react';
import { useLink } from 'react-aria';
import { Link as ThemeUILink } from 'theme-ui';

const Link = (props) => {
  const linkRef = useRef();

  const { linkProps } = useLink(props, linkRef);

  return (
    <ThemeUILink
      {...linkProps}
      ref={linkRef}
      href={props.href}
      target={props.target}
      variant={props.variant || 'primary'}
    >
      {props.children}
    </ThemeUILink>
  );
};

export default Link;
