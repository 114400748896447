import React, { Component } from 'react';
import Ink from 'react-ink';
import ProLabel from '../ProLabel';

class SubTab extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { id, onClick, disabled } = this.props;
    if (!disabled) {
      onClick(id);
    }
  }

  render() {
    const {
      handleClick,
      props: { activeTab, id, label, disabled },
    } = this;

    let className = 'sub-tabs-list__item';

    if (activeTab === id) {
      className += ' sub-tabs-list__item--active';
    }

    if (disabled) {
      className += ' sub-tabs-list__item--disabled';
    }

    return (
      <button className={className} onClick={handleClick}>
        {label}
        {disabled && <ProLabel />}
        <Ink />
      </button>
    );
  }
}

export default SubTab;
