export const colorKeys = ['red', 'yellow', 'green', 'blue', 'purple'];

export const themeKeys = [
  'Flower',
  'Test',
  'Wedding',
  'Holidays',
  'Halloween',
  'Summer',
  'Decades',
  'School',
  'Birthdays',
  'Nightclub',
  'Sports',
  'Misc',
];

export const rgbValues = {
  red: [255, 0, 0],
  yellow: [255, 255, 0],
  green: [0, 255, 0],
  blue: [0, 0, 255],
  purple: [255, 0, 255],
};

export const checkColor = (color) => {
  const [r, g, b] = color;
  const rgbDiffs = colorKeys.map((testColor) => {
    const [r2, g2, b2] = rgbValues[testColor];
    const rgbDiff = (r - r2) ** 2 + (g - g2) ** 2 + (b - b2) ** 2;
    return { color: testColor, rgbDiff };
  });
  const colorCategory = rgbDiffs.reduce((min, curr) => {
    return curr.rgbDiff < min.rgbDiff ? curr : min;
  }).color;
  return colorCategory;
};
