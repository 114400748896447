import { connect } from 'react-redux';
import { WAIT_FOR_ACTION, ERROR_ACTION } from 'redux-wait-for-action';
import _ from 'lodash';
import moment from 'moment';
import {
  UPDATE_EVENT_SUCCESS,
  UPDATE_EVENT_FAILURE,
  listPortfolioEvents,
  listPortfolioEventsReset,
  LIST_PORTFOLIO_EVENTS_SUCCESS,
  LIST_PORTFOLIO_EVENTS_FAILURE,
  getPortfolioSettings,
  GET_PORTFOLIO_SETTINGS_SUCCESS,
  GET_PORTFOLIO_SETTINGS_FAILURE,
  requestEventAccessWithPassword,
} from '../actions/actions';
import { Router } from '../routes';
import Portfolio from '../components/Portfolio';
import { getContrastColor } from '../utils/utils';

const mapStateToProps = (state, ownProps) => {
  const { portfolioEvents, portfolioSettings, passwordToken } = state;
  return { portfolioEvents, portfolioSettings, passwordToken, ...ownProps };
};

const mergeProps = (
  { portfolioEvents, portfolioSettings, passwordToken, operatorId, subdomain, iframe, isSalsa },
  { dispatch }
) => {
  const settings = portfolioSettings;
  if (settings.data && settings.data.accentColor) {
    settings.data.contrastColor = getContrastColor(settings.data.accentColor);
  }

  const portfolioId = subdomain || operatorId || null;

  let passwordProtected = _.get(settings, 'data.enabled') ? false : _.get(settings, 'data.passwordProtected');
  if (_.get(passwordToken, 'token')) {
    passwordProtected = false;
  }

  return {
    portfolioEvents,
    passwordProtected,
    passwordToken,
    portfolioSettings: settings,
    loadSettings: () => {
      const action = getPortfolioSettings(portfolioId, _.get(passwordToken, 'token'), isSalsa);
      action[WAIT_FOR_ACTION] = GET_PORTFOLIO_SETTINGS_SUCCESS;
      action[ERROR_ACTION] = GET_PORTFOLIO_SETTINGS_FAILURE;
      return dispatch(action);
    },
    loadEvents: (options) => {
      const args = {
        passwordAccessToken: _.get(passwordToken, 'token'),
        pagination: {
          // INFO: Arbitrary high number effectively fetches all events at once,
          // so the embedded portfolio doesn't need to listen for a scroll event
          count: 999,
        },
        sortOrder: _.get(settings, 'data.sortOrder'),
      };
      if (_.get(options, 'searchText')) {
        args.searchText = options.searchText;
      }
      const action = listPortfolioEvents(portfolioId, args, isSalsa);
      action[WAIT_FOR_ACTION] = LIST_PORTFOLIO_EVENTS_SUCCESS;
      action[ERROR_ACTION] = LIST_PORTFOLIO_EVENTS_FAILURE;
      return dispatch(action);
    },
    updateEvent: (config) => {
      const action = updateEvent(appEvent.data.id, _.pick(config, keys), accessToken);
      action[WAIT_FOR_ACTION] = UPDATE_EVENT_SUCCESS;
      action[ERROR_ACTION] = UPDATE_EVENT_FAILURE;
      return dispatch(action);
    },
    resetEvents: () => dispatch(listPortfolioEventsReset()),
    login: (password) => {
      return dispatch(requestEventAccessWithPassword(portfolioId, password, 'portfolio'));
    },
    iframe,
  };
};

export default connect(mapStateToProps, null, mergeProps)(Portfolio);
