/** @jsxImportSource theme-ui */
import { motion } from 'framer-motion';
import { Paragraph } from 'theme-ui';

const Error = (props) => (
  <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} layout>
    <Paragraph variant="error" mb={2} role="alert" sx={{ textAlign: 'left' }} {...props}>
      {props.children}
    </Paragraph>
  </motion.div>
);

export default Error;
