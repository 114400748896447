import { connect } from 'react-redux';
import { WAIT_FOR_ACTION, ERROR_ACTION } from 'redux-wait-for-action';
import _ from 'lodash';
import moment from 'moment';
import { Router } from '../../routes';
import UploadModal from '../../components/webapp/core/UploadModal';
import {
  listAssets,
  listAssetsReset,
  uploadAsset,
  deleteAssets,
  UPLOAD_ASSET_SUCCESS,
  UPLOAD_ASSET_FAILURE,
  DELETE_ASSETS_SUCCESS,
  DELETE_ASSETS_FAILURE,
  loadSysAssets,
  LOAD_SYS_ASSETS_SUCCESS,
  LOAD_SYS_ASSETS_FAILURE,
} from '../../actions/actions';

const mapStateToProps = (state, ownProps) => {
  const {
    operator: { accessToken },
    userAssets,
    sysAssets,
  } = state;
  return { accessToken, userAssets, sysAssets, ...ownProps };
};

const mergeProps = (props, { dispatch }) => {
  const {
    accessToken,
    userAssets,
    sysAssets,
    id,
    onSelectAsset,
    isOpen,
    onClose,
    accept,
    assets,
    category,
    categoryName,
  } = props;

  return {
    userAssets,
    sysAssets,
    defaultAssets: assets,
    loadAssets: (categories) => {
      let assetCategories = categories;
      if (!categories) {
        assetCategories = [category];
      }
      const before = _.get(userAssets.data.slice(-1).pop(), 'dateCreated') || null;
      return dispatch(listAssets({ pagination: { count: 12, before }, category: assetCategories }, accessToken));
    },
    loadSysAssets: (type) => {
      const action = loadSysAssets(type);
      action[WAIT_FOR_ACTION] = LOAD_SYS_ASSETS_SUCCESS;
      action[ERROR_ACTION] = LOAD_SYS_ASSETS_FAILURE;
      return dispatch(action);
    },
    resetAssets: () => {
      return dispatch(listAssetsReset());
    },
    uploadAsset: (files) => {
      if (!files || !files.length) {
        throw new Error('No files to upload');
      }
      const action = uploadAsset(files[0], null, category, accessToken);
      action[WAIT_FOR_ACTION] = UPLOAD_ASSET_SUCCESS;
      action[ERROR_ACTION] = UPLOAD_ASSET_FAILURE;
      return dispatch(action);
    },
    deleteAssets: (ids) => {
      const action = deleteAssets(ids, accessToken);
      action[WAIT_FOR_ACTION] = DELETE_ASSETS_SUCCESS;
      action[ERROR_ACTION] = DELETE_ASSETS_FAILURE;
      return dispatch(action);
    },
    id,
    onSelectAsset,
    isOpen,
    onClose,
    accept,
    category,
    categoryName,
  };
};

export default connect(mapStateToProps, null, mergeProps)(UploadModal);
