import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Head from 'next/head';
import { getFavIcon } from '../src/scripts/utils/getFavIcon';
import { Router } from '../src/scripts/routes';
import utils from '../src/scripts/utils/utils';
import Portfolio from '../src/scripts/containers/portfolio';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/styles/index.styl';

const { liveGalleryURL } = require('../src/scripts/constants').get();

class SplashPage extends React.Component {
  static async getInitialProps({ req, res, store, isServer, query }) {
    const { operatorId } = query;

    let subdomain;
    if (req) {
      const domain = req.headers.host;
      if (domain && domain.includes('.')) {
        subdomain = domain.split('.')[0];
        if (!utils.isValidSubdomain(subdomain)) {
          subdomain = null;
        }
      }
    }

    /* if (subdomain && res) {
            res.writeHead(301, {
                Location: liveGalleryURL.replace(/:\/\//, `://${subdomain}.`) + '/empty'
            });
            res.end();
        } */

    return { subdomain, operatorId };
  }

  render() {
    const { subdomain, operatorId } = this.props;

    if (subdomain || operatorId) {
      return <Portfolio subdomain={subdomain} operatorId={operatorId} isSalsa={true} />;
    }

    return (
      <div className="splashpage">
        <Head>
          <title>No photos here</title>
          {getFavIcon('blank')}
        </Head>
        <Container fluid={true}>
          <Row className="justify-content-center">
            <Col xs="12" md="7" lg="4">
              <div className="imagecontainer">
                <img src="/static/frame.svg" />
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs="12" md="7">
              <div className="titles">
                <div className="title">No Photos Here</div>
                <div className="subtitle">Contact your rental company for more information</div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default SplashPage;
