/** @jsxImportSource theme-ui */
import React, { useEffect, useState, useRef } from 'react';
import { useListBox, useOption } from 'react-aria';
import { Box } from 'theme-ui';
import { useListState } from '@react-stately/list';

const ListBox = (props) => {
  const ref = useRef();
  const { listBoxRef = ref, state = useListState(props), minHeight = 200 } = props;
  const { listBoxProps } = useListBox(props, state, listBoxRef);
  const [maxHeight, setMaxHeight] = useState(minHeight);

  // Set max height so the box is as tall as possible without going off the page
  useEffect(() => {
    if (typeof window === 'undefined' || !ref.current) {
      return;
    }
    const rect = ref.current.getBoundingClientRect();
    setMaxHeight(Math.max(window.innerHeight - rect.top - 24, minHeight));
  }, []);

  return (
    <Box
      as="ul"
      {...listBoxProps}
      ref={listBoxRef}
      sx={{
        margin: 0,
        padding: 0,
        listStyle: 'none',
        overflow: 'auto',
        outline: 'none',
        backgroundColor: 'white',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.05);',
        maxHeight,
      }}
    >
      {[...state.collection].map((item) => (
        <Option key={item.key} item={item} state={state} />
      ))}
    </Box>
  );
};

const Option = ({ item, state }) => {
  const ref = React.useRef();
  const { optionProps, isSelected, isFocused, isDisabled } = useOption({ key: item.key }, state, ref);

  let backgroundColor;
  let color = 'text';

  if (isSelected) {
    backgroundColor = '#9775f7';
    color = 'white';
  } else if (isFocused) {
    backgroundColor = '#f4f4f4';
  } else if (isDisabled) {
    backgroundColor = 'transparent';
    color = 'gray';
  }

  return (
    <Box
      as="li"
      {...optionProps}
      ref={ref}
      px={2}
      py={1}
      sx={{
        background: backgroundColor,
        color,
        outline: 'none',
        cursor: 'pointer',
      }}
    >
      {item.rendered}
    </Box>
  );
};

export default ListBox;
