import React from 'react';
import { InfoIcon, WarningIcon } from '../../Icons';

const Tooltip = (props) => {
  const { tip, noHover, wide, variant, direction } = props;

  const btnClass = ['tooltip__button', !noHover ? 'hover' : ''].join(' ').trim();

  let icon;
  switch (variant) {
    case 'warning':
      icon = <WarningIcon />;
      break;
    default:
      icon = <InfoIcon />;
      break;
  }

  return (
    <div
      className={[
        'tooltip',
        wide ? 'tooltip--wide' : null,
        variant ? `tooltip--${variant}` : null,
        direction ? `tooltip--${direction}` : null,
      ].join(' ')}
    >
      <button type="button" className={btnClass}>
        <span className="sr-only">More info</span>
        {icon}
      </button>

      <div className="tooltip__tip">
        <div className="tooltip__content">{tip}</div>
      </div>
    </div>
  );
};

export default Tooltip;
