import React, { Component } from 'react';
import Ink from 'react-ink';
import ProLabel from '../ProLabel';

class PriorityNav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      current: null,
      priorityItems: [],
      moreItems: [],
      moreItemsVisible: false,
    };

    this.fullNavArray = this.props.items;
    this.updateNavigation = this.updateNavigation.bind(this);
    this.toggleMoreItems = this.toggleMoreItems.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.setState({
      priorityItems: this.props.items,
    });
  }

  componentDidMount() {
    this.widthsArray = Array.from(this.refs.navigation.children).map((item) => item.getBoundingClientRect().width);
    window.addEventListener('resize', this.updateNavigation, 100);
    this.updateNavigation();
  }

  handleClick(item) {
    if (typeof item.onClick === 'function') {
      item.onClick();
    }

    this.setState({
      current: item,
      moreItemsVisible: false,
    });
  }

  toggleMoreItems() {
    this.setState((prevState) => ({
      moreItemsVisible: !prevState.moreItemsVisible,
    }));
  }

  howManyItemsInMenuArray(array, outerWidth, initialWidth) {
    let total = initialWidth;
    for (let i = 0; i < array.length; i += 1) {
      if (total + array[i] > outerWidth) {
        return i;
      }
      total += array[i];
    }
  }

  updateNavigation() {
    if (this.refs.navigationOuter) {
      this.outerWidth = this.refs.navigationOuter.getBoundingClientRect().width;
      const arrayAmount = this.howManyItemsInMenuArray(this.widthsArray, this.outerWidth, 90);
      const navItemsCopy = this.fullNavArray;
      const priorityItems = navItemsCopy.slice(0, arrayAmount);

      this.setState({
        current: !this.state.current ? priorityItems[0] : this.state.current,
        priorityItems,
        moreItems:
          priorityItems.length !== navItemsCopy.length ? navItemsCopy.slice(arrayAmount, navItemsCopy.length) : [],
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateNavigation());
  }

  render() {
    const { priorityItems, moreItems, moreItemsVisible } = this.state;

    const toggleClassNames = ['priority-nav__toggle'];
    if (moreItemsVisible) {
      toggleClassNames.push('priority-nav__toggle--active');
    }

    let moreActive = false;
    if (this.state.current) {
      for (let i = 0; i < moreItems.length; i += 1) {
        if (moreItems[i].label === this.state.current.label) {
          moreActive = true;
          break;
        }
      }
    }
    if (moreActive) {
      toggleClassNames.push('priority-nav__toggle--current');
    }

    return (
      <div>
        <nav ref="navigationOuter" className="priority-nav">
          <ul ref="navigation" className="priority-nav__list">
            {priorityItems.map((item, i) => {
              const classNames = ['priority-nav__item'];
              if (this.state.current && this.state.current.label === item.label) {
                classNames.push('priority-nav__item--active');
              }
              if (item.disabled) {
                classNames.push('priority-nav__item--disabled');
              }

              return (
                <li key={`navItem-${i}`} className={classNames.join(' ')}>
                  <button
                    className="priority-nav__link"
                    onClick={() => this.handleClick(item)}
                    disabled={!!item.disabled}
                  >
                    {item.label}
                    {item.disabled && <ProLabel />}
                    <Ink />
                  </button>
                </li>
              );
            })}
          </ul>

          {moreItems.length > 0 && (
            <div ref="moreMenu" className="priority-nav__more">
              <button className={toggleClassNames.join(' ')} onClick={this.toggleMoreItems}>
                More...
                <Ink />
              </button>

              {moreItemsVisible && (
                <ul ref="moreNav" className="priority-nav__list priority-nav__list--more">
                  {moreItems.map((item, i) => {
                    const classNames = ['priority-nav__item'];
                    if (this.state.current && this.state.current.label === item.label) {
                      classNames.push('priority-nav__item--active');
                    }
                    if (item.disabled) {
                      classNames.push('priority-nav__item--disabled');
                    }

                    return (
                      <li key={`moreNavItem-${i}`} className={classNames.join(' ')}>
                        <button
                          className="block-list__link"
                          onClick={() => this.handleClick(item)}
                          disabled={!!item.disabled}
                        >
                          {item.label}
                          {item.disabled && <ProLabel />}
                          <Ink />
                        </button>
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          )}
        </nav>
      </div>
    );
  }
}

export default PriorityNav;
