import { mergeProps, useTooltip } from 'react-aria';
import { motion, AnimatePresence } from 'framer-motion';
import { Box } from '../atoms';

const Tooltip = ({ state, ...props }) => {
  const { tooltipProps } = useTooltip(props, state);

  return (
    <AnimatePresence>
      {state.isOpen && (
        <motion.div layout initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
          <Box
            sx={{
              position: 'absolute',
              left: 0,
              top: '100%',
              textAlign: 'left',
              mt: 1,
              backgroundColor: 'rgba(0,0,0,0.75)',
              color: 'white',
              px: 2,
              py: 1,
              borderRadius: 1,
              fontSize: 0,
              fontWeight: 'bold',
            }}
            {...mergeProps(props, tooltipProps)}
          >
            {props.children}
          </Box>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Tooltip;
