import React from 'react';
import _ from 'lodash';
import Ink from 'react-ink';
import SelectMenuItem from './SelectMenuItem';
import { DownArrowIcon } from '../../Icons';

class SelectMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      menuOpen: false,
      current: null,
    };

    this.toggleMenu = this.toggleMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.setCurrent = this.setCurrent.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);

    // Menu ref
    this.menu = null;
  }

  componentDidMount() {
    if (!this.props.placeholder) {
      this.setCurrent();
    }
    // Listener to close the dropdown when clicked outside
    document.addEventListener('mousedown', this.handleClickOutside);
    // Key press listener
    document.addEventListener('keydown', this.handleKeyPress, false);
  }

  componentDidUpdate(prevProps) {
    if (!this.props.placeholder) {
      if (!_.isEqual(prevProps, this.props)) {
        this.setCurrent();
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.removeEventListener('keydown', this.handleKeyPress, false);
  }

  setCurrent() {
    if (this.props.children) {
      let current;

      if (this.props.value) {
        const result = this.props.children.find((el) => el.props.value === this.props.value);
        if (result) {
          current = result.props;
        }
      }

      if (!current && this.props.children.length) {
        current = this.props.children[0].props;
      } else if (!current) {
        current = this.props.children.props;
      }

      this.setState({ current });
    }
  }

  handleClick(item) {
    this.setState({
      current: item,
      menuOpen: false,
    });

    // Perform onClick function if applicable
    if (typeof item.onClick === 'function') {
      item.onClick();
    }
  }

  handleClickOutside(e) {
    if (!this.menu.contains(e.target)) {
      this.closeMenu();
    }
  }

  handleKeyPress = (event) => {
    const { code } = event;
    if (!code) {
      return;
    }
    if (code === 'Escape') {
      this.closeMenu();
    }
  };

  toggleMenu() {
    this.setState((prevState) => ({
      menuOpen: !prevState.menuOpen,
    }));
  }

  closeMenu() {
    this.setState({ menuOpen: false });
  }

  render() {
    const { placeholder } = this.props;

    const selectClasses = ['form-select'];
    if (this.state.menuOpen) {
      selectClasses.push('form-select--open');
    }
    if (this.props.fullWidth) {
      selectClasses.push('form-select--fullwidth');
    }

    const current = this.state.current ? this.state.current.label : '';

    let children;
    if (this.props.children.length) {
      children = this.props.children.map((child) => {
        if (!child.props) {
          return null;
        }
        return (
          <SelectMenuItem
            key={child.props.value}
            value={child.props.value}
            label={child.props.label}
            handleClick={this.handleClick}
            onClick={child.props.onClick}
            menuOpen={this.state.menuOpen}
            style={child.props.style}
          />
        );
      });
    } else {
      const child = this.props.children;
      children = (
        <SelectMenuItem
          key={child.props.value}
          value={child.props.value}
          label={child.props.label}
          handleClick={this.handleClick}
          onClick={child.props.onClick}
          menuOpen={this.state.menuOpen}
          style={child.props.style}
        />
      );
    }

    return (
      <div className={selectClasses.join(' ')} ref={(el) => (this.menu = el)}>
        <button className="form-select__current" onClick={this.toggleMenu} type="button">
          <span className="form-select__current-text">{current || placeholder}</span>

          <div className="form-select__arrow">
            <DownArrowIcon />
          </div>
        </button>

        <ul className="form-select__options block-list block-list--links">{children}</ul>
      </div>
    );
  }
}

export default SelectMenu;
