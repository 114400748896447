/** @jsxImportSource theme-ui */
import { useRef, useState, useEffect } from 'react';
import { useTextField, useFocus, VisuallyHidden } from 'react-aria';
import { motion, AnimatePresence, AnimateSharedLayout } from 'framer-motion';
import { Box, Input, Label as DefaultLabel, Paragraph, Flex, Error } from '../atoms';
import { IconButton } from '.';
import { ShowIcon, HideIcon } from '../../Icons';

const Label = motion(DefaultLabel);

const TextField = (props) => {
  const { type = 'text', label, description, errorMessage, pasteDisabled = false } = props;

  const ref = useRef();
  const { labelProps, inputProps, descriptionProps, errorMessageProps } = useTextField(props, ref);

  // Input focus handler
  const [focused, setFocused] = useState(false);
  const { focusProps } = useFocus({
    onFocus: (e) => setFocused(true),
    onBlur: (e) => setFocused(false),
  });

  // Control text visibility
  const [textHidden, setTextHidden] = useState(type === 'password');

  // Hack to detect autofilled value
  /* const [autofilled, setAutofilled] = useState(false);
  useEffect(async () => {
    console.log(ref.current)
    setAutofilled(await detectAutofill(ref.current));
  }, []); */

  const isActive = (inputProps.value && inputProps.value.length) || focused;

  return (
    <motion.div layout>
      <Box
        mb={4}
        sx={{
          position: 'relative',
        }}
      >
        <motion.div layout>
          <Label
            {...labelProps}
            p={2}
            pt={isActive ? '3px' : 2}
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              transformOrigin: '0 0',
              pointerEvents: 'none',
              color: focused ? 'accent' : 'grayDark',
            }}
            animate={{
              x: isActive ? 2 : 0,
              scale: isActive ? 0.8 : 1,
            }}
            initial={false}
          >
            {label}
          </Label>
          <Input
            ref={ref}
            {...inputProps}
            {...focusProps}
            type={type === 'password' ? (textHidden ? type : 'text') : type}
            pt={'18px'}
            pb={'6px'}
            onPaste={pasteDisabled ? (e) => e.preventDefault() : null}
            sx={{ border: errorMessage && !focused ? '1px solid #ea71ad' : '1px solid transparent' }}
          />
          {type === 'password' && (
            <Box
              sx={{
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                top: 0,
                right: 0,
                width: 48,
                height: 48,
                minWidth: 0,
              }}
            >
              <IconButton
                iconVariant={textHidden ? 'Ui07' : 'Ui08'}
                label={textHidden ? 'Show text' : 'Hide text'}
                onPress={() => setTextHidden(!textHidden)}
                type="button"
              />
            </Box>
          )}
        </motion.div>
        {description && <Paragraph {...descriptionProps}>{description}</Paragraph>}
        <AnimatePresence exitBeforeEnter>
          {errorMessage && (
            <Error key={errorMessage} {...errorMessageProps}>
              {errorMessage}
            </Error>
          )}
        </AnimatePresence>
      </Box>
    </motion.div>
  );
};

export default TextField;
