import { motion, AnimatePresence } from 'framer-motion';
import { Box, Flex, Error, Paragraph, Checkbox, Link } from '../atoms';

const CheckboxRow = (props) => {
  const { description, errorMessage, label, isSelected, onChange, supportLink, supportText = 'Learn more' } = props;

  return (
    <motion.div layout>
      <Box
        sx={{
          position: 'relative',
        }}
      >
        <motion.div layout>
          <Flex as="label" sx={{ alignItems: 'flex-start' }}>
            <Flex sx={{ height: 24, alignItems: 'center' }}>
              <Checkbox aria-label={label} isSelected={!!isSelected} onChange={onChange} />
            </Flex>
            <Box sx={{ pl: 2 }}>
              <Paragraph>{label}</Paragraph>
              {description && (
                <Paragraph sx={{ fontSize: 1, color: 'grayDark' }}>
                  {description}
                  &nbsp;
                  {supportLink && (
                    <Link variant="primary" href={supportLink} target="_blank">
                      {supportText}
                    </Link>
                  )}
                </Paragraph>
              )}
            </Box>
          </Flex>
        </motion.div>
        <AnimatePresence exitBeforeEnter>{errorMessage && <Error>{errorMessage}</Error>}</AnimatePresence>
      </Box>
    </motion.div>
  );
};

export default CheckboxRow;
