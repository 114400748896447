import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';

class TextInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      active: false,
      focused: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.input = React.createRef();
  }

  componentDidMount() {
    const { value, autofocus } = this.props;

    if (value && value.length) {
      this.setState({
        value,
        active: true,
      });
    }

    if (autofocus && this.input.current) {
      this.input.current.focus();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props;

    if (value && value !== prevState.value) {
      this.setState({
        value,
        active: true,
      });
    }
  }

  handleChange(e) {
    const { onChange } = this.props;

    if (typeof onChange === 'function') {
      onChange(e);
    }

    const value = e.target.value;

    this.setState({ value });
  }

  handleFocus() {
    this.setState({
      active: true,
      focused: true,
    });
  }

  handleBlur() {
    const { value } = this.state;

    if (!value || !value.length) {
      this.setState({
        active: false,
        focused: false,
      });
    } else {
      this.setState({
        active: true,
        focused: false,
      });
    }
  }

  render() {
    const { id, defaultValue, label, type, multiline, placeholder, autoComplete, textarea = 0 } = this.props;
    const { value } = this.state;

    const classNames = ['form-row', 'form-row--text'];
    if (this.state.active) {
      classNames.push('form-row--active');
    }
    if (this.state.focused) {
      classNames.push('form-row--focused');
    }
    if (placeholder) {
      classNames.push('form-row--placeholder');
    }
    if (textarea === 2) {
      classNames.push('form-row--textarea', 'form-row--textarea-large');
    } else if (textarea) {
      classNames.push('form-row--textarea');
    }

    const Tag = textarea ? TextareaAutosize : 'input';

    return (
      <div className={classNames.join(' ')}>
        <label htmlFor={id} className="form-row__label form-row--text__label">
          {label}
        </label>

        <Tag
          id={id}
          name={id}
          type={type || 'text'}
          value={value}
          defaultValue={defaultValue}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          className="form-row--text__input text-input gamma"
          ref={this.input}
          placeholder={placeholder}
          autoComplete={autoComplete ? 'on' : 'new-password'}
        />
      </div>
    );
  }
}

export default TextInput;
