import React, { Component } from 'react';
import _ from 'lodash';
import debounce from 'lodash.debounce';
import { Button, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import { Element } from 'react-scroll';
import { Router } from '../routes';
import GridTile from './event/GridTileCulled';
import Header from '../containers/headerPortfolio';
import PageSpinner from './PageSpinner';
import CompanyInfo from './core/CompanyInfo';
import Tabs from './webapp/core/Tabs';
import EventPasswordModal from './EventPasswordModal';
import { SearchIcon, CloseIcon } from './Icons';
import { retrieveFromLocalStorage, saveToLocalStorage, removeFromLocalStorage } from '../utils/utils';
import { InfiniteScroll } from './webapp/organisms';

const loadjs = require('loadjs');
const { liveGalleryURL } = require('../constants').get();

class Portfolio extends Component {
  state = {
    loading: true,
    eventsLoaded: false,
    events: [],
    windowSize: {
      h: 0,
      w: 0,
    },
    buildingItems: false,
    searchText: null,
  };

  componentDidMount() {
    // Load portfolio settings
    this.props
      .loadSettings()
      .catch((error) => {
        window.location = liveGalleryURL;
      })
      .then(() => {
        // Portfolio is being embedded in an iframe?
        if (this.props.iframe) {
          // Make sure we're on a plan that allows embeds
          if (!_.get(this.props.portfolioSettings, 'data.embedAllowed')) {
            window.location = liveGalleryURL;
          }

          // Add class to html element
          document.documentElement.classList.add('iframe-embedded');
        }

        // Load initial page
        this.fetchMore();
      });

    // Repack grid items on debounced resize
    window.addEventListener('resize', this.delayedResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.delayedResize);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.loading &&
      !this.props.portfolioSettings.loading &&
      !this.props.portfolioEvents.loading &&
      !this.props.passwordProtected
    ) {
      if (!prevProps.passwordProtected && this.state.eventsLoaded) {
        this.setState({ loading: false });
      } else {
        // Reload settings with authentication
        this.props
          .loadSettings()
          .catch((error) => {
            window.location = liveGalleryURL;
          })
          .then(() => {
            // Load initial page
            this.fetchMore(() => {
              this.setState({ loading: false });
            });
          });
      }
    }

    if (!_.get(this.props.portfolioEvents, 'data.length') && this.state.events.length) {
      this.setState({
        events: [],
      });
    }

    // Force resize/repack when event list updates
    if (prevState.events.length !== this.state.events.length) {
      this.onResize();
    }
  }

  onResize = () => {
    this.setState({
      windowSize: {
        h: window.innerHeight,
        w: window.innerWidth,
      },
    });

    if (this.refs.gridRef) {
      this.refs.gridRef.forcePack();
    }
  };

  delayedResize = debounce(this.onResize, 250);

  preload = (media) => {
    return new Promise((resolve, reject) => {
      const { type, thumbnailUrl, videoThumbnailUrl } = media;

      let img = new Image();
      img.src = thumbnailUrl;

      const poll = setInterval(() => {
        if (img.naturalHeight) {
          clearInterval(poll);
          resolve({
            src: type === 'video' ? videoThumbnailUrl : thumbnailUrl,
            static: thumbnailUrl,
            renderType: type === 'video' ? 'video' : 'image',
            size: {
              h: img.naturalHeight,
              w: img.naturalWidth,
            },
          });
          img = null;
        }
      }, 30);

      img.onerror = () => {
        clearInterval(poll);
        img = null;
        reject();
      };
    });
  };

  fetchMore = (callback) => {
    if (this.state.buildingItems) {
      return;
    }
    this.setState({ buildingItems: true });

    const { loadEvents } = this.props;
    const { searchText } = this.state;

    loadEvents({
      searchText,
    })
      .then(() => {
        const manifest = [];
        const { portfolioEvents } = this.props;
        const { loading, hasMore, data } = portfolioEvents;
        data.forEach((event) => {
          if (event.previewPhotos && event.previewPhotos.length) {
            manifest.push(this.preload(event.previewPhotos[0]));
          } else {
            manifest.push(
              new Promise((resolve) =>
                resolve({
                  src: null,
                  static: null,
                  renderType: null,
                  size: {
                    h: 100,
                    w: 100,
                  },
                })
              )
            );
          }
        });

        Promise.all(manifest.map((p) => p.catch(() => undefined))).then((result) => {
          this.setState({
            buildingItems: false,
            events: data.map((event, index) => {
              const newEvent = { ...event };
              newEvent.image = result[index];
              return newEvent;
            }),
            windowSize: {
              h: window.innerHeight,
              w: window.innerWidth,
            },
            eventsLoaded: true,
          });

          if (callback) {
            callback();
          }
        });
      })
      .catch((error) => {
        this.setState({ buildingItems: false });
      });
  };

  renderGridItems = () => {
    const { portfolioSettings, iframe } = this.props;
    const accentColor = _.get(portfolioSettings, 'data.accentColor');
    const contrastColor = _.get(portfolioSettings, 'data.contrastColor');
    return this.state.events.map((event, index) => (
      <GridTile
        key={event.eventId}
        image={event.image}
        event={event}
        href={`/${event.eventId}`}
        newTab={iframe}
        windowSize={this.state.windowSize}
        overlayOpen={false}
        accentColor={accentColor}
        contrastColor={contrastColor}
      />
    ));
  };

  handlePortfolioPassword = (password) => {
    this.setState({ requestedEvent: false });
    this.props.login(password);
  };

  handleSearch = (e) => {
    const searchText = e.target.value;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.setState({ searchText });
      this.props.resetEvents();
      setTimeout(() => {
        this.fetchMore();
      }, 10);
    }, 1000);
  };

  toggleSearchBar = () => {
    this.setState((prevState) => {
      if (prevState.searchOpen && this.searchInput.value) {
        this.searchInput.value = '';
        clearTimeout(this.timer);
        this.setState({ searchText: null });
        this.props.resetEvents();
        setTimeout(() => {
          this.fetchMore();
        }, 10);
      } else if (!prevState.searchOpen) {
        this.searchInput.focus();
      }
      return {
        searchOpen: !prevState.searchOpen,
      };
    });
  };

  render() {
    const { portfolioEvents, portfolioSettings, passwordProtected, passwordToken, iframe } = this.props;
    const { hasMore } = portfolioEvents;
    const { events, loading, searchText } = this.state;
    const accentColor = _.get(portfolioSettings, 'data.accentColor');
    const contrastColor = _.get(portfolioSettings, 'data.contrastColor');

    if (loading) {
      const { hint, error } = passwordToken;
      const spinnerColor = iframe ? 'rgba(200,200,200,0.75)' : contrastColor;
      const spinnerBgColor = iframe ? 'transparent' : accentColor;
      return (
        <>
          <PageSpinner backgroundColor={spinnerBgColor} color={spinnerColor} />
          {passwordProtected && (
            <EventPasswordModal
              name="Portfolio"
              label="Enter Portfolio"
              variant="lg"
              hint={hint}
              error={error}
              submitEventPassword={(password) => this.handlePortfolioPassword(password)}
            />
          )}
        </>
      );
    }

    const eventGrid = (
      <>
        {searchText && <h3 style={{ marginBottom: '36px' }}>Search results for &ldquo;{searchText}&rdquo;</h3>}

        {!events.length && !portfolioEvents.loading && !portfolioEvents.data.length && (
          <p style={{ color: '#777' }}>No Events found.</p>
        )}

        {events.length > 0 && (
          <InfiniteScroll
            isMasonry={true}
            dataLength={this.state.events.length}
            hasMore={hasMore}
            loadMore={this.fetchMore}
          >
            {this.renderGridItems()}
          </InfiniteScroll>
        )}

        {portfolioEvents.loading && <Spinner />}
      </>
    );

    if (iframe) {
      return (
        <div className="eventpage eventpage--portfolio eventpage--iframe" data-iframe-height="true">
          {eventGrid}
        </div>
      );
    }

    const company = {};
    const showCompanyFields = _.get(portfolioSettings, 'data.showCompanyFields');
    if (showCompanyFields) {
      Object.entries(_.get(portfolioSettings, 'data.company')).forEach((row) => {
        const key = row[0];
        const value = row[1];
        if (showCompanyFields[key]) {
          company[key] = value;
        }
      });
    }

    const searchClass = ['hero-search'];
    if (this.state.searchOpen) {
      searchClass.push('hero-search--open');
    }
    const search = (
      <div className={searchClass.join(' ')}>
        <label className="sr-only" htmlFor="search">
          Search
        </label>
        <input
          id="search"
          type="text"
          className="hero-search__input"
          onChange={this.handleSearch}
          ref={(el) => (this.searchInput = el)}
        />
        <button className="hero-search__toggle" onClick={this.toggleSearchBar}>
          {this.state.searchOpen ? <CloseIcon /> : <SearchIcon />}
        </button>
      </div>
    );

    return (
      <div className="screen-loaded">
        <Header handleSearch={this.handleSearch} />

        <div style={{ position: 'relative' }}>
          <Tabs accentColor={accentColor} tabsOnMobile={true}>
            <div id="events" label="Portfolio">
              <Element name="eventMedia" className="eventpage eventpage--portfolio" style={{ paddingTop: '24px' }}>
                {eventGrid}
              </Element>
            </div>

            <div id="company" label="Contact">
              <CompanyInfo large={true} company={company} accentColor={accentColor} contrastColor={contrastColor} />
            </div>

            {search}
          </Tabs>
        </div>
      </div>
    );
  }
}

export default Portfolio;
