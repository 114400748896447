/** @jsxImportSource theme-ui */
import { useRef, useEffect } from 'react';
import { HiddenSelect, useButton, useSelect } from 'react-aria';
import { useSelectState } from 'react-stately';
import { Button } from 'theme-ui';
import { motion, AnimatePresence, AnimateSharedLayout } from 'framer-motion';
import { Box, Label as DefaultLabel, Paragraph, Error, Popover, ListBox } from '../atoms';

const Label = motion(DefaultLabel);

const SelectField = (props) => {
  const { initialValue, label, description, errorMessage, onChange, isTransparent = false } = props;

  // Create state from incoming props
  const state = useSelectState(props);

  // Props for child elements
  const ref = useRef();
  const { labelProps, triggerProps, valueProps, menuProps, errorMessageProps, descriptionProps } = useSelect(
    props,
    state,
    ref
  );

  // Get props for trigger button based on useSelect
  const { buttonProps } = useButton(triggerProps, ref);

  // Select initial value
  useEffect(() => {
    if (initialValue) {
      state.setSelectedKey(initialValue);
    }
  }, []);

  useEffect(() => {
    if (state.selectedKey) {
      onChange(state.selectedKey);
    }
  }, [state.selectedKey]);

  const isActive = !!state.selectedItem || state.isOpen;

  return (
    <motion.div layout>
      <Box
        mb={4}
        sx={{
          position: 'relative',
        }}
      >
        <motion.div layout>
          <Label
            {...labelProps}
            p={2}
            pt={isActive ? '3px' : 2}
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              transformOrigin: '0 0',
              pointerEvents: 'none',
              color: state.isOpen ? 'accent' : 'grayDark',
            }}
            animate={{
              x: isActive ? 2 : 0,
              scale: isActive ? 0.8 : 1,
            }}
            initial={false}
          >
            {label}
          </Label>
          <HiddenSelect state={state} triggerRef={ref} label={props.label} name={props.name} />
          <Button
            {...buttonProps}
            ref={ref}
            p={2}
            pt={label ? '18px' : '6px'}
            pb={'6px'}
            sx={{
              display: 'block',
              backgroundColor: isTransparent ? 'transparent' : state.isOpen ? 'white' : 'grayLight',
              width: '100%',
              height: '48px',
              borderRadius: 1,
              transition: 'box-shadow 100ms',
              outline: 'none',
              boxShadow: errorMessage
                ? '0 0 0 2px #EA71AD'
                : state.isOpen
                ? '0 0 0 2px #9775f7'
                : '0 0 0 2px transparent',
              textTransform: 'none',
              fontWeight: 'normal',
              textAlign: 'left',
              color: 'text',
            }}
          >
            <span {...valueProps}>{state.selectedItem ? state.selectedItem.rendered : ''}</span>
          </Button>
          {state.isOpen && (
            <Popover isOpen={state.isOpen} onClose={state.close}>
              <ListBox {...menuProps} state={state} />
            </Popover>
          )}
        </motion.div>
        {description && <Paragraph {...descriptionProps}>{description}</Paragraph>}
        <AnimatePresence exitBeforeEnter>
          {errorMessage && (
            <Error key={errorMessage} {...errorMessageProps}>
              {errorMessage}
            </Error>
          )}
        </AnimatePresence>
      </Box>
    </motion.div>
  );
};

export default SelectField;
