export const EVENT_NAME = {
  key: 'title',
  label: 'Name',
  control: {
    type: 'text',
  },
};

export const EVENT_START_DATE = {
  key: 'eventDate',
  label: 'Start date',
  control: {
    type: 'date',
  },
};

export const EVENT_END_DATE = {
  key: 'endDate',
  label: 'End date',
  control: {
    type: 'date',
  },
};

export const EVENT_LOCATION = {
  key: 'location',
  label: 'Location',
  control: {
    type: 'text',
  },
};

export const EVENT_PASSCODE_IS_ENABLED = {
  key: 'passcodeEnabled',
  label: 'Enable Passcode',
  description: 'Require entering your 4-digit passcode while this event is running',
  control: {
    type: 'toggle',
  },
};

export const EVENT_IS_INCLUDED_IN_PORTFOLIO = {
  key: 'portfolioEnabled',
  label: 'Include in Portfolio',
  description: 'Show this event on your company Porfolio page',
  control: {
    type: 'toggle',
  },
};

export const EVENT_DROPBOX_WATCH_FOLDER = {
  key: 'dropbox.watchPath',
  label: 'Dropbox Watch Folder',
  description:
    'Automatically sync supported images and videos from a Dropbox folder to be uploaded to the event’s Live Gallery and shown in Gallery Mode and Sharing Station.',
  supportText: 'Learn more about Dropbox Watch Folders',
  supportLink: 'https://support.photoboothsupplyco.com/salsa/dropbox-watch-folder',
  control: {
    type: 'button',
    emptyButtonText: 'Select',
    removeButtonText: 'Remove',
  },
  excludedPlans: ['plus'],
};
